import { render, staticRenderFns } from "./SaveChanges.vue?vue&type=template&id=2033a78b&scoped=true&"
import script from "./SaveChanges.vue?vue&type=script&lang=js&"
export * from "./SaveChanges.vue?vue&type=script&lang=js&"
import style0 from "./SaveChanges.less?vue&type=style&index=0&id=2033a78b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2033a78b",
  null
  
)

export default component.exports