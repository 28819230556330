<template>
  <div class="visual-machine-campaigns">
    <b-modal
      id="carousel-products-preview"
      :visible="showProductsPreview"
      hide-footer
      hide-header
      size="lg"
      @hide="showProductsPreview = false"
    >
      <div class="close" @click="showProductsPreview = false"></div>
      <div class="title">{{ $t("visualMachine.carousel") }}</div>
      <div class="desc">{{ $t("visualMachine.productCarouselDesc") }}</div>
      <div class="subtitle">
        {{ $t("visualMachine.desktopProductCarousel") }}
      </div>
      <div class="prods-image" />
      <div class="subtitle">
        {{ $t("visualMachine.mobileProductCarousel") }}
      </div>
      <div class="prods-image-mobile" />
      <div class="footer" style="justify-content: center;">
        <div class="button discard" @click="showProductsPreview = false">
          {{ $t("layoutDesign.general.gotIt") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="carousel-products-preview"
      :visible="showButtonsPreview"
      hide-footer
      hide-header
      size="lg"
      @hide="showButtonsPreview = false"
    >
      <div class="close" @click="showButtonsPreview = false"></div>
      <div class="title">{{ $t("visualMachine.buttons") }}</div>
      <div class="desc">{{ $t("visualMachine.buttonCarouselDesc") }}</div>
      <div class="subtitle">{{ $t("visualMachine.desktopCategories") }}</div>
      <div class="categories-image" />
      <div class="subtitle">{{ $t("visualMachine.mobileCategories") }}</div>
      <div class="categories-image-mobile" />
      <div class="footer" style="justify-content: center;">
        <div class="button discard" @click="showProductsPreview = false">
          {{ $t("layoutDesign.general.gotIt") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="carousel-products-preview"
      :visible="showProductsPreviewInfo"
      hide-footer
      hide-header
      size="lg"
      @hide="showProductsPreviewInfo = false"
    >
      <div class="close" @click="showProductsPreviewInfo = false"></div>
      <div class="title">{{ $t("visualMachine.insideShowcase") }}</div>
      <div class="desc">{{ $t("visualMachine.insideShowcaseDesc") }}</div>
      <div class="footer" style="justify-content: center;">
        <div class="button discard" @click="showProductsPreviewInfo = false">
          {{ $t("layoutDesign.general.gotIt") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="carousel-products-preview"
      :visible="showEmptyPreviewInfo"
      hide-footer
      hide-header
      size="lg"
      @hide="showEmptyPreviewInfo = false"
    >
      <div class="close" @click="showEmptyPreviewInfo = false"></div>
      <div class="title">{{ $t("visualMachine.insideNoResult") }}</div>
      <div class="desc">{{ $t("visualMachine.insideNoResultsDesc") }}</div>
      <div class="footer" style="justify-content: center;">
        <div class="button discard" @click="showEmptyPreviewInfo = false">
          {{ $t("layoutDesign.general.gotIt") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="discard-config-modal"
      :visible="showDiscardChangesModal"
      hide-footer
      hide-header
      size="lg"
      @hide="showDiscardChangesModal = false"
    >
      <div class="close" @click="showDiscardChangesModal = false"></div>
      <div class="title">{{ $t("visualMachine.discardRec") }}</div>
      <div class="desc">{{ $t("visualMachine.unsaved") }}</div>
      <div class="footer">
        <div class="button save" @click="exitWithoutSave">
          {{ $t("visualMachine.saving") }}
        </div>
        <div class="button discard" @click="showDiscardChangesModal = false">
          {{ $t("visualMachine.cancel") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="delete-trigger-config-modal"
      :visible="showDeleteTriggerModal"
      hide-footer
      hide-header
      size="lg"
      @hide="showDeleteTriggerModal = false"
    >
      <div class="close" @click="showDeleteTriggerModal = false"></div>
      <div class="title">
        {{
          $t("visualMachine.deleteTrigger", { var1: deletingTriggerIndex + 1 })
        }}
      </div>
      <div class="desc">{{ $t("visualMachine.recoverTrigger") }}</div>
      <div class="footer">
        <div
          class="button save"
          @click="
            selectedRecommendation.selectedRecommendation.conditions.splice(
              deletingTriggerIndex,
              1
            )
            showDeleteTriggerModal = false
          "
        >
          {{ $t("visualMachine.delete") }}
        </div>
        <div class="button discard" @click="showDeleteTriggerModal = false">
          {{ $t("visualMachine.cancel") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="delete-campaign-modal"
      :visible="showDeleteModal"
      hide-footer
      hide-header
      size="lg"
      @hide="showDeleteModal = false"
    >
      <div class="close" @click="showDeleteModal = false"></div>
      <div class="title">{{ $t("visualMachine.deleteCampaign?") }}</div>
      <div class="desc">{{ $t("visualMachine.recoverCampaign") }}</div>
      <div class="footer">
        <div
          class="button save"
          @click="
            deleteCampaign(selectedRecommendation.id)
            showDeleteModal = false
            selectedRecommendation = null
          "
        >
          {{ $t("visualMachine.delete") }}
        </div>
        <div class="button discard" @click="showDeleteModal = false">
          {{ $t("visualMachine.cancel") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="carousel-products-preview"
      :visible="showConditionsGuide"
      hide-footer
      hide-header
      size="lg"
      @hide="showConditionsGuide = false"
    >
      <div class="close" @click="showConditionsGuide = false"></div>
      <div class="title">{{ $t("visualMachine.booleanExpression") }}</div>
      <div class="desc left">{{ $t("visualMachine.boolean1") }}</div>
      <div class="desc left">
        <b>{{ $t("visualMachine.booleanTitle1") }}</b
        ><br />
        {{ $t("visualMachine.boolean2") }}
        <ul>
          <li>{{ $t("visualMachine.example1") }}</li>
          <li>{{ $t("visualMachine.example2") }}</li>
        </ul>
      </div>
      <div class="desc left">
        <b>{{ $t("visualMachine.booleanTitle2") }}</b
        ><br />
        {{ $t("visualMachine.alwaysTrue") }}
        <p class="code">{ "type": "always" }</p>
        {{ $t("visualMachine.alwaysFalse") }}
        <p class="code">{ "type": "nerver" }</p>
        {{ $t("visualMachine.compare") }}
        <p class="code">
          { "type": "equal", "firstOperand": ..., "secondOperand": ... }
        </p>
        <p>{{ $t("visualMachine.other") }}</p>
        {{ $t("visualMachine.stringCompare") }}<br />
        {{ $t("visualMachine.stringCompare2") }}<br />
        <span class="code"
          >{ "type": "contains", "haystack": ..., "needle": ... }</span
        >
        <p>{{ $t("visualMachine.other1") }}</p>
        {{ $t("visualMachine.negation") }} <br />
        <p class="code">{ "type": "negation", "subject": ... }</p>
        {{ $t("visualMachine.conjunction") }} <br />
        <p class="code">{ "type": "conjunction", "subjects": [ ... ] }</p>
        {{ $t("visualMachine.disjunction") }} <br />
        <p class="code">{ "type": "disjunction", "subjects": [ ... ] }</p>
        <b>{{ $t("visualMachine.examples") }}</b
        ><br />
        {{ $t("visualMachine.nike") }}
        <p v-hmtl="htmlContent" class="code"></p>
        {{ $t("visualMachine.greaterThan") }}
        <p class="code">
          { "type": "negation", "subject": { "type": "greaterThan",
          "firstOperand": ..., "secondOperand": 100 } }
        </p>
        {{ $t("visualMachine.lessThan") }}
        <p v-html="htmlContent1" class="code"></p>
        {{ $t("visualMachine.last") }}
      </div>
    </b-modal>
    <b-modal
      id="carousel-products-preview"
      :visible="showRelevanceModal"
      hide-footer
      hide-header
      size="lg"
      @hide="showRelevanceModal = false"
    >
      <div class="close" @click="showRelevanceModal = false"></div>
      <div class="title">{{ $t("visualMachine.relevanceTitle") }}</div>
      <div class="desc">{{ $t("visualMachine.relevanceText") }}</div>
      <div class="footer" style="justify-content: center; max-width: none;">
        <div class="button goBack" @click="showRelevanceModal = false">
          {{ $t("visualMachine.closeRelevance") }}
        </div>
        <router-link to="/layoutDesign/general" class="goToSettingsRouter">
          <div
            class="button discard goToSettings"
            @click="showRelevanceModal = false"
          >
            {{ $t("visualMachine.goToSetting") }}
            <a class="icon" />
          </div>
        </router-link>
      </div>
    </b-modal>
    <b-modal
      id="carousel-products-preview"
      :visible="showChangingTime"
      hide-footer
      hide-header
      size="lg"
      @hide="showChangingTime = false"
    >
      <div class="close" @click="showChangingTime = false"></div>
      <div class="title">{{ $t("visualMachine.changeHistoricTimeTitle") }}</div>
      <div class="desc">
        {{
          $t("visualMachine.changeHistoricTimeDesc", {
            old: historicalTimeOption,
            new: unconfirmedTime,
          })
        }}
      </div>
      <div class="footer" style="justify-content: center; max-width: none;">
        <div class="button goBack" @click="showChangingTime = false">
          {{ $t("visualMachine.closeRelevance") }}
        </div>
        <div
          class="button discard goToSettings"
          @click="handleConfirmChangeTime"
        >
          {{ $t("visualMachine.changeHistoricTime") }}
          <a class="icon" />
        </div>
      </div>
    </b-modal>
    <ASPanel
      :showPanel="showPanel"
      :actionType="actionType"
      @closeASPanel="onClosePanel"
    />
    <perfect-scrollbar>
      <div class="content-container" v-if="!selectedRecommendation">
        <div class="top">
          <div>
            <p>{{ $t("visualMachine.recDescription") }}</p>
            <div class="row">
              <p>{{ $t("visualMachine.showcaseRec") }}</p>
              <div class="tutorial"><a class="icon" /></div>
            </div>
            <div class="row">
              <p>{{ $t("visualMachine.noResultsRec") }}</p>
              <div class="tutorial"><a class="icon" /></div>
            </div>
            <div class="row">
              <p>{{ $t("visualMachine.externalRec") }}</p>
              <div class="tutorial"><a class="icon" /></div>
            </div>
          </div>
          <div class="search">
            <b-input
              v-model="filterWord"
              :placeholder="
                $t('visualMachine.searchAmongRec', {
                  var1: recommendationList.length,
                })
              "
            >
            </b-input>
            <div
              class="new-campaign"
              @click="selectRecommendation($options.EMPTY_RECOMMENDATION)"
            >
              + {{ $t("visualMachine.newRecommendation") }}
            </div>
          </div>
          <div class="row" v-if="filterWord == ''" style="align-items:center">
            <div class="toggle toggle-row">
              {{ $t("visualMachine.enableDisableAll") }}
              <toggle-button
                class="toggle-button"
                color="#398ff4"
                :sync="true"
                :width="50"
                v-model="toggledAll"
                @change="toggleAll"
              />
            </div>

            <div class="toggle toggle-row">
              {{ $t("visualMachine.onlyOldData") }}
              <toggle-button
                class="toggle-button"
                color="#398ff4"
                :sync="true"
                :width="50"
                v-model="onlyHistorical"
                @change="onlyHistorical"
              />
            </div>
            <div class="custom-select">
              <span class="icon"></span>
              <select
                @change="onTimeChange($event.target.value)"
                :value="historicalTimeOption"
              >
                <option
                  v-for="option in historicalTime"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="search-results" v-else>
            {{ $t("visualMachine.searchResultsFor") }} "<span>{{
              filterWord
            }}</span
            >"
          </div>
        </div>
        <div class="toggle"></div>
        <div :class="['list-container', { searching: filterWord !== '' }]">
          <div class="header">
            <div class="drag column-item">
              <div
                class="info"
                @mouseenter="$bvToast.show('drag-toast')"
                @mouseleave="$bvToast.hide('drag-toast')"
              ></div>
              <b-toast
                id="drag-toast"
                variant="info"
                static
                no-close-button
                no-auto-hide
                solid
              >
                {{ $t("visualMachine.canOrderRec") }}
              </b-toast>
            </div>
            <div class="name column-item" style="width: calc(100% - 760px)">
              {{ $t("visualMachine.name") }}
            </div>
            <div class="enable column-item">
              {{ $t("visualMachine.enableDisable") }}
            </div>
            <div class="validity column-item">
              {{ $t("visualMachine.visibility") }}
            </div>
            <div class="option column-item">
              {{ $t("visualMachine.option") }}
            </div>
          </div>
          <draggable
            v-model="recommendationList"
            v-if="!isloadingRecommendationList && filterWord == ''"
            class="campaign-list"
            @end="saveAll"
            v-bind="$options.DRAG_OPTIONS"
          >
            <transition-group type="transition">
              <div
                :class="['list-item']"
                v-for="recommendation in recommendationListFiltered"
                :key="'recommendation' + recommendation.id"
              >
                <div class="drag column-item"><div class="icon"></div></div>
                <div class="name column-item">
                  {{ recommendation.name }}
                  <!-- <span class="with-error" @mouseenter="$bvToast.show('name-toast_'+recommendation.id)" @mouseleave="$bvToast.hide('name-toast_'+recommendation.id)" v-if="checkIsNotAlive(recommendation)"> </span>  -->
                  <b-toast
                    :id="'name-toast_' + recommendation.id"
                    variant="danger"
                    static
                    no-close-button
                    no-auto-hide
                    solid
                  >
                    {{ $t("visualMachine.productIsNotAlive") }}
                  </b-toast>
                </div>
                <div class="enable column-item">
                  <toggle-button
                    class="toggle-button"
                    color="#398ff4"
                    :sync="true"
                    :width="50"
                    v-model="recommendation.isActive"
                    @change="() => onChange(recommendation)"
                  />
                </div>
                <div class="validity column-item">
                  {{
                    recommendation.condition.conditions[0].conditions[0].type ==
                    validityOptions[0].value
                      ? $t("visualMachine.alwaysVisible")
                      : validityText(
                          recommendation.condition.conditions[0].conditions[0]
                        )
                  }}
                </div>
                <div class="option column-item">
                  <div
                    class="btn copy"
                    @click="copyRecommendation(recommendation)"
                    @click.stop
                  ></div>
                  <div
                    class="btn"
                    @click="selectRecommendation(recommendation)"
                    @click.stop
                  >
                    {{ $t("visualMachine.editRecommendationBtn") }}
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div
            class="spinner-container"
            v-else-if="isloadingRecommendationList && filterWord == ''"
          >
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <div class="campaign-list" v-else-if="!isloadingRecommendationList">
            <div
              :class="['list-item']"
              v-for="campaign in recommendationListFiltered"
              :key="'campaign_' + campaign.id"
            >
              <div class="name column-item">{{ campaign.name }}</div>
              <div class="enable column-item">
                <toggle-button
                  class="toggle-button"
                  color="#398ff4"
                  :sync="true"
                  :width="50"
                  v-model="campaign.isActive"
                  @change="() => onChange(campaign)"
                />
              </div>
              <!-- <div class="validity column-item"> {{validityType==validityOptions[0].value?$t('visualMachine.alwaysVisible'):validityText(campaign.validity)}} </div> -->
              <div class="option column-item">
                <div
                  class="btn copy"
                  @click="copyRecommendation(campaign)"
                  @click.stop
                ></div>
                <div
                  class="btn"
                  @click="selectRecommendation(campaign)"
                  @click.stop
                >
                  {{ $t("visualMachine.editRule") }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="spinner-container" v-else> 
              <b-spinner class="align-middle"></b-spinner>
            </div> -->
        </div>
      </div>
      <div class="content-container" v-else>
        <div class="top-container">
          <div class="btn back" @click="showDiscardChangesModal = true">
            {{ $t("visualMachine.goBack") }}
          </div>
          <div class="title">{{ selectedRecommendation.name }}</div>
        </div>
        <div
          :class="[
            'collapse-container',
            { expanded: recommendationInfoExpanded },
            { 'with-error': cantAddName && touchedName },
          ]"
        >
          <div
            class="header-collapse"
            @click="recommendationInfoExpanded = !recommendationInfoExpanded"
          >
            <div
              class="warning info"
              v-if="cantAddName && touchedName"
              @mouseenter="$bvToast.show('info-toast')"
              @mouseleave="$bvToast.hide('info-toast')"
            >
              <div class="warn-icon"></div>
              <b-toast
                id="info-toast"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                {{ $t("visualMachine.addCampaign") }}
              </b-toast>
            </div>
            <div class="title-container">
              <div class="label-container">
                <div class="sub-title">
                  {{ $t("visualMachine.recommendationInfo") }}
                </div>
                <div class="desc">
                  {{ $t("visualMachine.editRecommendation") }}
                </div>
              </div>
            </div>
            <div class="icon"></div>
          </div>
          <b-collapse
            accordion="accordion"
            v-model="recommendationInfoExpanded"
          >
            <div class="accordion-content">
              <div class="row">
                <div class="column" style="width: calc(50% - 20px);">
                  <div class="label">
                    {{ $t("visualMachine.recommendationName") }}
                  </div>
                  <b-input
                    v-model="selectedRecommendation.name"
                    @input="touchedName = true"
                  ></b-input>
                </div>
                <div class="column" style="width: calc(50% - 20px);">
                  <div class="label">
                    {{ $t("visualMachine.recommendationDesc") }}
                  </div>
                  <b-textarea
                    v-model="selectedRecommendation.description"
                  ></b-textarea>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div
          :class="[
            'collapse-container',
            { expanded: schedulingExpanded },
            { 'with-error': cantAddValidity },
          ]"
        >
          <div
            class="header-collapse"
            @click="schedulingExpanded = !schedulingExpanded"
          >
            <div
              class="warning validity"
              v-if="cantAddValidity"
              @mouseenter="$bvToast.show('validity-toast')"
              @mouseleave="$bvToast.hide('validity-toast')"
            >
              <div class="warn-icon"></div>
              <b-toast
                id="validity-toast"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                {{ $t("visualMachine.addValidity") }}
              </b-toast>
            </div>
            <div class="title-container">
              <div class="label-container">
                <div class="sub-title">
                  {{ $t("visualMachine.scheduling") }}
                </div>
                <div class="desc">{{ $t("visualMachine.timeRange") }}</div>
              </div>
            </div>
            <div class="icon"></div>
          </div>
          <b-collapse accordion="accordion" v-model="schedulingExpanded">
            <div class="accordion-content">
              <div class="row">
                <div class="column" style="width: calc(50% - 20px);">
                  <b-form-group>
                    <b-form-radio
                      v-model="validityType.type"
                      v-for="option in validityOptions"
                      @change="timeframes = {}"
                      :key="'timeframe_' + option.value"
                      :value="option.value"
                      style="color: #52575C; font-family: 'Open Sans'; margin-bottom: 12px"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-group>
                </div>
                <div
                  class="column"
                  v-if="validityType.type == validityOptions[1].value"
                  style="width: calc(50% - 20px);"
                >
                  <div class="label">{{ $t("visualMachine.timeFrame") }}</div>
                  <Datepicker
                    v-model="timeframes"
                    :opens="'left'"
                    :ranges="false"
                    :timePicker="true"
                    :locale-data="locale"
                    :timePicker24Hour="true"
                  />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div
          :class="[
            'collapse-container',
            { expanded: conditionsExpanded },
            { 'with-error': cantAddValidity },
          ]"
        >
          <div
            class="header-collapse"
            @click="conditionsExpanded = !conditionsExpanded"
          >
            <div
              class="warning validity"
              v-if="cantAddValidity"
              @mouseenter="$bvToast.show('validity-toast')"
              @mouseleave="$bvToast.hide('validity-toast')"
            >
              <div class="warn-icon"></div>
              <b-toast
                id="validity-toast"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                {{ $t("visualMachine.addValidity") }}
              </b-toast>
            </div>
            <div class="title-container">
              <div class="label-container">
                <div class="sub-title">
                  {{ $t("visualMachine.conditions") }}
                </div>
                <div class="desc">
                  {{ $t("visualMachine.conditionsLabel") }}
                </div>
              </div>
            </div>
            <div class="icon"></div>
          </div>
          <b-collapse accordion="accordion" v-model="conditionsExpanded">
            <div class="accordion-content">
              <div class="desc-secondary">
                {{ $t("visualMachine.conditionsDescription")
                }}<b>{{ $t("visualMachine.conditionsDescription1") }} </b
                ><span
                  style="text-decoration: underline; cursor: pointer;"
                  @click="showConditionsGuide = true"
                  >{{ $t("visualMachine.conditionsDescriptionLink") }}</span
                >
              </div>
              <div class="row">
                <textarea
                  class="conditions"
                  id="code"
                  rows="2"
                  :value="conditionsContent"
                ></textarea>
              </div>
              <div class="row" style="margin-top: 12px;">
                <div class="example" @click="conditionsContent = brandTemplate">
                  Brand
                  <div class="brand"></div>
                </div>
                <div
                  class="example"
                  @click="conditionsContent = sellingTemplate"
                >
                  Selling price
                  <div class="selling"></div>
                </div>
                <div
                  class="example"
                  @click="conditionsContent = listingTemplate"
                >
                  Listing price
                  <div class="listing"></div>
                </div>
              </div>
              <div class="btn-container">
                <div
                  :class="['add', 'btn']"
                  @click="
                    conditionsExpanded = false
                    touchedTrigger = true
                    conditionsContent = ''
                  "
                >
                  {{ $t("visualMachine.continueWithout") }}
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div>
          <div
            :class="[
              'collapse-container',
              { expanded: triggersExpanded['trigger'] },
              { 'with-error': cantAddFilterArray[0] && touchedTrigger },
            ]"
          >
            <!-- <div class="header-collapse" @click="triggersExpanded['trigger'+index]=!triggersExpanded['trigger'+index]"> -->
            <div class="header-collapse" @click="() => expandAccordion(0)">
              <div
                class="warning filter"
                v-if="cantAddFilterArray[0] && touchedTrigger"
                @mouseenter="$bvToast.show('filter-toast_' + 0)"
                @mouseleave="$bvToast.hide('filter-toast_' + 0)"
              >
                <div class="warn-icon"></div>
                <b-toast
                  :id="'filter-toast_' + 0"
                  variant="danger"
                  static
                  no-close-button
                  no-auto-hide
                  solid
                >
                  <span
                    v-for="(warning, warningIndex) in cantAddFilterWarnings(0)"
                    :key="'warning_' + 0 + '_' + warningIndex"
                  >
                    <br v-if="warningIndex > 0" />
                    {{ warning }}
                  </span>
                </b-toast>
              </div>
              <div class="title-container">
                <div class="label-container">
                  <div class="sub-title">
                    {{ $t("visualMachine.trigger", { var1: "" }) }}
                  </div>
                  <div class="desc">
                    {{ $t("visualMachine.recTriggerDesc1") }}
                  </div>
                  <div class="desc" style="margin-top: 0;">
                    {{ $t("visualMachine.recTriggerDesc2") }}
                  </div>
                </div>
              </div>
              <div class="icon"></div>
            </div>
            <b-collapse
              accordion="accordion"
              v-model="triggersExpanded['trigger' + 0]"
            >
              <div class="accordion-content">
                <div class="container">
                  <div class="row" style="border-bottom: 1px #AFBECB solid;">
                    <div class="column">
                      <b-form-group>
                        <b-form-radio
                          class="radio"
                          v-model="triggerType"
                          @change="triggerType = triggerOptions[0].value"
                          :key="'timeframe_' + triggerOptions[0].value"
                          :value="triggerOptions[0].value"
                        >
                          {{ triggerOptions[0].text }}
                        </b-form-radio>
                        <div class="desc">
                          {{ $t("visualMachine.opt1Desc") }}
                        </div>
                        <b-form-radio
                          class="radio"
                          style="margin-top: 24px;"
                          v-model="triggerType"
                          @change="triggerType = triggerOptions[1].value"
                          :key="'timeframe_' + triggerOptions[1].value"
                          :value="triggerOptions[1].value"
                        >
                          {{ triggerOptions[1].text }}
                        </b-form-radio>
                        <div class="desc">
                          {{ $t("visualMachine.opt2Desc") }}
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div
                  class="container"
                  v-if="triggerType == triggerOptions[1].value"
                >
                  <div class="row">
                    <div class="column">
                      <div class="desc selected-trigger">
                        {{ triggerOptions[1].text }}
                      </div>
                      <div class="desc">
                        {{ $t("visualMachine.pasteCode") }}
                      </div>
                      <div
                        class="desc"
                        style="font-weight: 600;margin-top: 24px;"
                      >
                        {{ $t("visualMachine.assignTag") }}
                      </div>
                      <b-input
                        v-model="selectedRecommendation.name"
                        @input="touchedName = true"
                        class="input"
                      ></b-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="code-snippet">
                      <div class="copy-icon"></div>
                      <textarea
                        id="code"
                        rows="2"
                        readonly="readonly"
                        disabled="disabled"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div
                  class="container"
                  v-if="triggerType == triggerOptions[0].value"
                >
                  <div
                    class="row"
                    style="border-bottom: 1px #AFBECB solid; padding-bottom: 24px"
                  >
                    <div class="column">
                      <div
                        class="desc selected-trigger"
                        style="margin-top: 24px;"
                      >
                        {{ triggerOptions[0].text }}
                      </div>
                      <div class="desc">
                        {{ $t("visualMachine.inLayerDesc") }}
                      </div>
                      <div
                        class="desc row"
                        style="font-weight: 600;margin-top: 24px; align-items: center;"
                      >
                        {{ $t("visualMachine.insideShowcase") }}
                        <div class="info-div">
                          <a
                            class="icon"
                            @click="showProductsPreviewInfo = true"
                          />
                        </div>
                      </div>
                      <div class="desc">
                        {{ $t("visualMachine.insideDesc") }}
                      </div>
                      <div class="row checkbox-row">
                        <div class="row" style="align-items: center;">
                          <b-checkbox
                            :checked="
                              selectedRecommendation.extra.showcase
                                .visualizationType == 'carousel'
                            "
                            @change="
                              selectedRecommendation.extra.showcase.visualizationType =
                                'carousel'
                            "
                            style="color: #52575C;  font-family: 'Open Sans';"
                          >
                            {{ $t("visualMachine.carousel") }}
                          </b-checkbox>
                          <div class="info-div">
                            <a
                              class="icon"
                              @click="showProductsPreview = true"
                            />
                          </div>
                        </div>
                        <!-- <div class="row" style="align-items: center;">
                          <b-checkbox
                            :checked="
                              selectedRecommendation.extra.showcase
                                .visualizationType == 'buttons'
                            "
                            @change="
                              selectedRecommendation.extra.showcase.visualizationType =
                                'buttons'
                            "
                            style="color: #52575C;  font-family: 'Open Sans';"
                          >
                            {{ $t("visualMachine.buttons") }}
                          </b-checkbox>
                          <div class="info-div">
                            <a
                              class="icon"
                              @click="showButtonsPreview = true"
                            />
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">
                      <div
                        class="desc row"
                        style="font-weight: 600;margin-top: 24px;align-items: center;"
                      >
                        {{ $t("visualMachine.insideNoResult") }}
                        <div class="info-div">
                          <a
                            class="icon"
                            @click="showEmptyPreviewInfo = true"
                          />
                        </div>
                      </div>
                      <div class="desc">
                        {{ $t("visualMachine.insideNoResultDesc") }}
                      </div>
                      <div class="row checkbox-row">
                        <div class="row" style="align-items: center;">
                          <b-checkbox
                            :checked="
                              selectedRecommendation.extra.noResult
                                .visualizationType == 'carousel'
                            "
                            @change="
                              selectedRecommendation.extra.noResult.visualizationType =
                                'carousel'
                            "
                            style="color: #52575C;  font-family: 'Open Sans';"
                          >
                            {{ $t("visualMachine.carousel") }}
                          </b-checkbox>
                          <!-- <div class="info-div">
                            <a class="icon"  @click="showEmptyProductsPreview=true"/>
                          </div> -->
                        </div>
                        <!-- <div class="row" style="align-items: center;">
                          <b-checkbox
                            :checked="
                              selectedRecommendation.extra.noResult
                                .visualizationType == 'buttons'
                            "
                            @change="
                              selectedRecommendation.extra.noResult.visualizationType =
                                'buttons'
                            "
                            style="color: #52575C;  font-family: 'Open Sans';"
                          >
                            {{ $t("visualMachine.buttons") }}
                          </b-checkbox>

                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <div
          :class="[
            'collapse-container',
            { expanded: actionExpanded },
            {
              'with-error': !queryCheck,
            },
          ]"
        >
          <div
            class="header-collapse"
            @click="actionExpanded = !actionExpanded"
          >
            <div
              class="warning actions"
              v-if="touchedActions || !queryCheck"
              @mouseenter="$bvToast.show('actions-toast')"
              @mouseleave="$bvToast.hide('actions-toast')"
            >
              <div class="warn-icon"></div>
              <b-toast
                id="actions-toast"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                <span
                  v-for="(warning, warningIndex) in cantAddActionsWarnings"
                  :key="'warning_action_' + warningIndex"
                >
                  <br v-if="warningIndex > 0" />
                  {{ warning }}
                </span>
              </b-toast>
            </div>
            <div class="title-container">
              <div class="label-container">
                <div class="sub-title">
                  {{ $t("visualMachine.layerAction") }}
                </div>
                <div class="desc">{{ $t("visualMachine.impactRec") }}</div>
              </div>
            </div>
            <div class="icon"></div>
          </div>
          <b-collapse accordion="accordion" v-model="actionExpanded">
            <div class="accordion-content">
              <div class="container">
                <div>
                  <div class="action-input-container">
                    <div class="type">
                      <b-select
                        v-model="actionType"
                        :options="actionTypeOptions"
                        @change="changeRecommender"
                      >
                      </b-select>
                    </div>
                    <div
                      class="value"
                      v-if="actionType == actionTypeOptions[0].value"
                    >
                      <div
                        class="btn"
                        @click="openPanel(actionType, actionFacets, 0)"
                      >
                        <div class="icon"></div>
                        {{ $t("visualMachine.selectProducts") }}
                      </div>
                      <div class="label">
                        {{ actionFacets.length }}
                        {{ $t("visualMachine.productsSelected") }}
                      </div>
                    </div>
                    <div
                      class="value"
                      v-if="actionType == actionTypeOptions[1].value"
                    >
                      <b-input
                        v-model="querySearch"
                        :placeholder="$t('visualMachine.queryPlaceholder')"
                        @input="touchedName = true"
                        class="input"
                      ></b-input>
                    </div>
                  </div>
                  <div class="input-desc">
                    {{ $t("visualMachine." + actionType + "Desc") }}
                  </div>
                  <div
                    v-if="
                      actionType == actionTypeOptions[2].value ||
                        actionType == actionTypeOptions[3].value
                    "
                    class="row"
                    style="margin-top: 48px;"
                  >
                    <div class="column">
                      <div style="margin-bottom: 8px;">
                        {{ $t("setup.facetsTitle") }}
                      </div>
                      <div class="input-desc" style="margin-bottom: 30px;">
                        {{ $t("visualMachine.similarFacet") }}
                      </div>
                    </div>
                    <div
                      class="divider"
                      style="width: 100%; margin-bottom: 30px; margin-top: 0px"
                    ></div>
                    <!-- <div
                      class="row"
                      style="flex-wrap: nowrap; align-items: center"
                    >
                      <div
                        class="delete-small"
                        @click="deleteFacet(index, facetIndex)"
                      ></div>
                      <b-select
                        v-model="selectedFacet"
                        :options="facetOptions"
                        @change="facetTypeChange(index, facetIndex)"
                      >
                      </b-select>
                    </div> -->
                    <div
                      class="col"
                      style="display: flex; flex-direction: column; gap: 30px;"
                    >
                      <div
                        class="row"
                        style="flex-wrap: nowrap; align-items: center"
                        v-for="(facet, facetIndex) in actionFacets"
                        :key="'facet_' + facetIndex"
                      >
                        <div
                          class="delete-small"
                          @click="deleteFacet(index, facetIndex)"
                        ></div>
                        <div class="type">
                          <b-select
                            v-model="facet.value"
                            :options="facetOptions"
                            @change="
                              (value) => facetTypeChange(value, facetIndex)
                            "
                          >
                          </b-select>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      style="justify-content: flex-end; width: 100%;"
                    >
                      <div class="btn-container">
                        <div
                          :class="[
                            'add',
                            'btn',
                            // { disabled: cantAdd[index].facet },
                          ]"
                          @click="
                            addFacet()
                            touchedTrigger = true
                          "
                        >
                          {{ $t("visualMachine.addFacet") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="actionType == actionTypeOptions[1].value"
                    class="row"
                    style="margin-top: 48px;"
                  >
                    <div class="column">
                      <div style="margin-bottom: 8px;">
                        {{ $t("setup.facetsTitle") }}
                      </div>
                      <div class="input-desc" style="margin-bottom: 30px;">
                        {{ $t("visualMachine.queryFacetDesc") }}
                      </div>
                    </div>
                    <div
                      class="divider"
                      style="width: 100%; margin-bottom: 30px; margin-top: 0px"
                    ></div>
                    <div
                      class="col"
                      style="display: flex; flex-direction: column; gap: 30px;"
                    >
                      <div
                        class="row"
                        style="flex-wrap: nowrap; align-items: center"
                        v-for="(facet, facetIndex) in queryFacets"
                        :key="'facet_' + facetIndex"
                      >
                        <div
                          class="delete-small"
                          @click="deleteQueryFacet(facetIndex)"
                        ></div>
                        <div class="type" style="width: 30%;">
                          <b-select
                            v-model="facet.value"
                            :options="queryFacetOptions"
                          >
                          </b-select>
                        </div>
                        <div
                          class="value"
                          style="margin-left: 24px; width: 50%;"
                          v-if="facet.value !== 'price'"
                        >
                          <b-input v-model="facet.text"> </b-input>
                        </div>
                        <div
                          class="value"
                          style="margin-left: 24px; width: 50% ;display: flex; flex-direction: row; justify-content: space-between;"
                          v-if="facet.value === 'price'"
                        >
                          <div style="width: 48%; position: relative;">
                            <b-input
                              v-model="facet.from"
                              style="padding-right: 30px;"
                              type="number"
                            >
                            </b-input>
                            <div class="currency">{{ currencyCode }}</div>
                          </div>
                          <div style="width: 48%; position: relative;">
                            <b-input
                              v-model="facet.to"
                              style="padding-right: 30px;"
                              type="number"
                            >
                            </b-input>
                            <div class="currency">{{ currencyCode }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      style="justify-content: flex-end; width: 100%;"
                    >
                      <div class="btn-container">
                        <div
                          :class="[
                            'add',
                            'btn',
                            // { disabled: cantAdd[index].facet },
                          ]"
                          @click="
                            addQueryFacet()
                            touchedTrigger = true
                          "
                        >
                          {{ $t("visualMachine.addFacet") }}
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div style="margin-bottom: 8px;">
                        {{ $t("visualMachine.querySorting") }}
                      </div>
                      <div class="input-desc" style="margin-bottom: 30px;">
                        {{ $t("visualMachine.querySortingDesc") }}
                      </div>
                    </div>
                    <div
                      class="divider"
                      style="width: 100%; margin-bottom: 30px; margin-top: 0px"
                    ></div>
                    <div
                      class="col"
                      style="display: flex; flex-direction: column; gap: 30px;"
                    >
                      <div
                        class="row"
                        style="flex-wrap: nowrap; align-items: center"
                      >
                        <b-form-checkbox
                          v-model="sortByRelevance"
                          @change="sortByHistorycalData = false"
                        >
                          {{ $t("visualMachine.relevance") }}</b-form-checkbox
                        >
                      </div>
                      <div
                        v-if="
                          sortByHistorycalData == false &&
                            sortByRelevance == true
                        "
                        style="display: flex; flex-direction: column;gap: 30px;"
                      >
                        <div
                          class="row"
                          style="display: flex; flex-direction: row; padding-left: 32px"
                          v-for="(sort, sortIndex) in sortingValues"
                          :key="'sort_' + sortIndex"
                        >
                          <b-form-checkbox
                            v-model="relevanceOption"
                            :value="sort.value"
                          >
                            {{ sort.text }}</b-form-checkbox
                          >
                        </div>
                      </div>

                      <div
                        class="row"
                        style="flex-wrap: nowrap; align-items: center"
                      >
                        <b-form-checkbox
                          v-model="sortByHistorycalData"
                          @change="sortByRelevance = false"
                        >
                          {{
                            $t("visualMachine.historicalData")
                          }}</b-form-checkbox
                        >
                      </div>
                      <div
                        class="row"
                        style="display: flex; flex-direction: row; padding-left: 32px"
                        v-if="
                          sortByHistorycalData == true &&
                            sortByRelevance == false
                        "
                      >
                        <b-form-checkbox
                          v-model="historicalOption"
                          value="clicks"
                        >
                          {{ $t("visualMachine.clickNumber") }}</b-form-checkbox
                        >
                      </div>
                      <div
                        class="row"
                        style="display: flex; flex-direction: row; padding-left: 32px"
                        v-if="
                          sortByHistorycalData == true &&
                            sortByRelevance == false
                        "
                      >
                        <b-form-checkbox
                          v-model="historicalOption"
                          value="views"
                        >
                          {{ $t("visualMachine.viewsNumber") }}</b-form-checkbox
                        >
                      </div>
                    </div>

                    <div
                      class="row"
                      style="justify-content: flex-end; width: 100%;"
                      v-if="querySorting.key == ''"
                    >
                      <div class="btn-container">
                        <div
                          :class="[
                            'add',
                            'btn',
                            // { disabled: cantAdd[index].facet },
                          ]"
                          @click="showRelevanceModal = true"
                        >
                          {{ $t("visualMachine.addSorting") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="btn-container">
                    <div :class="['add','btn',{disabled:cantAddAction}]" @click="addAction()"> {{$t('visualMachine.addAction')}} </div>
                  </div> -->
              </div>
            </div>
          </b-collapse>
        </div>
        <div
          :class="['collapse-container', { expanded: actionCatExpanded }]"
          v-if="
            selectedRecommendation.extra.showcase.visualizationType ==
              'buttons' ||
              selectedRecommendation.extra.noResult.visualizationType ==
                'buttons'
          "
        >
          <div
            class="header-collapse"
            @click="actionCatExpanded = !actionCatExpanded"
          >
            <div
              class="warning actions"
              v-if="touchedActions || !queryCheck"
              @mouseenter="$bvToast.show('actions-toast')"
              @mouseleave="$bvToast.hide('actions-toast')"
            >
              <div class="warn-icon"></div>
              <b-toast
                id="actions-toast"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                <span
                  v-for="(warning, warningIndex) in cantAddActionsWarnings"
                  :key="'warning_action_' + warningIndex"
                >
                  <br v-if="warningIndex > 0" />
                  {{ warning }}
                </span>
              </b-toast>
            </div>
            <div class="title-container">
              <div class="label-container">
                <div class="sub-title">
                  {{ $t("visualMachine.layerActionCat") }}
                </div>
                <div class="desc">{{ $t("visualMachine.impactRec") }}</div>
              </div>
            </div>
            <div class="icon"></div>
          </div>
          <b-collapse accordion="accordion" v-model="actionCatExpanded">
            <div class="accordion-content">
              <div class="container">
                <div
                  class="row checkbox-row"
                  style="color: #52575C; font-size: 16px; line-height: 24px; margin-bottom: 8px; align-items: center;"
                >
                  <b-form-group>
                    <b-form-radio
                      v-model="recently"
                      :value="recently"
                      @change="(value) => (recently = value)"
                      disabled
                    >
                      {{ $t("visualMachine.recentlyCategories") }}
                    </b-form-radio>
                  </b-form-group>
                </div>
                <div
                  style="color: #72828F; font-size: 16px; line-height: 24px;"
                >
                  {{ $t("visualMachine.recentlyCategoriesDesc") }}
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="cta-container">
          <div
            :class="['btn', 'delete', { disabled: !selectedRecommendation.id }]"
            @click="showDeleteModal = true"
          >
            {{ $t("visualMachine.deleteRecommendation") }}
          </div>
          <div
            :class="['btn', 'save', { disabled: false }]"
            @click="saveRecommendation"
          >
            {{
              selectedRecommendation.id
                ? $t("visualMachine.updateRecommendation")
                : $t("visualMachine.createRecommendation")
            }}
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import axios from "@/utils/Axios"
import lodash from "lodash"
import moment from "moment"
import Utils from "@/utils/Utils"
import ASPanel from "@/components/ASPanel/ASPanel"
import { currencies } from "currencies.json"
import templates from "./templates.json"

export default {
  name: "Recommendations",
  DRAG_OPTIONS: Utils.DRAG_OPTIONS,
  EMPTY_RECOMMENDATION: {
    name: "",
    shopId: null,
    description: "",
    isActive: true,
    tag: "layer",
    extra: {
      actionType: "manually",
      showcase: {
        visualizationType: "carousel",
      },
      noResult: {
        visualizationType: "carousel",
      },
    },
    condition: {
      type: "disjunction",
      conditions: [
        {
          type: "conjunction",
          conditions: [
            {
              type: "always",
            },
          ],
        },
      ],
    },
    recommender: {
      type: "limit",
      recommender: {
        type: "custom",
        content: {
          products: [],
        },
      },
      size: 20,
    },
  },
  data() {
    return {
      isloadingRecommendationList: true,
      recommendationList: [],
      filterWord: "",
      showProductsPreview: false,
      showButtonsPreview: false,
      showProductsPreviewInfo: false,
      showEmptyPreviewInfo: false,
      showRelevanceModal: false,
      showChangingTime: false,
      recommendationInfoExpanded: false,
      schedulingExpanded: false,
      conditionsExpanded: false,
      actionExpanded: false,
      actionCatExpanded: false,
      changedWebsite: false,
      triggersExpanded: {
        trigger0: false,
      },
      locale: {
        applyLabel: this.$t("visualMachine.applyLabel"),
        cancelLabel: this.$t("visualMachine.cancel"),
        format: "dd mmm yyyy HH:MM",
      },
      sortByRelevance: false,
      sortByHistorycalData: false,
      recently: true,
      queryCheck: true,
      querySearch: "",
      queryFacets: [],
      querySorting: { key: "", value: "" },
      sortingValues: [],
      triggerType: "layer",
      toggledAll: false,
      onlyHistorical: false,
      facetOptions: [],
      queryFacetOptions: [],
      selectedFacet: null,
      showPanel: false,
      actionType: "",
      VMIndex: 0,
      showDeleteTriggerModal: false,
      showDeleteModal: false,
      modalTitle: "",
      modalDesc: "",
      relevanceOption: null,
      historicalOption: null,
      historicalTimeOption: "30",
      unconfirmedTime: "30",
      deletingTriggerIndex: 0,
      website: "",
      actionFacets: null,
      actionFacetsLength: 0,
      categoriesList: [],
      showAutocomplete: false,
      touchedName: false,
      touchedTrigger: false,
      touchedActions: false,
      conditionsContent: "",
      htmlContent: JSON.stringify(
        JSON.parse(
          '{ "type": "equal", "firstOperand": "{{item.brand}}", "secondOperand": "Nike" }'
        ),
        undefined,
        4
      ),
      htmlContent1: JSON.stringify(
        JSON.parse(
          '{"type": "conjunction","subjects": [{ "type": "contains", "haystack": "{{item.name}}", "needle": "Promo" },{ "type": "lessThan", "firstOperand": "{{item.price}}", "secondOperand": 50 }]}'
        ),
        undefined,
        4
      ),
      brandTemplate: JSON.stringify(templates[0], undefined, 4),
      sellingTemplate: JSON.stringify(templates[1], undefined, 4),
      listingTemplate: JSON.stringify(templates[2], undefined, 4),
      showConditionsGuide: false,
      validityType: { type: "timeInterval", from: 0, to: 0 },
      sortingOrder: [
        { value: "ASC", text: "ASC" },
        { value: "DESC", text: "DESC" },
      ],
      validityOptions: [
        {
          value: "always",
          text: this.$t("visualMachine.runRecommendationAlways"),
        },
        {
          value: "timeInterval",
          text: this.$t("visualMachine.runRecommendationSpecific"),
        },
      ],
      triggerOptions: [
        { value: "layer", text: this.$t("visualMachine.recTriggerOpt1") },
        { value: "external", text: this.$t("visualMachine.recTriggerOpt2") },
      ],
      queryOptions: [
        { value: "queryContains", text: this.$t("visualMachine.matches") },
        { value: "queryIs", text: this.$t("visualMachine.matchesExactly") },
        { value: "always", text: this.$t("visualMachine.all") },
      ],
      matchExactOptions: [
        { value: "filterIs", text: this.$t("visualMachine.specificFilters") },
        { value: "filterContains", text: this.$t("visualMachine.anyFilter") },
      ],
      matchExactOptionsNoFacets: [
        { value: "filterIs", text: this.$t("visualMachine.justQuery") },
        {
          value: "filterContains",
          text: this.$t("visualMachine.queryFilters"),
        },
      ],
      actionTypeOptions: [
        { value: "manually", text: this.$t("visualMachine.manually") },
        { value: "query", text: this.$t("visualMachine.query") },
        { value: "similar", text: this.$t("visualMachine.similar") },
        { value: "upselled", text: this.$t("visualMachine.upsell") },
      ],
      historicalTime: [
        { value: "15", text: this.$t("visualMachine.15gg") },
        { value: "30", text: this.$t("visualMachine.30gg") },
      ],
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentConfiguration",
      "getVMSelectedProducts",
      "getShowDiscardChangesModal",
      "getSelectedRecommendation",
      "getPendingRouting",
      "getDisplayedWebsites",
      "getBaseUrl",
    ]),
    showAddTrigger() {
      var lastTrigger = this.triggers.slice(-1)[0]
      return lastTrigger
        ? lastTrigger.facets.filters.length > 0 ||
            lastTrigger.queries.length > 0
        : false
    },
    showDiscardChangesModal: {
      get() {
        return this.getShowDiscardChangesModal
      },
      set(value) {
        this.setShowDiscardChangesModal(value)
      },
    },
    selectedRecommendation: {
      get() {
        return this.getSelectedRecommendation
      },
      set(value) {
        this.actionType = value?.extra?.actionType
        this.setSelectedRecommendation(value)
      },
    },
    timeframes: {
      get() {
        let timeInterval = this.selectedRecommendation.condition?.conditions?.[0]?.conditions?.find(
          (cond) => cond.type === "timeInterval"
        )
        return {
          // type: timeInterval?.type,
          startDate: timeInterval?.from * 1000,
          endDate: timeInterval?.to * 1000,
        }
      },
      set(value) {
        this.selectedRecommendation.condition.conditions.conditions = this.selectedRecommendation.condition?.conditions?.conditions?.filter(
          (cond) => cond.type !== "timeInterval" || cond.type !== "always"
        )

        let newValidity = {
          type: this.validityType?.type,
        }
        ;(newValidity.from = value.startDate
          ? value.startDate.getTime() / 1000
          : 0),
          (newValidity.to = value.endDate ? value.endDate.getTime() / 1000 : 0)
        this.validityType.from = newValidity.from
        this.validityType.to = newValidity.to
        this.selectedRecommendation.condition?.conditions?.conditions?.push(
          newValidity
        )
      },
    },
    triggers: {
      get() {
        var objToReturn = []
        objToReturn = this.selectedRecommendation.condition?.conditions.map(
          (trigger, index) => {
            if (index === 0) {
              var facetsContainer = trigger.conditions?.filter((item) =>
                this.matchExactOptions
                  .map((facetOption) => facetOption.value)
                  .includes(item.type)
              )[0]
              return {
                queries: trigger.conditions?.filter((item) =>
                  this.queryOptions
                    .map((queryOption) => queryOption.value)
                    .includes(item.type)
                ),
                facets: {
                  type: facetsContainer?.type,
                  filters: facetsContainer?.filters.flat().map((item) => {
                    return {
                      type: Object.keys(item)[0],
                      value: Object.values(item)[0],
                    }
                  }),
                },
              }
            }
          }
        )
        return objToReturn
      },
      set(values) {
        this.selectedRecommendation.condition.conditions = values.map(
          (value) => {
            return {
              type: "conjunction",
              conditions: [
                ...value.queries,
                {
                  type: value.facets.type,
                  filters: value.facets.filters.map((facet) => {
                    var objectToReturn = {}
                    objectToReturn[facet.type] = facet.value
                    return [objectToReturn]
                  }),
                },
              ],
            }
          }
        )
      },
    },
    cantAdd() {
      return this.triggers.map((trigger) => {
        return {
          query: trigger.queries?.some(
            (query) =>
              query.type != this.queryOptions[0].value ||
              (query.type == this.queryOptions[0].value && query.value == "")
          ),
          facet:
            trigger.facets.filters?.some(
              (facet) => facet.type == null || facet.value == ""
            ) ||
            trigger.facets.filters.length ==
              this.getCurrentConfiguration.setup.facets.length + 2,
        }
      })
    },
    priceValues: {
      get() {
        return this.triggers.map((trigger) =>
          trigger.facets.filters.map((filter) => {
            return {
              from: filter.value.from,
              to: filter.value.to,
            }
          })
        )
      },
      set(value) {
        var triggers = lodash.cloneDeep(this.triggers)
        value.forEach((item, index) => {
          item.forEach((value, filterIndex) => {
            if (value.from || value.to) {
              triggers[index].facets.filters[filterIndex].value = {}
              triggers[index].facets.filters[
                filterIndex
              ].value.from = value.from ? parseInt(value.from) : 0
              triggers[index].facets.filters[filterIndex].value.to = value.to
                ? parseInt(value.to)
                : 0
            }
          })
        })
        this.triggers = triggers
      },
    },
    recommendationListFiltered() {
      console.log(this.filterWord)
      return this.recommendationList.filter((recommendation) =>
        recommendation.name
          .toUpperCase()
          .includes(this.filterWord.toUpperCase())
      )
    },
    cantAddAction() {
      return (
        this.selectedRecommendation.actions?.length == 4 ||
        this.selectedRecommendation.actions?.filter(
          (action) => action.products.length == 0
        ).length > 0
      )
    },
    cantAddActionsWarnings() {
      var arrayToReturn = []
      if (this.selectedRecommendation.actions?.length == 0)
        arrayToReturn.push(this.$t("visualMachine.almost"))
      if (!this.queryCheck)
        arrayToReturn.push(this.$t("visualMachine.searchMissing"))
      // if (
      //   this.selectedRecommendation.actions?.filter(
      //     (action) => action.products.length == 0
      //   ).length > 0
      // )
      //   arrayToReturn.push(this.$t("visualMachine.oneAction"))
      // if (this.checkIsNotAlive(this.selectedRecommendation))
      //   arrayToReturn.push(this.$t("visualMachine.productIsNotAlive"))
      // if (
      //   this.hasDuplicates(
      //     this.selectedRecommendation.actions?.map((item) => item.type)
      //   )
      // )
      //   arrayToReturn.push(this.$t("visualMachine.twoTypes"))
      // if (
      //   this.intersection(
      //     this.selectedRecommendation.actions?.map((item) => item.products)
      //   )
      // )
      //   arrayToReturn.push(this.$t("visualMachine.twoProducts"))
      return arrayToReturn
    },
    cantAddName() {
      return this.selectedRecommendation.name == ""
    },
    cantAddValidity() {
      return (
        this.validityType.type == this.validityOptions[1].value &&
        this.validityType.from == 0
      )
    },
    cantAddFilterArray() {
      // return this.selectedRecommendation.conditions?.conditions.map(
      //   (subCondition) => this.cantAddFilter(subCondition)
      // )
      return false
    },
    cantAddFilters() {
      // return this.cantAddFilterArray?.some((item) => item)
      return false
    },

    cantSaveRecommendation() {
      console.log(this.cantAddName, this.cantAddValidity, this.cantAddFilters)
      /*TODO add cantAddFilters*/
      return this.cantAddName || this.cantAddValidity
    },
    currencyCode() {
      const currency = currencies.find(
        (curr) =>
          curr.code ===
          this.getCurrentConfiguration.setup.config.groupPrice.currencyCode
      )
      return currency?.symbolNative
    },
  },
  methods: {
    ...mapActions([
      "setVMSelectedProducts",
      "setShowDiscardChangesModal",
      "setSelectedRecommendation",
      "setConfigurationModal",
      "setCurrentConfiguration",
      "setOpenedWebsitesList",
      "setPendingRouting",
    ]),
    onTimeChange(value) {
      this.showChangingTime = true
      this.unconfirmedTime = value
    },
    async handleConfirmChangeTime() {
      this.historicalTimeOption = this.unconfirmedTime
      this.showChangingTime = false

      await axios.put(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.RECOMMENDATIONS +
          Utils.HISTORYINTERVAL,
        { historyInterval: this.historicalTimeOption }
      )
    },
    openModal(type) {
      this.setConfigurationModal({ field: "type", value: type })
      this.$bvModal.show("config-modal")
    },
    changeRecommender(actionType) {
      this.selectedRecommendation.extra.actionType = actionType
      if (actionType !== "manually") {
        this.selectedFacet = this.facetOptions[0].value ?? null
      }
    },
    selectRecommendation(value) {
      this.selectedRecommendation = lodash.cloneDeep(value)
      if (!this.selectedRecommendation.shopId) {
        this.selectedRecommendation.shopId = this.getCurrentConfiguration.id
      }
      this.validityType = this.selectedRecommendation.condition?.conditions?.find(
        (cond) =>
          cond.conditions[0]?.type === "timeInterval" ||
          cond.conditions[0]?.type === "always"
      )?.conditions?.[0] ?? { type: "timeInterval", from: 0, to: 0 }

      if (this.selectedRecommendation.condition?.conditions?.length > 1) {
        this.conditionsContent = JSON.stringify(
          this.selectedRecommendation.condition?.conditions[1],
          undefined,
          4
        )
      }
      this.triggerType = this.selectedRecommendation?.tag ?? "layer"
      this.actionType = this.selectedRecommendation?.extra?.actionType
      this.recommendationInfoExpanded = true
      this.schedulingExpanded = false
      this.conditionsExpanded = false
      this.actionExpanded = false
      this.touchedName = false
      this.touchedTrigger = false
      this.touchedActions = false
      const queryString = this.selectedRecommendation?.recommender?.recommender
        ?.queryString
      if (queryString?.includes("sort")) {
        const sortField = queryString
          ?.split("sort")?.[1]
          ?.replace(/[0-9]/g, "")
          ?.replaceAll("[", "")
          ?.replaceAll("]", "")
        const sortKey = sortField?.split("=")?.[0]

        if (sortKey.includes("clicks")) {
          this.sortByHistorycalData = true
          this.historicalOption = "clicks"
        } else if (sortKey.includes("views")) {
          this.sortByHistorycalData = true
          this.historicalOption = "views"
        } else {
          const selected = this.sortingValues?.find(
            (sorting) => sorting.key === sortKey
          )
          this.sortByRelevance = true
          this.relevanceOption = selected.value
        }
      }

      value.conditions?.conditions.forEach((trigger, index) => {
        this.triggersExpanded["trigger" + index] = false
      })
      this.actionFacets = this.getActionFacets()
    },
    checkIsNotAlive(recommendation) {
      return recommendation.actions?.some(
        (action) =>
          action.products.filter((product) => !product.isAlive).length > 0
      )
    },
    getActionFacets() {
      let recommenderType = this.selectedRecommendation.extra.actionType
      if (!recommenderType) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.actionType = "manually"
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.actionType = recommenderType
      }
      let filters = null
      if (this.actionType) {
        if (this.actionType === "manually") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          // this.selectedRecommendation.recommender.recommender = {
          //   type: "custom",
          //   content: { products: [] },
          // }
          filters = this.selectedRecommendation?.recommender?.recommender
            ?.content?.products
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.actionFacetsLength = filters?.length ?? 0
        } else if (
          this.actionType === "similar" ||
          this.actionType === "upselled"
        ) {
          if (
            !this.selectedRecommendation.recommender.recommender?.queryString
          ) {
            if (this.facetOptions[0]) {
              filters = [this.facetOptions[0]]
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.selectedRecommendation.recommender.recommender = {
              type: "queryTemplate",
              baseUrl: `${this.getBaseUrl}/API/shops/${this.getCurrentConfiguration.key}/search`,
              queryString: `?filters[0][0][${this.facetOptions[0].value}]={:context.item.${this.facetOptions[0].value}}`,
            }
          } else {
            let params = new URLSearchParams(
              this.selectedRecommendation?.recommender?.recommender?.queryString
            )
            filters = []
            params?.values().forEach((e) => {
              let value = e.replace("{:context.item.", "").replace("}", "")
              // console.log(this.facetOptions)
              // console.log(this.facetOptions.find((facet) => facet.value === value))
              let facet = this.facetOptions.find(
                (facet) => facet.value === value
              )
              console.log(facet)
              if (facet) {
                filters.push(facet)
              }
            })
          }
        } else {
          let params = new URLSearchParams(
            this.selectedRecommendation?.recommender?.recommender?.queryString
          )
          let priceFilter = {}
          params.entries().forEach((q) => {
            const parsed = q?.[0]
              ?.replace(/filters\[\d+\]\[\d+\]/, "")
              ?.replace(/sort\[\d+\]/, "")
              ?.replace("[", "")
              ?.replace("]", "")
              ?.replace("[from]", "")
              ?.replace("[to]", "")
            if (q?.[0] === "q") {
              this.querySearch = q?.[1]
            }
            if (q?.[0]?.includes("filters")) {
              if (q?.[0]?.includes("from")) {
                priceFilter.value = parsed
                priceFilter.text = ""
                priceFilter.from = q?.[1]
              } else if (q?.[0]?.includes("to")) {
                priceFilter.value = parsed
                priceFilter.text = ""
                priceFilter.to = q?.[1]
              } else {
                let oldFacets = this.queryFacets
                oldFacets = [...oldFacets, { value: parsed, text: q?.[1] }]
                this.queryFacets = oldFacets
              }
            }
            if (q?.[0]?.includes("sort")) {
              const sortData = this.getCurrentConfiguration.layout.sort.values?.find(
                (sort) => sort.key === parsed || sort.label === parsed
              )
              this.querySorting = {
                key: parsed,
                value: sortData?.value,
                label: sortData?.text ?? sortData?.label,
              }
            }
          })
          if (Object.keys(priceFilter)?.length > 0) {
            let oldFacets = this.queryFacets
            oldFacets = [...oldFacets, priceFilter]
            this.queryFacets = oldFacets
          }
        }
      }
      return filters ?? []
    },
    setActionFacets(values) {
      this.actionFacets = values
    },
    addQuery(triggerIndex) {
      var triggers = lodash.cloneDeep(this.triggers)
      var queries = lodash.cloneDeep(triggers[triggerIndex].queries)
      queries.push({ type: this.queryOptions[0].value, value: "" })
      triggers[triggerIndex].queries = queries
      this.triggers = triggers
    },
    addFacet() {
      var actions = lodash.cloneDeep(this.actionFacets)
      actions.push({ type: null, value: "" })
      this.setActionFacets(actions)
      this.actionFacetsLength = actions.length
    },
    addQueryFacet() {
      var actions = lodash.cloneDeep(this.queryFacets)
      actions.push({ type: null, value: "" })
      // this.setActionFacets(actions)
      this.queryFacets = actions
    },
    deleteQuery(triggerIndex, queryIndex) {
      var triggers = lodash.cloneDeep(this.triggers)
      var queries = lodash.cloneDeep(triggers[triggerIndex].queries)
      queries.splice(queryIndex, 1)
      triggers[triggerIndex].queries = queries
      this.triggers = triggers
    },
    deleteFacet(triggerIndex, facetIndex) {
      var actionFacets = lodash.cloneDeep(this.actionFacets)
      actionFacets.splice(facetIndex, 1)
      this.actionFacets = actionFacets
    },
    deleteQueryFacet(facetIndex) {
      var queryFacets = lodash.cloneDeep(this.queryFacets)
      queryFacets.splice(facetIndex, 1)
      this.queryFacets = queryFacets
    },
    addAction() {
      var actions = lodash.cloneDeep(this.selectedRecommendation.actions)
      actions.push({ type: this.actionTypeOptions[0].value, products: [] })
      this.selectedRecommendation.actions = actions
    },
    deleteAction(index) {
      var actions = lodash.cloneDeep(this.selectedRecommendation.actions)
      actions.splice(index, 1)
      this.selectedRecommendation.actions = actions
    },
    addTrigger() {
      var triggers = lodash.cloneDeep(this.triggers)
      triggers.push({
        queries: [],
        facets: { filters: [], type: this.matchExactOptions[0].value },
      })
      this.triggers = triggers
      this.recommendationInfoExpanded = false
      this.schedulingExpanded = false
      this.conditionsExpanded = false
      this.actionExpanded = false
      var objectToSet = {}
      triggers.forEach((trigger, index) => {
        objectToSet["trigger" + index] = false
      })
      objectToSet["trigger" + (triggers.length - 1)] = true
      this.triggersExpanded = lodash.cloneDeep(objectToSet)
    },
    async copyRecommendation(recommendation) {
      let localRecommendation = lodash.cloneDeep(recommendation)
      delete localRecommendation.id
      localRecommendation.name += " - copy"
      await axios.post(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.RECOMMENDATIONS,
        localRecommendation
      )
      this.isloadingRecommendationList = true
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.RECOMMENDATIONS
        )
        .then((response) => {
          this.recommendationList = response.data.rules
        })
        .finally(() => (this.isloadingRecommendationList = false))
    },
    async saveRecommendation() {
      let queryString = ""

      this.selectedRecommendation.tag = this.triggerType
      if (this.selectedRecommendation.extra.actionType === "manually") {
        this.selectedRecommendation.recommender.recommender.content = {
          products: this.actionFacets,
        }
        this.selectedRecommendation.recommender.recommender.type = "custom"
      } else if (this.selectedRecommendation.extra.actionType === "query") {
        if (this.querySearch === "") {
          this.queryCheck = false
          return
        } else {
          this.queryCheck = true
        }
        queryString = `q=${this.querySearch}`
        let queryFacetsGrouped = {}
        this.queryFacets?.map((facet) => {
          let typeGroup = queryFacetsGrouped[facet.value]
          if (typeGroup) {
            queryFacetsGrouped[facet.value] = [...typeGroup, facet]
          } else {
            queryFacetsGrouped[facet.value] = [facet]
          }
        })
        Object.entries(queryFacetsGrouped).map((facetGroup, groupIndex) => {
          facetGroup?.[1]?.map((facet, index) => {
            if (facetGroup[0] === "price" || facetGroup[0] === "sellingPrice") {
              queryString += `&filters[${groupIndex}][${index}][${facet.value}][from]=${facet.from}`
              queryString += `&filters[${groupIndex}][${index}][${facet.value}][to]=${facet.to}`
            } else {
              queryString += `&filters[${groupIndex}][${index}][${facet.value}]=${facet.text}`
            }
          })
        })
        if (this.sortByRelevance) {
          if (this.relevanceOption) {
            const selected = this.sortingValues?.find(
              (sorting) => sorting.value === this.relevanceOption
            )
            if (selected?.key === "sellingPrice") {
              if (this.relevanceOption?.toLowerCase()?.includes("asc")) {
                console.log("ASC")
                queryString += `&sort[0][sellingPrice]=ASC`
              } else {
                queryString += `&sort[0][sellingPrice]=DESC`
              }
            } else {
              queryString += `&sort[0][${selected?.key}]=DESC`
            }
          }
        }
        if (this.sortByHistorycalData) {
          if (this.historicalOption) {
            queryString += `&sort[0][history.${this.historicalOption}]=DESC`
          }
        }

        if (this.conditionsContent !== "") {
          const oldConditions = this.selectedRecommendation?.condition
            ?.conditions
          this.selectedRecommendation.condition.conditions = [
            ...oldConditions,
            JSON.parse(this.conditionsContent),
          ]
        }
        this.selectedRecommendation.recommender.recommender.queryString = queryString
        this.selectedRecommendation.recommender.recommender.baseUrl = `${this.getBaseUrl}/API/shops/${this.getCurrentConfiguration.key}/search`
        this.selectedRecommendation.recommender.recommender.type = "query"
      } else {
        let lastIndex = -1
        this.actionFacets?.forEach((val, index) => {
          let propName = val?.value
          if (val.value?.toLowerCase()?.includes("category")) {
            propName = "categories"
          }
          if (index === 0) {
            queryString = `?filters[0][0][${propName}]={:context.item.${val.value}}`
          } else {
            queryString += `&filters[${index}][][${propName}]={:context.item.${val.value}}`
          }
          lastIndex = index
        })
        if (this.selectedRecommendation.extra.actionType === "upselled") {
          if (lastIndex !== -1) {
            queryString += `&filters[${lastIndex +
              1}][][sellingPrice][from]={:context.item.sellingPrice}`
          } else {
            queryString = `?filters[0][][sellingPrice][from]={:context.item.sellingPrice}`
          }
        }
        this.selectedRecommendation.recommender.recommender.queryString = queryString
        this.selectedRecommendation.recommender.recommender.baseUrl = `${this.getBaseUrl}/API/shops/${this.getCurrentConfiguration.key}/search`
        this.selectedRecommendation.recommender.recommender.type =
          "queryTemplate"
        this.selectedRecommendation.recommender.recommender.content = null
      }
      if (this.selectedRecommendation.id)
        await axios
          .put(
            Utils.SHOPS +
              "/" +
              this.getCurrentConfiguration.id +
              Utils.RECOMMENDATIONS +
              "/" +
              this.selectedRecommendation.id,
            this.selectedRecommendation
          )
          .then(() => {
            this.selectedRecommendation = null
            this.validityType.type = "timeInterval"
          })
      else
        await axios
          .post(
            Utils.SHOPS +
              "/" +
              this.getCurrentConfiguration.id +
              Utils.RECOMMENDATIONS,
            this.selectedRecommendation
          )
          .then(() => {
            this.selectedRecommendation = null
            this.validityType.type = "timeInterval"
          })
      this.isloadingRecommendationList = true
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.RECOMMENDATIONS
        )
        .then((response) => {
          this.recommendationList = response.data.rules
        })
        .finally(() => (this.isloadingRecommendationList = false))
    },
    async deleteCampaign(recommendationId) {
      this.isloadingRecommendationList = true
      await axios.delete(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.RECOMMENDATIONS +
          "/" +
          recommendationId
      )
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.RECOMMENDATIONS
        )
        .then((response) => {
          this.recommendationList = response.data.rules
        })
      this.isloadingRecommendationList = false
    },
    async saveAll() {
      this.isloadingRecommendationList = true
      await axios.put(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.RECOMMENDATIONS,
        { rules: this.recommendationList }
      )
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.RECOMMENDATIONS
        )
        .then((response) => {
          this.recommendationList = response.data.rules
        })
      this.isloadingRecommendationList = false
    },
    validityText(validity) {
      // const momentFormat='DD MMM YYYY, HH:mm'

      const momentFormat = "lll"
      return (
        moment(validity.from * 1000).format(momentFormat) +
        " - " +
        moment(validity.to * 1000).format(momentFormat)
      )
    },
    async onChange(recommendation) {
      this.isloadingRecommendationList = true
      await axios.put(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.RECOMMENDATIONS +
          "/" +
          recommendation.id,
        recommendation
      )
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.RECOMMENDATIONS
        )
        .then((response) => {
          this.recommendationList = response.data.rules
        })
      this.isloadingRecommendationList = false
    },
    facetTypeChange(value, index) {
      let localActionFacets = lodash.cloneDeep(this.actionFacets)
      localActionFacets[index] = { text: value, value: value }
      this.actionFacets = localActionFacets
    },
    sortingChange(val) {
      const selected = this.getCurrentConfiguration.layout.sort.values?.find(
        (sort) => sort.label === val
      )
      this.querySorting = {
        key: selected.key ?? selected?.label,
        value: selected.value,
        label: selected?.label,
      }
    },
    openPanel(actionType, products, index) {
      this.VMIndex = index
      this.actionType = actionType
      this.setVMSelectedProducts(products)
      this.showPanel = true
    },
    onClosePanel() {
      this.showPanel = false
      this.touchedActions = false
      this.actionFacets = this.getVMSelectedProducts
      this.actionFacetsLength = this.getVMSelectedProducts?.length
    },
    cantAddFilter(condition) {
      var filtersArray = condition?.conditions
        ?.slice(-1)[0]
        .filters.map((item) => item[0])
        .map((item) => Object.keys(item)[0])
      return (
        condition.conditions?.some((subCondition) =>
          subCondition.filters
            ? subCondition.filters?.some((filter) => {
                var key = Object.keys(filter[0])[0]
                return (
                  key == "null" ||
                  filter[0][key] == "" ||
                  (key == "price" && filter[0].price.from >= filter[0].price.to)
                )
              })
            : (subCondition.type == this.queryOptions[0].value ||
                subCondition.type == this.queryOptions[1].value) &&
              subCondition.value == ""
        ) ||
        (condition?.conditions?.length == 1 &&
          condition.conditions[0].filters.length == 0) ||
        this.hasDuplicates(filtersArray)
      )
    },
    cantAddFilterWarnings(index) {
      var arrayToReturn = []
      var condition = this.selectedRecommendation.condition?.conditions[index]
      var filtersArray = condition?.conditions
        ?.slice(-1)[0]
        .filters.map((item) => item[0])
        .map((item) => Object.keys(item)[0])
      if (
        condition?.conditions?.length == 1 &&
        condition.conditions[0].filters.length == 0
      )
        arrayToReturn.push(this.$t("visualMachine.almostAQuery"))
      if (this.hasDuplicates(filtersArray))
        arrayToReturn.push(this.$t("visualMachine.twoConditions"))
      condition?.conditions?.forEach((subCondition) => {
        if (
          subCondition.filters &&
          subCondition.filters?.some((filter) => {
            var key = Object.keys(filter[0])[0]
            return (
              key == "null" ||
              filter[0][key] == "" ||
              (key == "price" && filter[0].price.from >= filter[0].price.to)
            )
          })
        )
          arrayToReturn.push(this.$t("visualMachine.filterCondition"))
        if (
          (subCondition.type == this.queryOptions[0].value ||
            subCondition.type == this.queryOptions[1].value) &&
          subCondition.value == ""
        )
          arrayToReturn.push(this.$t("visualMachine.queryCondition"))
      })
      return arrayToReturn
    },
    toggleAll(value) {
      this.recommendationList = this.recommendationList.map(
        (recommendation) => {
          var itemToReturn = lodash.cloneDeep(recommendation)
          itemToReturn.isActive = value.value
          return itemToReturn
        }
      )
      this.saveAll()
    },
    async initializeRecommendationList() {
      this.selectedRecommendation = null
      this.validityType.type = "timeInterval"
      this.isloadingRecommendationList = true
      axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.RECOMMENDATIONS
        )
        .then((response) => {
          this.recommendationList = response.data.rules
        })
        .finally(() => (this.isloadingRecommendationList = false))
    },
    async exitWithoutSave() {
      if (this.changedWebsite) {
        this.showDiscardChangesModal = false
        this.setOpenedWebsitesList(false)
        await this.setCurrentConfiguration(
          lodash.clone(
            Utils.getConfigurationFromWebsite(
              this.getDisplayedWebsites,
              this.website
            )
          )
        )
        this.initializeRecommendationList()
        this.changedWebsite = false
      } else {
        this.$router.push({ name: this.getPendingRouting })
        this.setPendingRouting("")
        this.selectedRecommendation = null
        this.validityType.type = "timeInterval"
        this.showDiscardChangesModal = false
      }
    },
    deleteTrigger(index) {
      this.deletingTriggerIndex = index
      this.showDeleteTriggerModal = true
    },
    hasDuplicates(array) {
      return lodash.uniq(array).length !== array?.length
    },
    intersection(array) {
      var arrayToReturn = array.map((item) => item.map((subitem) => subitem.id))
      if (array?.length == 1) return false
      return arrayToReturn?.some((item, index) =>
        arrayToReturn?.some((subitem, subindex) => {
          if (index < subindex)
            return lodash.intersection(item, subitem).length >= 1
        })
      )
    },
    filteredCategoriesList(value) {
      return this.categoriesList.filter(
        (item, index) =>
          item.toUpperCase().includes(value.toUpperCase()) && index < 10
      )
    },
    onInputBlur() {
      setTimeout(() => {
        this.showAutocomplete = false
      }, 150)
    },
    expandAccordion(index) {
      var valueToChange = this.triggersExpanded["trigger" + index]
      this.triggersExpanded = {}
      this.triggers.forEach((trigger, subIndex) => {
        this.triggersExpanded["trigger" + subIndex] = false
      })
      this.triggersExpanded["trigger" + index] = !valueToChange
    },
  },
  async mounted() {
    await this.initializeRecommendationList()

    await axios
      .get(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.RECOMMENDATIONS +
          Utils.HISTORYINTERVAL
      )
      .then(
        (response) =>
          (this.historicalTimeOption = response.data?.historyInterval)
      )
    this.facetOptions = Object.keys(
      this.getCurrentConfiguration.setup.facets
    ).map((item) => {
      return { value: item, text: item }
    })
    this.queryFacetOptions = this.facetOptions
    if (this.facetOptions.find((facet) => facet.value === "category")) {
      let filtered = this.facetOptions.filter(
        (facet) => facet.value !== "category"
      )
      this.facetOptions = [
        ...filtered,
        { value: "sameCategory", text: this.$t("visualMachine.sameCategory") },
        { value: "prevCategory", text: this.$t("visualMachine.prevCategory") },
      ]
    }

    this.sortingValues = this.getCurrentConfiguration.layout.sort.values?.map(
      (sort) => ({
        value: sort.label,
        text: sort.label,
        key: sort.key ?? sort.label,
      })
    )

    this.$root.$on("before-change-website", (website) => {
      this.changedWebsite = true
      this.showDiscardChangesModal = true
      this.website = website
    })
    this.$root.$on("changed-website", () => this.initializeRecommendationList())
    await axios
      .get("shops/" + this.getCurrentConfiguration.key + "/search?")
      .then(
        (response) =>
          (this.categoriesList = Object.keys(response.data.stats.categories))
      )
  },
  components: { ASPanel },
}
</script>

<style src="./Recommendations.less" lang="less" scoped></style>
