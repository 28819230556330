import lodash from "lodash"
// import moment from 'moment';
import axios from "axios"
import axiosUtil from "@/utils/Axios"

export default class Utils {
  static SESSION = "/session"
  static CUSTOMERS = "/customers"
  static CUSTOMER = "/customer"
  static ME = "/me"
  static TIER = "/tier"
  static ECHO = "/echo"
  static SHOPS = "/shops"
  static CMS = "/cms"
  static EVENTS = "/events"
  static MCP = "/most_clicked_products"
  static MSQ = "/most_searched_queries"
  static NRQ = "/no_results_queries"
  static OVERVIEW = "/overview"
  static INTEGRATIONS = "/integrations"
  static SHP = "/shp"
  static GOOGLE_SHOPPING = "/google-shopping"
  static SHOP_DOMAIN = "shopDomain"
  static FROM_TIMESTAMP = "fromTimestamp"
  static TO_TIMESTAMP = "toTimestamp"
  static PAGE = "page"
  static SYNC = "/synchronization"
  static INDUSTRIES = "/industries"
  static LANGUAGES = "/languages"
  static CONF = "/configuration"
  static LANG = "/language"
  static DATA = "/data"
  static ACTIVATION = "/activation"
  static ATTR = "/attributes"
  static TYPES = "/types"
  static PAYMENT = "/payment"
  static HISTORY = "/history"
  static METHODS = "/methods"
  static STRIPE = "/stripe"
  static SEARCH = "/search"
  static REQUIRE = "/require"
  static USERS = "/users"
  static PWD = "/password"
  static RECOVERY = "/recovery"
  static STATUS = "/status"
  static ERROR = "ERROR"
  static FACETS = "/facets"
  static SEO = "/seo"
  static SEOVALUES = "/seo/values"
  static EXPIRED_TOKEN = "Expired token"
  static MODIFIED_TOKEN = "Modified token"
  static NOTIFICATIONS = "/notifications"
  static READ = "/read"
  static BULK = "/bulk"
  static COUNT = "/count"
  static ACTIVE = "/active"
  static COUNTRIES = "/countries"
  static VM = "/visual-machine"
  static RECOMMENDATIONS = "/recommendations"
  static HISTORYINTERVAL = "/history/interval"
  static FROM = "from"
  static TO = "to"
  static SIZE = "size"
  static IS_SUCCESS = "isSuccess"
  static API = "/API"
  static INTEGRATIONS = "/integrations"
  static PLANS = "/plans"
  static PLAN = "/plan"
  static PENDING = "/pending"
  static GS = "/google-shopping"
  static CHECK_FEED = "/check-feed"
  static BANNER_ID = "/uuid/integer"
  static CATEGORIES = "/categories"
  static VERSIONS = "/layer/versions"
  static NOTIFICATION_PAGE_SIZE = 20
  static NOTIFICATION_INTERVAL_GAP = 5000

  static MOMENT_FORMAT = "L"
  static ALL = "all"
  static DESKTOP = "desktop"
  static MOBILE = "mobile"

  static STARTING_DATE = {
    startDate: new Date(),
    endDate: new Date(),
  }

  static getStartingDate() {
    var lastWeek = new Date()
    var today = new Date(new Date().setHours(0, 0, 0, 0))
    lastWeek.setDate(today.getDate() - 7)
    lastWeek.setHours(0, 0, 0, 0)
    var yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)
    var midnight = new Date()
    midnight.setDate(yesterday.getDate())
    midnight.setHours(23, 59, 59, 0)
    return {
      startDate: lastWeek,
      endDate: midnight,
    }
  }

  static DEVICES = [Utils.ALL, Utils.DESKTOP, Utils.MOBILE]

  static TRIAL_PLAN = {
    trial: { key: "trial" },
  }

  static EMPTY_ANALYTICS = {
    columns: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    kpi: {
      total_searches: {
        value: 0,
        rate: 0,
      },
      search_intents: {
        value: 0,
        rate: 0,
      },
      total_clicks: {
        value: 0,
        rate: 0,
      },
      exit_rate: {
        value: 0,
        rate: 0,
      },
      use_search: {
        value: 100,
        rate: 0,
      },
      detail: {
        withResults: 100,
        withClicks: 100,
        // "with_conversions":100
      },
    },
    data: {
      total_searches: [],
      search_intents: [],
      total_clicks: [],
      conversions: [],
      average_value: [],
      total_value: [],
      use_search: [],
      exit_rate: [],
    },
  }

  static DRAG_OPTIONS = {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost",
  }

  static NOTIFICATIONS_CODES = {
    "generic-error": {
      title: "genericErrorTitle",
      message: "genericErrorMessage",
      status: "danger",
      payload: [],
    },
    "gs-missing-feed": {
      message: "missingGSFeedMessage",
      title: "missingFeed",
      redirect: "setup",
      status: "danger",
      payload: ["shopId", "feedUrl"],
    },
    "missing-feed": {
      message: "missingFeedMessage",
      title: "missingFeed",
      redirect: "setup",
      status: "danger",
      payload: ["shopId", "feedUrl"],
    },
    "gs-bad-feed": {
      title: "badFeed",
      message: "badFeedMessage",
      redirect: "setup",
      status: "danger",
      payload: ["shopId", "feedUrl"],
    },
    "synchronization-failed": {
      title: "errorSync",
      message: "errorSyncMessage",
      redirect: "setup",
      status: "danger",
      payload: ["shopId"],
    },
    "synchronization-complete": {
      title: "completedSync",
      status: "info",
      redirect: "",
      message: "completedSyncMessage",
      payload: ["shopId"],
    },
    "payment-success": {
      title: "paymentSuccess",
      status: "info",
      redirect: "",
      message: "paymentSuccessMessage",
      payload: ["paymentId"],
    },
    "payment-failure": {
      title: "paymentFail",
      status: "danger",
      redirect: "billing-information",
      message: "paymentFailMessage",
      payload: ["paymentId"],
    },
    "plan-switch-success": {
      title: "planSwitchSuccessTitle",
      status: "info",
      redirect: "",
      message: "planSwitchSuccessTitleMessage",
      payload: [],
    },
    "plan-switch-failure": {
      title: "planSwitchFailTitle",
      status: "danger",
      redirect: "billing-info",
      message: "planSwitchFailTitleMessage",
      payload: [],
    },
    "vm-product-fail": {
      title: "vmProductFailTitle",
      status: "danger",
      redirect: "visualMachine",
      message: "vmProductFailMessage",
      payload: [],
    },
  }

  static API_RESPONSE_CODES = {
    "user-cannot-authenticate": {
      message: "cantAuthMessage",
      title: "cantAuthTitle",
      status: "danger",
      payload: [],
    },
  }

  static categoryLevelList = [
    { value: 1, text: "Primo" },
    { value: 2, text: "Secondo" },
    { value: 3, text: "Penultimo" },
    { value: 4, text: "Ultimo" },
  ]

  static ga4Options = [
    { value: 1, text: "gtag" },
    { value: 2, text: "dataLayer" },
  ]

  static COUNTRIES = [
    {
      it: "Afghanistan",
      en: "Afghanistan",
      value: "AF",
    },
    {
      it: "Albania",
      en: "Albania",
      value: "AL",
    },
    {
      it: "Algeria",
      en: "Algeria",
      value: "DZ",
    },
    {
      it: "Andorra",
      en: "Andorra",
      value: "AD",
    },
    {
      it: "Angola",
      en: "Angola",
      value: "AO",
    },
    {
      it: "Anguilla",
      en: "Anguilla",
      value: "AI",
    },
    {
      it: "Antigua e Barbuda",
      en: "Antigua and Barbuda",
      value: "AG",
    },
    {
      it: "Arabia Saudita",
      en: "Saudi Arabia",
      value: "SA",
    },
    {
      it: "Argentina",
      en: "Argentina",
      value: "AR",
    },
    {
      it: "Armenia",
      en: "Armenia",
      value: "AM",
    },
    {
      it: "Aruba",
      en: "Aruba",
      value: "AW",
    },
    {
      it: "Australia",
      en: "Australia",
      value: "AU",
    },
    {
      it: "Austria",
      en: "Austria",
      value: "AT",
    },
    {
      it: "Azerbaigian",
      en: "Azerbaijan",
      value: "AZ",
    },
    {
      it: "Bahamas",
      en: "Bahamas",
      value: "BS",
    },
    {
      it: "Bahrein",
      en: "Bahrain",
      value: "BH",
    },
    {
      it: "Bangladesh",
      en: "Bangladesh",
      value: "BD",
    },
    {
      it: "Barbados",
      en: "Barbados",
      value: "BB",
    },
    {
      it: "Belgio",
      en: "Belgium",
      value: "BE",
    },
    {
      it: "Belize",
      en: "Belize",
      value: "BZ",
    },
    {
      it: "Benin",
      en: "Benin",
      value: "BJ",
    },
    {
      it: "Bermuda",
      en: "Bermuda",
      value: "BM",
    },
    {
      it: "Bhutan",
      en: "Bhutan",
      value: "BT",
    },
    {
      it: "Bielorussia",
      en: "Belarus",
      value: "BY",
    },
    {
      it: "Bolivia",
      en: "Bolivia",
      value: "BO",
    },
    {
      it: "Bosnia-Erzegovina",
      en: "Bosnia and Herzegovina",
      value: "BA",
    },
    {
      it: "Botswana",
      en: "Botswana",
      value: "BW",
    },
    {
      it: "Brasile",
      en: "Brazil",
      value: "BR",
    },
    {
      it: "Brunei Darussalam",
      en: "Brunei Darussalam",
      value: "BN",
    },
    {
      it: "Bulgaria",
      en: "Bulgaria",
      value: "BG",
    },
    {
      it: "Burkina Faso",
      en: "Burkina Faso",
      value: "BF",
    },
    {
      it: "Burundi",
      en: "Burundi",
      value: "BI",
    },
    {
      it: "Cambogia",
      en: "Cambodia",
      value: "KH",
    },
    {
      it: "Camerun",
      en: "Cameroon",
      value: "CM",
    },
    {
      it: "Canada",
      en: "Canada",
      value: "CA",
    },
    {
      it: "Capo Verde",
      en: "Cape Verde",
      value: "CV",
    },
    {
      it: "Ciad",
      en: "Chad",
      value: "TD",
    },
    {
      it: "Cile",
      en: "Chile",
      value: "CL",
    },
    {
      it: "Cina",
      en: "China",
      value: "CN",
    },
    {
      it: "Cipro",
      en: "Cyprus",
      value: "CY",
    },
    {
      it: "Colombia",
      en: "Colombia",
      value: "CO",
    },
    {
      it: "Comore",
      en: "Comoros",
      value: "KM",
    },
    {
      it: "Congo",
      en: "Congo",
      value: "CG",
    },
    {
      it: "Corea del Nord",
      en: "North Korea",
      value: "KP",
    },
    {
      it: "Corea del Sud",
      en: "South Korea",
      value: "KR",
    },
    {
      it: "Costa d'Avorio",
      en: "Côte d'Ivoire",
      value: "CI",
    },
    {
      it: "Costa Rica",
      en: "Costa Rica",
      value: "CR",
    },
    {
      it: "Croazia",
      en: "Croatia",
      value: "HR",
    },
    {
      it: "Cuba",
      en: "Cuba",
      value: "CU",
    },
    {
      it: "Curaçao",
      en: "Curaçao",
      value: "CW",
    },
    {
      it: "Danimarca",
      en: "Denmark",
      value: "DK",
    },
    {
      it: "Dominica",
      en: "Dominica",
      value: "DM",
    },
    {
      it: "Ecuador",
      en: "Ecuador",
      value: "EC",
    },
    {
      it: "Egitto",
      en: "Egypt",
      value: "EG",
    },
    {
      it: "El Salvador",
      en: "El Salvador",
      value: "SV",
    },
    {
      it: "Emirati Arabi Uniti",
      en: "United Arab Emirates",
      value: "AE",
    },
    {
      it: "Eritrea",
      en: "Eritrea",
      value: "ER",
    },
    {
      it: "Estonia",
      en: "Estonia",
      value: "EE",
    },
    {
      it: "Eswatini",
      en: "Eswatini",
      value: "SZ",
    },
    {
      it: "Etiopia",
      en: "Ethiopia",
      value: "ET",
    },
    {
      it: "Federazione russa",
      en: "Russian Federation",
      value: "RU",
    },
    {
      it: "Figi",
      en: "Fiji",
      value: "FJ",
    },
    {
      it: "Filippine",
      en: "Philippines",
      value: "PH",
    },
    {
      it: "Finlandia",
      en: "Finland",
      value: "FI",
    },
    {
      it: "Francia",
      en: "France",
      value: "FR",
    },
    {
      it: "Gabon",
      en: "Gabon",
      value: "GA",
    },
    {
      it: "Gambia",
      en: "Gambia",
      value: "GM",
    },
    {
      it: "Georgia",
      en: "Georgia",
      value: "GE",
    },
    {
      it: "Germania",
      en: "Germany",
      value: "DE",
    },
    {
      it: "Ghana",
      en: "Ghana",
      value: "GH",
    },
    {
      it: "Giamaica",
      en: "Jamaica",
      value: "JM",
    },
    {
      it: "Giappone",
      en: "Japan",
      value: "JP",
    },
    {
      it: "Gibilterra",
      en: "Gibraltar",
      value: "GI",
    },
    {
      it: "Gibuti",
      en: "Djibouti",
      value: "DJ",
    },
    {
      it: "Giordania",
      en: "Jordan",
      value: "JO",
    },
    {
      it: "Grecia",
      en: "Greece",
      value: "GR",
    },
    {
      it: "Grenada",
      en: "Grenada",
      value: "GD",
    },
    {
      it: "Groenlandia",
      en: "Greenland",
      value: "GL",
    },
    {
      it: "Guatemala",
      en: "Guatemala",
      value: "GT",
    },
    {
      it: "Guernsey",
      en: "Guernsey",
      value: "GG",
    },
    {
      it: "Guinea",
      en: "Guinea",
      value: "GN",
    },
    {
      it: "Guinea equatoriale",
      en: "Equatorial Guinea",
      value: "GQ",
    },
    {
      it: "Guinea-Bissau",
      en: "Guinea-Bissau",
      value: "GW",
    },
    {
      it: "Guyana",
      en: "Guyana",
      value: "GY",
    },
    {
      it: "Haiti",
      en: "Haiti",
      value: "HT",
    },
    {
      it: "Honduras",
      en: "Honduras",
      value: "HN",
    },
    {
      it: "India",
      en: "India",
      value: "IN",
    },
    {
      it: "Indonesia",
      en: "Indonesia",
      value: "ID",
    },
    {
      it: "Iran",
      en: "Iran",
      value: "IR",
    },
    {
      it: "Iraq",
      en: "Iraq",
      value: "IQ",
    },
    {
      it: "Irlanda",
      en: "Ireland",
      value: "IE",
    },
    {
      it: "Islanda",
      en: "Iceland",
      value: "IS",
    },
    {
      it: "Isola di Man",
      en: "Isle of Man",
      value: "IM",
    },
    {
      it: "Isole Cayman",
      en: "Cayman Islands",
      value: "KY",
    },
    {
      it: "Isole Cook (NZ)",
      en: "Cook Islands (NZ)",
      value: "CK",
    },
    {
      it: "Isole Fær Øer",
      en: "Faroe Islands",
      value: "FO",
    },
    {
      it: "Isole Falkland (Malvine)",
      en: "Falkland Islands (Malvinas)",
      value: "FK",
    },
    {
      it: "Isole Marshall",
      en: "Marshall Islands",
      value: "MH",
    },
    {
      it: "Isole Pitcairn",
      en: "Pitcairn",
      value: "PN",
    },
    {
      it: "Isole Salomone",
      en: "Solomon Islands",
      value: "SB",
    },
    {
      it: "Isole Turks e Caicos",
      en: "Turks and Caicos Islands",
      value: "TC",
    },
    {
      it: "Isole Vergini britanniche",
      en: "British Virgin Islands",
      value: "VG",
    },
    {
      it: "Israele",
      en: "Israel",
      value: "IL",
    },
    {
      it: "Italia",
      en: "Italy",
      value: "IT",
    },
    {
      it: "Jersey",
      en: "Jersey",
      value: "JE",
    },
    {
      it: "Kazakhstan",
      en: "Kazakhstan",
      value: "KZ",
    },
    {
      it: "Kenya",
      en: "Kenya",
      value: "KE",
    },
    {
      it: "Kirghizistan",
      en: "Kyrgyzstan",
      value: "KG",
    },
    {
      it: "Kiribati",
      en: "Kiribati",
      value: "KI",
    },
    {
      it: "Kosovo",
      en: "Kosovo",
      value: "n.d.",
    },
    {
      it: "Kuwait",
      en: "Kuwait",
      value: "KW",
    },
    {
      it: "Laos",
      en: "Laos",
      value: "LA",
    },
    {
      it: "Lesotho",
      en: "Lesotho",
      value: "LS",
    },
    {
      it: "Lettonia",
      en: "Latvia",
      value: "LV",
    },
    {
      it: "Libano",
      en: "Lebanon",
      value: "LB",
    },
    {
      it: "Liberia",
      en: "Liberia",
      value: "LR",
    },
    {
      it: "Libia",
      en: "Libya",
      value: "LY",
    },
    {
      it: "Liechtenstein",
      en: "Liechtenstein",
      value: "LI",
    },
    {
      it: "Lituania",
      en: "Lithuania",
      value: "LT",
    },
    {
      it: "Lussemburgo",
      en: "Luxembourg",
      value: "LU",
    },
    {
      it: "Macedonia del Nord",
      en: "North Macedonia",
      value: "MK",
    },
    {
      it: "Madagascar",
      en: "Madagascar",
      value: "MG",
    },
    {
      it: "Malawi",
      en: "Malawi",
      value: "MW",
    },
    {
      it: "Malaysia",
      en: "Malaysia",
      value: "MY",
    },
    {
      it: "Maldive",
      en: "Maldives",
      value: "MV",
    },
    {
      it: "Mali",
      en: "Mali",
      value: "ML",
    },
    {
      it: "Malta",
      en: "Malta",
      value: "MT",
    },
    {
      it: "Marocco",
      en: "Morocco",
      value: "MA",
    },
    {
      it: "Mauritania",
      en: "Mauritania",
      value: "MR",
    },
    {
      it: "Maurizio",
      en: "Mauritius",
      value: "MU",
    },
    {
      it: "Messico",
      en: "Mexico",
      value: "MX",
    },
    {
      it: "Moldova",
      en: "Moldova",
      value: "MD",
    },
    {
      it: "Monaco",
      en: "Monaco",
      value: "MC",
    },
    {
      it: "Mongolia",
      en: "Mongolia",
      value: "MN",
    },
    {
      it: "Montenegro",
      en: "Montenegro",
      value: "ME",
    },
    {
      it: "Montserrat",
      en: "Montserrat",
      value: "MS",
    },
    {
      it: "Mozambico",
      en: "Mozambique",
      value: "MZ",
    },
    {
      it: "Myanmar/Birmania",
      en: "Myanmar/Burma",
      value: "MM",
    },
    {
      it: "Namibia",
      en: "Namibia",
      value: "NA",
    },
    {
      it: "Nauru",
      en: "Nauru",
      value: "NR",
    },
    {
      it: "Nepal",
      en: "Nepal",
      value: "NP",
    },
    {
      it: "Nicaragua",
      en: "Nicaragua",
      value: "NI",
    },
    {
      it: "Niger",
      en: "Niger",
      value: "NE",
    },
    {
      it: "Nigeria",
      en: "Nigeria",
      value: "NG",
    },
    {
      it: "Norvegia",
      en: "Norway",
      value: "NO",
    },
    {
      it: "Nuova Caledonia",
      en: "New Caledonia",
      value: "NC",
    },
    {
      it: "Nuova Zelanda",
      en: "New Zealand",
      value: "NZ",
    },
    {
      it: "Oman",
      en: "Oman",
      value: "OM",
    },
    {
      it: "Paesi Bassi",
      en: "Netherlands",
      value: "NL",
    },
    {
      it: "Pakistan",
      en: "Pakistan",
      value: "PK",
    },
    {
      it: "Palau",
      en: "Palau",
      value: "PW",
    },
    {
      it: "Palestina",
      en: "Palestine",
      value: "PS",
    },
    {
      it: "Panama",
      en: "Panama",
      value: "PA",
    },
    {
      it: "Papua Nuova Guinea",
      en: "Papua New Guinea",
      value: "PG",
    },
    {
      it: "Paraguay",
      en: "Paraguay",
      value: "PY",
    },
    {
      it: "Perù",
      en: "Peru",
      value: "PE",
    },
    {
      it: "Polinesia francese",
      en: "French Polynesia",
      value: "PF",
    },
    {
      it: "Polonia",
      en: "Poland",
      value: "PL",
    },
    {
      it: "Portogallo",
      en: "Portugal",
      value: "PT",
    },
    {
      it: "Qatar",
      en: "Qatar",
      value: "QA",
    },
    {
      it: "Regno Unito",
      en: "United Kingdom",
      value: "UK",
    },
    {
      it: "Repubblica ceca",
      en: "Czech Republic",
      value: "CZ",
    },
    {
      it: "Repubblica Centrafricana",
      en: "Central African Republic",
      value: "CF",
    },
    {
      it: "Repubblica Democratica del Congo",
      en: "Democratic Republic of the Congo",
      value: "CD",
    },
    {
      it: "Repubblica Dominicana",
      en: "Dominican Republic",
      value: "DO",
    },
    {
      it: "Romania",
      en: "Romania",
      value: "RO",
    },
    {
      it: "Ruanda",
      en: "Rwanda",
      value: "RW",
    },
    {
      it: "Sahara occidentale",
      en: "Western Sahara",
      value: "EH",
    },
    {
      it: "Saint Kitts e Nevis",
      en: "Saint Kitts and Nevis",
      value: "KN",
    },
    {
      it: "Saint Pierre e Miquelon",
      en: "Saint Pierre and Miquelon",
      value: "PM",
    },
    {
      it: "Saint Vincent e Grenadine",
      en: "Saint Vincent and the Grenadines",
      value: "VC",
    },
    {
      it: "Saint-Barthélemy",
      en: "Saint Barthelemy",
      value: "BL",
    },
    {
      it: "Saint-Martin (FR)",
      en: "Saint Martin (FR)",
      value: "MF",
    },
    {
      it: "Samoa",
      en: "Samoa",
      value: "WS",
    },
    {
      it: "San Marino",
      en: "San Marino",
      value: "SM",
    },
    {
      it: "Sant'Elena",
      en: "Saint Helena",
      value: "SH",
    },
    {
      it: "Santa Lucia",
      en: "Saint Lucia",
      value: "LC",
    },
    {
      it: "Sao Tomé e Principe",
      en: "Sao Tome and Principe",
      value: "ST",
    },
    {
      it: "Sark",
      en: "Sark",
      value: "n.d.",
    },
    {
      it: "Senegal",
      en: "Senegal",
      value: "SN",
    },
    {
      it: "Serbia",
      en: "Serbia",
      value: "RS",
    },
    {
      it: "Seychelles",
      en: "Seychelles",
      value: "SC",
    },
    {
      it: "Sierra Leone",
      en: "Sierra Leone",
      value: "SL",
    },
    {
      it: "Singapore",
      en: "Singapore",
      value: "SG",
    },
    {
      it: "Sint Maarten (NL)",
      en: "St Maarten (NL)",
      value: "SX",
    },
    {
      it: "Siria",
      en: "Syria",
      value: "SY",
    },
    {
      it: "Slovacchia",
      en: "Slovakia",
      value: "SK",
    },
    {
      it: "Slovenia",
      en: "Slovenia",
      value: "SI",
    },
    {
      it: "Somalia",
      en: "Somalia",
      value: "SO",
    },
    {
      it: "Spagna",
      en: "Spain",
      value: "ES",
    },
    {
      it: "Sri Lanka",
      en: "Sri Lanka",
      value: "LK",
    },
    {
      it: "Stati Federati di Micronesia",
      en: "Federated States of Micronesia",
      value: "FM",
    },
    {
      it: "Stati Uniti d'America",
      en: "United States of America",
      value: "US",
    },
    {
      it: "Stato della Città del Vaticano",
      en: "Vatican City State",
      value: "VA",
    },
    {
      it: "Sud Sudan",
      en: "South Sudan",
      value: "SS",
    },
    {
      it: "Sudafrica",
      en: "South Africa",
      value: "ZA",
    },
    {
      it: "Sudan",
      en: "Sudan",
      value: "SD",
    },
    {
      it: "Suriname",
      en: "Suriname",
      value: "SR",
    },
    {
      it: "Svezia",
      en: "Sweden",
      value: "SE",
    },
    {
      it: "Svizzera",
      en: "Switzerland",
      value: "CH",
    },
    {
      it: "Tagikistan",
      en: "Tajikistan",
      value: "TJ",
    },
    {
      it: "Taiwan",
      en: "Taiwan",
      value: "TW",
    },
    {
      it: "Tanzania",
      en: "Tanzania",
      value: "TZ",
    },
    {
      it: "Terre australi e antartiche francesi",
      en: "French Southern Territories",
      value: "TF",
    },
    {
      it: "Thailandia",
      en: "Thailand",
      value: "TH",
    },
    {
      it: "Timor Leste",
      en: "Timor Leste",
      value: "TL",
    },
    {
      it: "Togo",
      en: "Togo",
      value: "TG",
    },
    {
      it: "Tonga",
      en: "Tonga",
      value: "TO",
    },
    {
      it: "Trinidad e Tobago",
      en: "Trinidad and Tobago",
      value: "TT",
    },
    {
      it: "Tunisia",
      en: "Tunisia",
      value: "TN",
    },
    {
      it: "Turchia",
      en: "Turkey",
      value: "TR",
    },
    {
      it: "Turkmenistan",
      en: "Turkmenistan",
      value: "TM",
    },
    {
      it: "Tuvalu",
      en: "Tuvalu",
      value: "TV",
    },
    {
      it: "Ucraina",
      en: "Ukraine",
      value: "UA",
    },
    {
      it: "Uganda",
      en: "Uganda",
      value: "UG",
    },
    {
      it: "Ungheria",
      en: "Hungary",
      value: "HU",
    },
    {
      it: "Uruguay",
      en: "Uruguay",
      value: "UY",
    },
    {
      it: "Uzbekistan",
      en: "Uzbekistan",
      value: "UZ",
    },
    {
      it: "Vanuatu",
      en: "Vanuatu",
      value: "VU",
    },
    {
      it: "Venezuela",
      en: "Venezuela",
      value: "VE",
    },
    {
      it: "Vietnam",
      en: "Vietnam",
      value: "VN",
    },
    {
      it: "Wallis e Futuna",
      en: "Wallis and Futuna Islands",
      value: "WF",
    },
    {
      it: "Yemen",
      en: "Yemen",
      value: "YE",
    },
    {
      it: "Zambia",
      en: "Zambia",
      value: "ZM",
    },
    {
      it: "Zimbabwe",
      en: "Zimbabwe",
      value: "ZW",
    },
  ]

  static REGIONS = [
    {
      region_id: 1,
      country_id: "US",
      code: "AL",
      default_name: "Alabama",
    },
    {
      region_id: 2,
      country_id: "US",
      code: "AK",
      default_name: "Alaska",
    },
    {
      region_id: 3,
      country_id: "US",
      code: "AS",
      default_name: "American Samoa",
    },
    {
      region_id: 4,
      country_id: "US",
      code: "AZ",
      default_name: "Arizona",
    },
    {
      region_id: 5,
      country_id: "US",
      code: "AR",
      default_name: "Arkansas",
    },
    {
      region_id: 6,
      country_id: "US",
      code: "AE",
      default_name: "Armed Forces Africa",
    },
    {
      region_id: 7,
      country_id: "US",
      code: "AA",
      default_name: "Armed Forces Americas",
    },
    {
      region_id: 8,
      country_id: "US",
      code: "AE",
      default_name: "Armed Forces Canada",
    },
    {
      region_id: 9,
      country_id: "US",
      code: "AE",
      default_name: "Armed Forces Europe",
    },
    {
      region_id: 10,
      country_id: "US",
      code: "AE",
      default_name: "Armed Forces Middle East",
    },
    {
      region_id: 11,
      country_id: "US",
      code: "AP",
      default_name: "Armed Forces Pacific",
    },
    {
      region_id: 12,
      country_id: "US",
      code: "CA",
      default_name: "California",
    },
    {
      region_id: 13,
      country_id: "US",
      code: "CO",
      default_name: "Colorado",
    },
    {
      region_id: 14,
      country_id: "US",
      code: "CT",
      default_name: "Connecticut",
    },
    {
      region_id: 15,
      country_id: "US",
      code: "DE",
      default_name: "Delaware",
    },
    {
      region_id: 16,
      country_id: "US",
      code: "DC",
      default_name: "District of Columbia",
    },
    {
      region_id: 17,
      country_id: "US",
      code: "FM",
      default_name: "Federated States Of Micronesia",
    },
    {
      region_id: 18,
      country_id: "US",
      code: "FL",
      default_name: "Florida",
    },
    {
      region_id: 19,
      country_id: "US",
      code: "GA",
      default_name: "Georgia",
    },
    {
      region_id: 20,
      country_id: "US",
      code: "GU",
      default_name: "Guam",
    },
    {
      region_id: 21,
      country_id: "US",
      code: "HI",
      default_name: "Hawaii",
    },
    {
      region_id: 22,
      country_id: "US",
      code: "ID",
      default_name: "Idaho",
    },
    {
      region_id: 23,
      country_id: "US",
      code: "IL",
      default_name: "Illinois",
    },
    {
      region_id: 24,
      country_id: "US",
      code: "IN",
      default_name: "Indiana",
    },
    {
      region_id: 25,
      country_id: "US",
      code: "IA",
      default_name: "Iowa",
    },
    {
      region_id: 26,
      country_id: "US",
      code: "KS",
      default_name: "Kansas",
    },
    {
      region_id: 27,
      country_id: "US",
      code: "KY",
      default_name: "Kentucky",
    },
    {
      region_id: 28,
      country_id: "US",
      code: "LA",
      default_name: "Louisiana",
    },
    {
      region_id: 29,
      country_id: "US",
      code: "ME",
      default_name: "Maine",
    },
    {
      region_id: 30,
      country_id: "US",
      code: "MH",
      default_name: "Marshall Islands",
    },
    {
      region_id: 31,
      country_id: "US",
      code: "MD",
      default_name: "Maryland",
    },
    {
      region_id: 32,
      country_id: "US",
      code: "MA",
      default_name: "Massachusetts",
    },
    {
      region_id: 33,
      country_id: "US",
      code: "MI",
      default_name: "Michigan",
    },
    {
      region_id: 34,
      country_id: "US",
      code: "MN",
      default_name: "Minnesota",
    },
    {
      region_id: 35,
      country_id: "US",
      code: "MS",
      default_name: "Mississippi",
    },
    {
      region_id: 36,
      country_id: "US",
      code: "MO",
      default_name: "Missouri",
    },
    {
      region_id: 37,
      country_id: "US",
      code: "MT",
      default_name: "Montana",
    },
    {
      region_id: 38,
      country_id: "US",
      code: "NE",
      default_name: "Nebraska",
    },
    {
      region_id: 39,
      country_id: "US",
      code: "NV",
      default_name: "Nevada",
    },
    {
      region_id: 40,
      country_id: "US",
      code: "NH",
      default_name: "New Hampshire",
    },
    {
      region_id: 41,
      country_id: "US",
      code: "NJ",
      default_name: "New Jersey",
    },
    {
      region_id: 42,
      country_id: "US",
      code: "NM",
      default_name: "New Mexico",
    },
    {
      region_id: 43,
      country_id: "US",
      code: "NY",
      default_name: "New York",
    },
    {
      region_id: 44,
      country_id: "US",
      code: "NC",
      default_name: "North Carolina",
    },
    {
      region_id: 45,
      country_id: "US",
      code: "ND",
      default_name: "North Dakota",
    },
    {
      region_id: 46,
      country_id: "US",
      code: "MP",
      default_name: "Northern Mariana Islands",
    },
    {
      region_id: 47,
      country_id: "US",
      code: "OH",
      default_name: "Ohio",
    },
    {
      region_id: 48,
      country_id: "US",
      code: "OK",
      default_name: "Oklahoma",
    },
    {
      region_id: 49,
      country_id: "US",
      code: "OR",
      default_name: "Oregon",
    },
    {
      region_id: 50,
      country_id: "US",
      code: "PW",
      default_name: "Palau",
    },
    {
      region_id: 51,
      country_id: "US",
      code: "PA",
      default_name: "Pennsylvania",
    },
    {
      region_id: 52,
      country_id: "US",
      code: "PR",
      default_name: "Puerto Rico",
    },
    {
      region_id: 53,
      country_id: "US",
      code: "RI",
      default_name: "Rhode Island",
    },
    {
      region_id: 54,
      country_id: "US",
      code: "SC",
      default_name: "South Carolina",
    },
    {
      region_id: 55,
      country_id: "US",
      code: "SD",
      default_name: "South Dakota",
    },
    {
      region_id: 56,
      country_id: "US",
      code: "TN",
      default_name: "Tennessee",
    },
    {
      region_id: 57,
      country_id: "US",
      code: "TX",
      default_name: "Texas",
    },
    {
      region_id: 58,
      country_id: "US",
      code: "UT",
      default_name: "Utah",
    },
    {
      region_id: 59,
      country_id: "US",
      code: "VT",
      default_name: "Vermont",
    },
    {
      region_id: 60,
      country_id: "US",
      code: "VI",
      default_name: "Virgin Islands",
    },
    {
      region_id: 61,
      country_id: "US",
      code: "VA",
      default_name: "Virginia",
    },
    {
      region_id: 62,
      country_id: "US",
      code: "WA",
      default_name: "Washington",
    },
    {
      region_id: 63,
      country_id: "US",
      code: "WV",
      default_name: "West Virginia",
    },
    {
      region_id: 64,
      country_id: "US",
      code: "WI",
      default_name: "Wisconsin",
    },
    {
      region_id: 65,
      country_id: "US",
      code: "WY",
      default_name: "Wyoming",
    },
    {
      region_id: 66,
      country_id: "CA",
      code: "AB",
      default_name: "Alberta",
    },
    {
      region_id: 67,
      country_id: "CA",
      code: "BC",
      default_name: "British Columbia",
    },
    {
      region_id: 68,
      country_id: "CA",
      code: "MB",
      default_name: "Manitoba",
    },
    {
      region_id: 69,
      country_id: "CA",
      code: "NL",
      default_name: "Newfoundland and Labrador",
    },
    {
      region_id: 70,
      country_id: "CA",
      code: "NB",
      default_name: "New Brunswick",
    },
    {
      region_id: 71,
      country_id: "CA",
      code: "NS",
      default_name: "Nova Scotia",
    },
    {
      region_id: 72,
      country_id: "CA",
      code: "NT",
      default_name: "Northwest Territories",
    },
    {
      region_id: 73,
      country_id: "CA",
      code: "NU",
      default_name: "Nunavut",
    },
    {
      region_id: 74,
      country_id: "CA",
      code: "ON",
      default_name: "Ontario",
    },
    {
      region_id: 75,
      country_id: "CA",
      code: "PE",
      default_name: "Prince Edward Island",
    },
    {
      region_id: 76,
      country_id: "CA",
      code: "QC",
      default_name: "Quebec",
    },
    {
      region_id: 77,
      country_id: "CA",
      code: "SK",
      default_name: "Saskatchewan",
    },
    {
      region_id: 78,
      country_id: "CA",
      code: "YT",
      default_name: "Yukon Territory",
    },
    {
      region_id: 79,
      country_id: "DE",
      code: "NDS",
      default_name: "Niedersachsen",
    },
    {
      region_id: 80,
      country_id: "DE",
      code: "BAW",
      default_name: "Baden-Württemberg",
    },
    {
      region_id: 81,
      country_id: "DE",
      code: "BAY",
      default_name: "Bayern",
    },
    {
      region_id: 82,
      country_id: "DE",
      code: "BER",
      default_name: "Berlin",
    },
    {
      region_id: 83,
      country_id: "DE",
      code: "BRG",
      default_name: "Brandenburg",
    },
    {
      region_id: 84,
      country_id: "DE",
      code: "BRE",
      default_name: "Bremen",
    },
    {
      region_id: 85,
      country_id: "DE",
      code: "HAM",
      default_name: "Hamburg",
    },
    {
      region_id: 86,
      country_id: "DE",
      code: "HES",
      default_name: "Hessen",
    },
    {
      region_id: 87,
      country_id: "DE",
      code: "MEC",
      default_name: "Mecklenburg-Vorpommern",
    },
    {
      region_id: 88,
      country_id: "DE",
      code: "NRW",
      default_name: "Nordrhein-Westfalen",
    },
    {
      region_id: 89,
      country_id: "DE",
      code: "RHE",
      default_name: "Rheinland-Pfalz",
    },
    {
      region_id: 90,
      country_id: "DE",
      code: "SAR",
      default_name: "Saarland",
    },
    {
      region_id: 91,
      country_id: "DE",
      code: "SAS",
      default_name: "Sachsen",
    },
    {
      region_id: 92,
      country_id: "DE",
      code: "SAC",
      default_name: "Sachsen-Anhalt",
    },
    {
      region_id: 93,
      country_id: "DE",
      code: "SCN",
      default_name: "Schleswig-Holstein",
    },
    {
      region_id: 94,
      country_id: "DE",
      code: "THE",
      default_name: "Thüringen",
    },
    {
      region_id: 95,
      country_id: "AT",
      code: "WI",
      default_name: "Wien",
    },
    {
      region_id: 96,
      country_id: "AT",
      code: "NO",
      default_name: "Niederösterreich",
    },
    {
      region_id: 97,
      country_id: "AT",
      code: "OO",
      default_name: "Oberösterreich",
    },
    {
      region_id: 98,
      country_id: "AT",
      code: "SB",
      default_name: "Salzburg",
    },
    {
      region_id: 99,
      country_id: "AT",
      code: "KN",
      default_name: "Kärnten",
    },
    {
      region_id: 100,
      country_id: "AT",
      code: "ST",
      default_name: "Steiermark",
    },
    {
      region_id: 101,
      country_id: "AT",
      code: "TI",
      default_name: "Tirol",
    },
    {
      region_id: 102,
      country_id: "AT",
      code: "BL",
      default_name: "Burgenland",
    },
    {
      region_id: 103,
      country_id: "AT",
      code: "VB",
      default_name: "Vorarlberg",
    },
    {
      region_id: 104,
      country_id: "CH",
      code: "AG",
      default_name: "Aargau",
    },
    {
      region_id: 105,
      country_id: "CH",
      code: "AI",
      default_name: "Appenzell Innerrhoden",
    },
    {
      region_id: 106,
      country_id: "CH",
      code: "AR",
      default_name: "Appenzell Ausserrhoden",
    },
    {
      region_id: 107,
      country_id: "CH",
      code: "BE",
      default_name: "Bern",
    },
    {
      region_id: 108,
      country_id: "CH",
      code: "BL",
      default_name: "Basel-Landschaft",
    },
    {
      region_id: 109,
      country_id: "CH",
      code: "BS",
      default_name: "Basel-Stadt",
    },
    {
      region_id: 110,
      country_id: "CH",
      code: "FR",
      default_name: "Freiburg",
    },
    {
      region_id: 111,
      country_id: "CH",
      code: "GE",
      default_name: "Genf",
    },
    {
      region_id: 112,
      country_id: "CH",
      code: "GL",
      default_name: "Glarus",
    },
    {
      region_id: 113,
      country_id: "CH",
      code: "GR",
      default_name: "Graubünden",
    },
    {
      region_id: 114,
      country_id: "CH",
      code: "JU",
      default_name: "Jura",
    },
    {
      region_id: 115,
      country_id: "CH",
      code: "LU",
      default_name: "Luzern",
    },
    {
      region_id: 116,
      country_id: "CH",
      code: "NE",
      default_name: "Neuenburg",
    },
    {
      region_id: 117,
      country_id: "CH",
      code: "NW",
      default_name: "Nidwalden",
    },
    {
      region_id: 118,
      country_id: "CH",
      code: "OW",
      default_name: "Obwalden",
    },
    {
      region_id: 119,
      country_id: "CH",
      code: "SG",
      default_name: "St. Gallen",
    },
    {
      region_id: 120,
      country_id: "CH",
      code: "SH",
      default_name: "Schaffhausen",
    },
    {
      region_id: 121,
      country_id: "CH",
      code: "SO",
      default_name: "Solothurn",
    },
    {
      region_id: 122,
      country_id: "CH",
      code: "SZ",
      default_name: "Schwyz",
    },
    {
      region_id: 123,
      country_id: "CH",
      code: "TG",
      default_name: "Thurgau",
    },
    {
      region_id: 124,
      country_id: "CH",
      code: "TI",
      default_name: "Tessin",
    },
    {
      region_id: 125,
      country_id: "CH",
      code: "UR",
      default_name: "Uri",
    },
    {
      region_id: 126,
      country_id: "CH",
      code: "VD",
      default_name: "Waadt",
    },
    {
      region_id: 127,
      country_id: "CH",
      code: "VS",
      default_name: "Wallis",
    },
    {
      region_id: 128,
      country_id: "CH",
      code: "ZG",
      default_name: "Zug",
    },
    {
      region_id: 129,
      country_id: "CH",
      code: "ZH",
      default_name: "Zürich",
    },
    {
      region_id: 130,
      country_id: "ES",
      code: "A Coruсa",
      default_name: "A Coruña",
    },
    {
      region_id: 131,
      country_id: "ES",
      code: "Alava",
      default_name: "Alava",
    },
    {
      region_id: 132,
      country_id: "ES",
      code: "Albacete",
      default_name: "Albacete",
    },
    {
      region_id: 133,
      country_id: "ES",
      code: "Alicante",
      default_name: "Alicante",
    },
    {
      region_id: 134,
      country_id: "ES",
      code: "Almeria",
      default_name: "Almeria",
    },
    {
      region_id: 135,
      country_id: "ES",
      code: "Asturias",
      default_name: "Asturias",
    },
    {
      region_id: 136,
      country_id: "ES",
      code: "Avila",
      default_name: "Avila",
    },
    {
      region_id: 137,
      country_id: "ES",
      code: "Badajoz",
      default_name: "Badajoz",
    },
    {
      region_id: 138,
      country_id: "ES",
      code: "Baleares",
      default_name: "Baleares",
    },
    {
      region_id: 139,
      country_id: "ES",
      code: "Barcelona",
      default_name: "Barcelona",
    },
    {
      region_id: 140,
      country_id: "ES",
      code: "Burgos",
      default_name: "Burgos",
    },
    {
      region_id: 141,
      country_id: "ES",
      code: "Caceres",
      default_name: "Caceres",
    },
    {
      region_id: 142,
      country_id: "ES",
      code: "Cadiz",
      default_name: "Cadiz",
    },
    {
      region_id: 143,
      country_id: "ES",
      code: "Cantabria",
      default_name: "Cantabria",
    },
    {
      region_id: 144,
      country_id: "ES",
      code: "Castellon",
      default_name: "Castellon",
    },
    {
      region_id: 145,
      country_id: "ES",
      code: "Ceuta",
      default_name: "Ceuta",
    },
    {
      region_id: 146,
      country_id: "ES",
      code: "Ciudad Real",
      default_name: "Ciudad Real",
    },
    {
      region_id: 147,
      country_id: "ES",
      code: "Cordoba",
      default_name: "Cordoba",
    },
    {
      region_id: 148,
      country_id: "ES",
      code: "Cuenca",
      default_name: "Cuenca",
    },
    {
      region_id: 149,
      country_id: "ES",
      code: "Girona",
      default_name: "Girona",
    },
    {
      region_id: 150,
      country_id: "ES",
      code: "Granada",
      default_name: "Granada",
    },
    {
      region_id: 151,
      country_id: "ES",
      code: "Guadalajara",
      default_name: "Guadalajara",
    },
    {
      region_id: 152,
      country_id: "ES",
      code: "Guipuzcoa",
      default_name: "Guipuzcoa",
    },
    {
      region_id: 153,
      country_id: "ES",
      code: "Huelva",
      default_name: "Huelva",
    },
    {
      region_id: 154,
      country_id: "ES",
      code: "Huesca",
      default_name: "Huesca",
    },
    {
      region_id: 155,
      country_id: "ES",
      code: "Jaen",
      default_name: "Jaen",
    },
    {
      region_id: 156,
      country_id: "ES",
      code: "La Rioja",
      default_name: "La Rioja",
    },
    {
      region_id: 157,
      country_id: "ES",
      code: "Las Palmas",
      default_name: "Las Palmas",
    },
    {
      region_id: 158,
      country_id: "ES",
      code: "Leon",
      default_name: "Leon",
    },
    {
      region_id: 159,
      country_id: "ES",
      code: "Lleida",
      default_name: "Lleida",
    },
    {
      region_id: 160,
      country_id: "ES",
      code: "Lugo",
      default_name: "Lugo",
    },
    {
      region_id: 161,
      country_id: "ES",
      code: "Madrid",
      default_name: "Madrid",
    },
    {
      region_id: 162,
      country_id: "ES",
      code: "Malaga",
      default_name: "Malaga",
    },
    {
      region_id: 163,
      country_id: "ES",
      code: "Melilla",
      default_name: "Melilla",
    },
    {
      region_id: 164,
      country_id: "ES",
      code: "Murcia",
      default_name: "Murcia",
    },
    {
      region_id: 165,
      country_id: "ES",
      code: "Navarra",
      default_name: "Navarra",
    },
    {
      region_id: 166,
      country_id: "ES",
      code: "Ourense",
      default_name: "Ourense",
    },
    {
      region_id: 167,
      country_id: "ES",
      code: "Palencia",
      default_name: "Palencia",
    },
    {
      region_id: 168,
      country_id: "ES",
      code: "Pontevedra",
      default_name: "Pontevedra",
    },
    {
      region_id: 169,
      country_id: "ES",
      code: "Salamanca",
      default_name: "Salamanca",
    },
    {
      region_id: 170,
      country_id: "ES",
      code: "Santa Cruz de Tenerife",
      default_name: "Santa Cruz de Tenerife",
    },
    {
      region_id: 171,
      country_id: "ES",
      code: "Segovia",
      default_name: "Segovia",
    },
    {
      region_id: 172,
      country_id: "ES",
      code: "Sevilla",
      default_name: "Sevilla",
    },
    {
      region_id: 173,
      country_id: "ES",
      code: "Soria",
      default_name: "Soria",
    },
    {
      region_id: 174,
      country_id: "ES",
      code: "Tarragona",
      default_name: "Tarragona",
    },
    {
      region_id: 175,
      country_id: "ES",
      code: "Teruel",
      default_name: "Teruel",
    },
    {
      region_id: 176,
      country_id: "ES",
      code: "Toledo",
      default_name: "Toledo",
    },
    {
      region_id: 177,
      country_id: "ES",
      code: "Valencia",
      default_name: "Valencia",
    },
    {
      region_id: 178,
      country_id: "ES",
      code: "Valladolid",
      default_name: "Valladolid",
    },
    {
      region_id: 179,
      country_id: "ES",
      code: "Vizcaya",
      default_name: "Vizcaya",
    },
    {
      region_id: 180,
      country_id: "ES",
      code: "Zamora",
      default_name: "Zamora",
    },
    {
      region_id: 181,
      country_id: "ES",
      code: "Zaragoza",
      default_name: "Zaragoza",
    },
    {
      region_id: 182,
      country_id: "FR",
      code: "1",
      default_name: "Ain",
    },
    {
      region_id: 183,
      country_id: "FR",
      code: "2",
      default_name: "Aisne",
    },
    {
      region_id: 184,
      country_id: "FR",
      code: "3",
      default_name: "Allier",
    },
    {
      region_id: 185,
      country_id: "FR",
      code: "4",
      default_name: "Alpes-de-Haute-Provence",
    },
    {
      region_id: 186,
      country_id: "FR",
      code: "5",
      default_name: "Hautes-Alpes",
    },
    {
      region_id: 187,
      country_id: "FR",
      code: "6",
      default_name: "Alpes-Maritimes",
    },
    {
      region_id: 188,
      country_id: "FR",
      code: "7",
      default_name: "Ardèche",
    },
    {
      region_id: 189,
      country_id: "FR",
      code: "8",
      default_name: "Ardennes",
    },
    {
      region_id: 190,
      country_id: "FR",
      code: "9",
      default_name: "Ariège",
    },
    {
      region_id: 191,
      country_id: "FR",
      code: "10",
      default_name: "Aube",
    },
    {
      region_id: 192,
      country_id: "FR",
      code: "11",
      default_name: "Aude",
    },
    {
      region_id: 193,
      country_id: "FR",
      code: "12",
      default_name: "Aveyron",
    },
    {
      region_id: 194,
      country_id: "FR",
      code: "13",
      default_name: "Bouches-du-Rhône",
    },
    {
      region_id: 195,
      country_id: "FR",
      code: "14",
      default_name: "Calvados",
    },
    {
      region_id: 196,
      country_id: "FR",
      code: "15",
      default_name: "Cantal",
    },
    {
      region_id: 197,
      country_id: "FR",
      code: "16",
      default_name: "Charente",
    },
    {
      region_id: 198,
      country_id: "FR",
      code: "17",
      default_name: "Charente-Maritime",
    },
    {
      region_id: 199,
      country_id: "FR",
      code: "18",
      default_name: "Cher",
    },
    {
      region_id: 200,
      country_id: "FR",
      code: "19",
      default_name: "Corrèze",
    },
    {
      region_id: 201,
      country_id: "FR",
      code: "2A",
      default_name: "Corse-du-Sud",
    },
    {
      region_id: 202,
      country_id: "FR",
      code: "2B",
      default_name: "Haute-Corse",
    },
    {
      region_id: 203,
      country_id: "FR",
      code: "21",
      default_name: "Côte-d'Or",
    },
    {
      region_id: 204,
      country_id: "FR",
      code: "22",
      default_name: "Côtes-d'Armor",
    },
    {
      region_id: 205,
      country_id: "FR",
      code: "23",
      default_name: "Creuse",
    },
    {
      region_id: 206,
      country_id: "FR",
      code: "24",
      default_name: "Dordogne",
    },
    {
      region_id: 207,
      country_id: "FR",
      code: "25",
      default_name: "Doubs",
    },
    {
      region_id: 208,
      country_id: "FR",
      code: "26",
      default_name: "Drôme",
    },
    {
      region_id: 209,
      country_id: "FR",
      code: "27",
      default_name: "Eure",
    },
    {
      region_id: 210,
      country_id: "FR",
      code: "28",
      default_name: "Eure-et-Loir",
    },
    {
      region_id: 211,
      country_id: "FR",
      code: "29",
      default_name: "Finistère",
    },
    {
      region_id: 212,
      country_id: "FR",
      code: "30",
      default_name: "Gard",
    },
    {
      region_id: 213,
      country_id: "FR",
      code: "31",
      default_name: "Haute-Garonne",
    },
    {
      region_id: 214,
      country_id: "FR",
      code: "32",
      default_name: "Gers",
    },
    {
      region_id: 215,
      country_id: "FR",
      code: "33",
      default_name: "Gironde",
    },
    {
      region_id: 216,
      country_id: "FR",
      code: "34",
      default_name: "Hérault",
    },
    {
      region_id: 217,
      country_id: "FR",
      code: "35",
      default_name: "Ille-et-Vilaine",
    },
    {
      region_id: 218,
      country_id: "FR",
      code: "36",
      default_name: "Indre",
    },
    {
      region_id: 219,
      country_id: "FR",
      code: "37",
      default_name: "Indre-et-Loire",
    },
    {
      region_id: 220,
      country_id: "FR",
      code: "38",
      default_name: "Isère",
    },
    {
      region_id: 221,
      country_id: "FR",
      code: "39",
      default_name: "Jura",
    },
    {
      region_id: 222,
      country_id: "FR",
      code: "40",
      default_name: "Landes",
    },
    {
      region_id: 223,
      country_id: "FR",
      code: "41",
      default_name: "Loir-et-Cher",
    },
    {
      region_id: 224,
      country_id: "FR",
      code: "42",
      default_name: "Loire",
    },
    {
      region_id: 225,
      country_id: "FR",
      code: "43",
      default_name: "Haute-Loire",
    },
    {
      region_id: 226,
      country_id: "FR",
      code: "44",
      default_name: "Loire-Atlantique",
    },
    {
      region_id: 227,
      country_id: "FR",
      code: "45",
      default_name: "Loiret",
    },
    {
      region_id: 228,
      country_id: "FR",
      code: "46",
      default_name: "Lot",
    },
    {
      region_id: 229,
      country_id: "FR",
      code: "47",
      default_name: "Lot-et-Garonne",
    },
    {
      region_id: 230,
      country_id: "FR",
      code: "48",
      default_name: "Lozère",
    },
    {
      region_id: 231,
      country_id: "FR",
      code: "49",
      default_name: "Maine-et-Loire",
    },
    {
      region_id: 232,
      country_id: "FR",
      code: "50",
      default_name: "Manche",
    },
    {
      region_id: 233,
      country_id: "FR",
      code: "51",
      default_name: "Marne",
    },
    {
      region_id: 234,
      country_id: "FR",
      code: "52",
      default_name: "Haute-Marne",
    },
    {
      region_id: 235,
      country_id: "FR",
      code: "53",
      default_name: "Mayenne",
    },
    {
      region_id: 236,
      country_id: "FR",
      code: "54",
      default_name: "Meurthe-et-Moselle",
    },
    {
      region_id: 237,
      country_id: "FR",
      code: "55",
      default_name: "Meuse",
    },
    {
      region_id: 238,
      country_id: "FR",
      code: "56",
      default_name: "Morbihan",
    },
    {
      region_id: 239,
      country_id: "FR",
      code: "57",
      default_name: "Moselle",
    },
    {
      region_id: 240,
      country_id: "FR",
      code: "58",
      default_name: "Nièvre",
    },
    {
      region_id: 241,
      country_id: "FR",
      code: "59",
      default_name: "Nord",
    },
    {
      region_id: 242,
      country_id: "FR",
      code: "60",
      default_name: "Oise",
    },
    {
      region_id: 243,
      country_id: "FR",
      code: "61",
      default_name: "Orne",
    },
    {
      region_id: 244,
      country_id: "FR",
      code: "62",
      default_name: "Pas-de-Calais",
    },
    {
      region_id: 245,
      country_id: "FR",
      code: "63",
      default_name: "Puy-de-Dôme",
    },
    {
      region_id: 246,
      country_id: "FR",
      code: "64",
      default_name: "Pyrénées-Atlantiques",
    },
    {
      region_id: 247,
      country_id: "FR",
      code: "65",
      default_name: "Hautes-Pyrénées",
    },
    {
      region_id: 248,
      country_id: "FR",
      code: "66",
      default_name: "Pyrénées-Orientales",
    },
    {
      region_id: 249,
      country_id: "FR",
      code: "67",
      default_name: "Bas-Rhin",
    },
    {
      region_id: 250,
      country_id: "FR",
      code: "68",
      default_name: "Haut-Rhin",
    },
    {
      region_id: 251,
      country_id: "FR",
      code: "69",
      default_name: "Rhône",
    },
    {
      region_id: 252,
      country_id: "FR",
      code: "70",
      default_name: "Haute-Saône",
    },
    {
      region_id: 253,
      country_id: "FR",
      code: "71",
      default_name: "Saône-et-Loire",
    },
    {
      region_id: 254,
      country_id: "FR",
      code: "72",
      default_name: "Sarthe",
    },
    {
      region_id: 255,
      country_id: "FR",
      code: "73",
      default_name: "Savoie",
    },
    {
      region_id: 256,
      country_id: "FR",
      code: "74",
      default_name: "Haute-Savoie",
    },
    {
      region_id: 257,
      country_id: "FR",
      code: "75",
      default_name: "Paris",
    },
    {
      region_id: 258,
      country_id: "FR",
      code: "76",
      default_name: "Seine-Maritime",
    },
    {
      region_id: 259,
      country_id: "FR",
      code: "77",
      default_name: "Seine-et-Marne",
    },
    {
      region_id: 260,
      country_id: "FR",
      code: "78",
      default_name: "Yvelines",
    },
    {
      region_id: 261,
      country_id: "FR",
      code: "79",
      default_name: "Deux-Sèvres",
    },
    {
      region_id: 262,
      country_id: "FR",
      code: "80",
      default_name: "Somme",
    },
    {
      region_id: 263,
      country_id: "FR",
      code: "81",
      default_name: "Tarn",
    },
    {
      region_id: 264,
      country_id: "FR",
      code: "82",
      default_name: "Tarn-et-Garonne",
    },
    {
      region_id: 265,
      country_id: "FR",
      code: "83",
      default_name: "Var",
    },
    {
      region_id: 266,
      country_id: "FR",
      code: "84",
      default_name: "Vaucluse",
    },
    {
      region_id: 267,
      country_id: "FR",
      code: "85",
      default_name: "Vendée",
    },
    {
      region_id: 268,
      country_id: "FR",
      code: "86",
      default_name: "Vienne",
    },
    {
      region_id: 269,
      country_id: "FR",
      code: "87",
      default_name: "Haute-Vienne",
    },
    {
      region_id: 270,
      country_id: "FR",
      code: "88",
      default_name: "Vosges",
    },
    {
      region_id: 271,
      country_id: "FR",
      code: "89",
      default_name: "Yonne",
    },
    {
      region_id: 272,
      country_id: "FR",
      code: "90",
      default_name: "Territoire-de-Belfort",
    },
    {
      region_id: 273,
      country_id: "FR",
      code: "91",
      default_name: "Essonne",
    },
    {
      region_id: 274,
      country_id: "FR",
      code: "92",
      default_name: "Hauts-de-Seine",
    },
    {
      region_id: 275,
      country_id: "FR",
      code: "93",
      default_name: "Seine-Saint-Denis",
    },
    {
      region_id: 276,
      country_id: "FR",
      code: "94",
      default_name: "Val-de-Marne",
    },
    {
      region_id: 277,
      country_id: "FR",
      code: "95",
      default_name: "Val-d'Oise",
    },
    {
      region_id: 278,
      country_id: "RO",
      code: "AB",
      default_name: "Alba",
    },
    {
      region_id: 279,
      country_id: "RO",
      code: "AR",
      default_name: "Arad",
    },
    {
      region_id: 280,
      country_id: "RO",
      code: "AG",
      default_name: "Argeş",
    },
    {
      region_id: 281,
      country_id: "RO",
      code: "BC",
      default_name: "Bacău",
    },
    {
      region_id: 282,
      country_id: "RO",
      code: "BH",
      default_name: "Bihor",
    },
    {
      region_id: 283,
      country_id: "RO",
      code: "BN",
      default_name: "Bistriţa-Năsăud",
    },
    {
      region_id: 284,
      country_id: "RO",
      code: "BT",
      default_name: "Botoşani",
    },
    {
      region_id: 285,
      country_id: "RO",
      code: "BV",
      default_name: "Braşov",
    },
    {
      region_id: 286,
      country_id: "RO",
      code: "BR",
      default_name: "Brăila",
    },
    {
      region_id: 287,
      country_id: "RO",
      code: "B",
      default_name: "Bucureşti",
    },
    {
      region_id: 288,
      country_id: "RO",
      code: "BZ",
      default_name: "Buzău",
    },
    {
      region_id: 289,
      country_id: "RO",
      code: "CS",
      default_name: "Caraş-Severin",
    },
    {
      region_id: 290,
      country_id: "RO",
      code: "CL",
      default_name: "Călăraşi",
    },
    {
      region_id: 291,
      country_id: "RO",
      code: "CJ",
      default_name: "Cluj",
    },
    {
      region_id: 292,
      country_id: "RO",
      code: "CT",
      default_name: "Constanţa",
    },
    {
      region_id: 293,
      country_id: "RO",
      code: "CV",
      default_name: "Covasna",
    },
    {
      region_id: 294,
      country_id: "RO",
      code: "DB",
      default_name: "Dâmboviţa",
    },
    {
      region_id: 295,
      country_id: "RO",
      code: "DJ",
      default_name: "Dolj",
    },
    {
      region_id: 296,
      country_id: "RO",
      code: "GL",
      default_name: "Galaţi",
    },
    {
      region_id: 297,
      country_id: "RO",
      code: "GR",
      default_name: "Giurgiu",
    },
    {
      region_id: 298,
      country_id: "RO",
      code: "GJ",
      default_name: "Gorj",
    },
    {
      region_id: 299,
      country_id: "RO",
      code: "HR",
      default_name: "Harghita",
    },
    {
      region_id: 300,
      country_id: "RO",
      code: "HD",
      default_name: "Hunedoara",
    },
    {
      region_id: 301,
      country_id: "RO",
      code: "IL",
      default_name: "Ialomiţa",
    },
    {
      region_id: 302,
      country_id: "RO",
      code: "IS",
      default_name: "Iaşi",
    },
    {
      region_id: 303,
      country_id: "RO",
      code: "IF",
      default_name: "Ilfov",
    },
    {
      region_id: 304,
      country_id: "RO",
      code: "MM",
      default_name: "Maramureş",
    },
    {
      region_id: 305,
      country_id: "RO",
      code: "MH",
      default_name: "Mehedinţi",
    },
    {
      region_id: 306,
      country_id: "RO",
      code: "MS",
      default_name: "Mureş",
    },
    {
      region_id: 307,
      country_id: "RO",
      code: "NT",
      default_name: "Neamţ",
    },
    {
      region_id: 308,
      country_id: "RO",
      code: "OT",
      default_name: "Olt",
    },
    {
      region_id: 309,
      country_id: "RO",
      code: "PH",
      default_name: "Prahova",
    },
    {
      region_id: 310,
      country_id: "RO",
      code: "SM",
      default_name: "Satu-Mare",
    },
    {
      region_id: 311,
      country_id: "RO",
      code: "SJ",
      default_name: "Sălaj",
    },
    {
      region_id: 312,
      country_id: "RO",
      code: "SB",
      default_name: "Sibiu",
    },
    {
      region_id: 313,
      country_id: "RO",
      code: "SV",
      default_name: "Suceava",
    },
    {
      region_id: 314,
      country_id: "RO",
      code: "TR",
      default_name: "Teleorman",
    },
    {
      region_id: 315,
      country_id: "RO",
      code: "TM",
      default_name: "Timiş",
    },
    {
      region_id: 316,
      country_id: "RO",
      code: "TL",
      default_name: "Tulcea",
    },
    {
      region_id: 317,
      country_id: "RO",
      code: "VS",
      default_name: "Vaslui",
    },
    {
      region_id: 318,
      country_id: "RO",
      code: "VL",
      default_name: "Vâlcea",
    },
    {
      region_id: 319,
      country_id: "RO",
      code: "VN",
      default_name: "Vrancea",
    },
    {
      region_id: 320,
      country_id: "FI",
      code: "Lappi",
      default_name: "Lappi",
    },
    {
      region_id: 321,
      country_id: "FI",
      code: "Pohjois-Pohjanmaa",
      default_name: "Pohjois-Pohjanmaa",
    },
    {
      region_id: 322,
      country_id: "FI",
      code: "Kainuu",
      default_name: "Kainuu",
    },
    {
      region_id: 323,
      country_id: "FI",
      code: "Pohjois-Karjala",
      default_name: "Pohjois-Karjala",
    },
    {
      region_id: 324,
      country_id: "FI",
      code: "Pohjois-Savo",
      default_name: "Pohjois-Savo",
    },
    {
      region_id: 325,
      country_id: "FI",
      code: "Etelä-Savo",
      default_name: "Etelä-Savo",
    },
    {
      region_id: 326,
      country_id: "FI",
      code: "Etelä-Pohjanmaa",
      default_name: "Etelä-Pohjanmaa",
    },
    {
      region_id: 327,
      country_id: "FI",
      code: "Pohjanmaa",
      default_name: "Pohjanmaa",
    },
    {
      region_id: 328,
      country_id: "FI",
      code: "Pirkanmaa",
      default_name: "Pirkanmaa",
    },
    {
      region_id: 329,
      country_id: "FI",
      code: "Satakunta",
      default_name: "Satakunta",
    },
    {
      region_id: 330,
      country_id: "FI",
      code: "Keski-Pohjanmaa",
      default_name: "Keski-Pohjanmaa",
    },
    {
      region_id: 331,
      country_id: "FI",
      code: "Keski-Suomi",
      default_name: "Keski-Suomi",
    },
    {
      region_id: 332,
      country_id: "FI",
      code: "Varsinais-Suomi",
      default_name: "Varsinais-Suomi",
    },
    {
      region_id: 333,
      country_id: "FI",
      code: "Etelä-Karjala",
      default_name: "Etelä-Karjala",
    },
    {
      region_id: 334,
      country_id: "FI",
      code: "Päijät-Häme",
      default_name: "Päijät-Häme",
    },
    {
      region_id: 335,
      country_id: "FI",
      code: "Kanta-Häme",
      default_name: "Kanta-Häme",
    },
    {
      region_id: 336,
      country_id: "FI",
      code: "Uusimaa",
      default_name: "Uusimaa",
    },
    {
      region_id: 337,
      country_id: "FI",
      code: "Itä-Uusimaa",
      default_name: "Itä-Uusimaa",
    },
    {
      region_id: 338,
      country_id: "FI",
      code: "Kymenlaakso",
      default_name: "Kymenlaakso",
    },
    {
      region_id: 339,
      country_id: "FI",
      code: "Ahvenanmaa",
      default_name: "Ahvenanmaa",
    },
    {
      region_id: 340,
      country_id: "EE",
      code: "EE-37",
      default_name: "Harjumaa",
    },
    {
      region_id: 341,
      country_id: "EE",
      code: "EE-39",
      default_name: "Hiiumaa",
    },
    {
      region_id: 342,
      country_id: "EE",
      code: "EE-44",
      default_name: "Ida-Virumaa",
    },
    {
      region_id: 343,
      country_id: "EE",
      code: "EE-49",
      default_name: "Jõgevamaa",
    },
    {
      region_id: 344,
      country_id: "EE",
      code: "EE-51",
      default_name: "Järvamaa",
    },
    {
      region_id: 345,
      country_id: "EE",
      code: "EE-57",
      default_name: "Läänemaa",
    },
    {
      region_id: 346,
      country_id: "EE",
      code: "EE-59",
      default_name: "Lääne-Virumaa",
    },
    {
      region_id: 347,
      country_id: "EE",
      code: "EE-65",
      default_name: "Põlvamaa",
    },
    {
      region_id: 348,
      country_id: "EE",
      code: "EE-67",
      default_name: "Pärnumaa",
    },
    {
      region_id: 349,
      country_id: "EE",
      code: "EE-70",
      default_name: "Raplamaa",
    },
    {
      region_id: 350,
      country_id: "EE",
      code: "EE-74",
      default_name: "Saaremaa",
    },
    {
      region_id: 351,
      country_id: "EE",
      code: "EE-78",
      default_name: "Tartumaa",
    },
    {
      region_id: 352,
      country_id: "EE",
      code: "EE-82",
      default_name: "Valgamaa",
    },
    {
      region_id: 353,
      country_id: "EE",
      code: "EE-84",
      default_name: "Viljandimaa",
    },
    {
      region_id: 354,
      country_id: "EE",
      code: "EE-86",
      default_name: "Võrumaa",
    },
    {
      region_id: 355,
      country_id: "LV",
      code: "LV-DGV",
      default_name: "Daugavpils",
    },
    {
      region_id: 356,
      country_id: "LV",
      code: "LV-JEL",
      default_name: "Jelgava",
    },
    {
      region_id: 357,
      country_id: "LV",
      code: "Jēkabpils",
      default_name: "Jēkabpils",
    },
    {
      region_id: 358,
      country_id: "LV",
      code: "LV-JUR",
      default_name: "Jūrmala",
    },
    {
      region_id: 359,
      country_id: "LV",
      code: "LV-LPX",
      default_name: "Liepāja",
    },
    {
      region_id: 360,
      country_id: "LV",
      code: "LV-LE",
      default_name: "Liepājas novads",
    },
    {
      region_id: 361,
      country_id: "LV",
      code: "LV-REZ",
      default_name: "Rēzekne",
    },
    {
      region_id: 362,
      country_id: "LV",
      code: "LV-RIX",
      default_name: "Rīga",
    },
    {
      region_id: 363,
      country_id: "LV",
      code: "LV-RI",
      default_name: "Rīgas novads",
    },
    {
      region_id: 364,
      country_id: "LV",
      code: "Valmiera",
      default_name: "Valmiera",
    },
    {
      region_id: 365,
      country_id: "LV",
      code: "LV-VEN",
      default_name: "Ventspils",
    },
    {
      region_id: 366,
      country_id: "LV",
      code: "Aglonas novads",
      default_name: "Aglonas novads",
    },
    {
      region_id: 367,
      country_id: "LV",
      code: "LV-AI",
      default_name: "Aizkraukles novads",
    },
    {
      region_id: 368,
      country_id: "LV",
      code: "Aizputes novads",
      default_name: "Aizputes novads",
    },
    {
      region_id: 369,
      country_id: "LV",
      code: "Aknīstes novads",
      default_name: "Aknīstes novads",
    },
    {
      region_id: 370,
      country_id: "LV",
      code: "Alojas novads",
      default_name: "Alojas novads",
    },
    {
      region_id: 371,
      country_id: "LV",
      code: "Alsungas novads",
      default_name: "Alsungas novads",
    },
    {
      region_id: 372,
      country_id: "LV",
      code: "LV-AL",
      default_name: "Alūksnes novads",
    },
    {
      region_id: 373,
      country_id: "LV",
      code: "Amatas novads",
      default_name: "Amatas novads",
    },
    {
      region_id: 374,
      country_id: "LV",
      code: "Apes novads",
      default_name: "Apes novads",
    },
    {
      region_id: 375,
      country_id: "LV",
      code: "Auces novads",
      default_name: "Auces novads",
    },
    {
      region_id: 376,
      country_id: "LV",
      code: "Babītes novads",
      default_name: "Babītes novads",
    },
    {
      region_id: 377,
      country_id: "LV",
      code: "Baldones novads",
      default_name: "Baldones novads",
    },
    {
      region_id: 378,
      country_id: "LV",
      code: "Baltinavas novads",
      default_name: "Baltinavas novads",
    },
    {
      region_id: 379,
      country_id: "LV",
      code: "LV-BL",
      default_name: "Balvu novads",
    },
    {
      region_id: 380,
      country_id: "LV",
      code: "LV-BU",
      default_name: "Bauskas novads",
    },
    {
      region_id: 381,
      country_id: "LV",
      code: "Beverīnas novads",
      default_name: "Beverīnas novads",
    },
    {
      region_id: 382,
      country_id: "LV",
      code: "Brocēnu novads",
      default_name: "Brocēnu novads",
    },
    {
      region_id: 383,
      country_id: "LV",
      code: "Burtnieku novads",
      default_name: "Burtnieku novads",
    },
    {
      region_id: 384,
      country_id: "LV",
      code: "Carnikavas novads",
      default_name: "Carnikavas novads",
    },
    {
      region_id: 385,
      country_id: "LV",
      code: "Cesvaines novads",
      default_name: "Cesvaines novads",
    },
    {
      region_id: 386,
      country_id: "LV",
      code: "Ciblas novads",
      default_name: "Ciblas novads",
    },
    {
      region_id: 387,
      country_id: "LV",
      code: "LV-CE",
      default_name: "Cēsu novads",
    },
    {
      region_id: 388,
      country_id: "LV",
      code: "Dagdas novads",
      default_name: "Dagdas novads",
    },
    {
      region_id: 389,
      country_id: "LV",
      code: "LV-DA",
      default_name: "Daugavpils novads",
    },
    {
      region_id: 390,
      country_id: "LV",
      code: "LV-DO",
      default_name: "Dobeles novads",
    },
    {
      region_id: 391,
      country_id: "LV",
      code: "Dundagas novads",
      default_name: "Dundagas novads",
    },
    {
      region_id: 392,
      country_id: "LV",
      code: "Durbes novads",
      default_name: "Durbes novads",
    },
    {
      region_id: 393,
      country_id: "LV",
      code: "Engures novads",
      default_name: "Engures novads",
    },
    {
      region_id: 394,
      country_id: "LV",
      code: "Garkalnes novads",
      default_name: "Garkalnes novads",
    },
    {
      region_id: 395,
      country_id: "LV",
      code: "Grobiņas novads",
      default_name: "Grobiņas novads",
    },
    {
      region_id: 396,
      country_id: "LV",
      code: "LV-GU",
      default_name: "Gulbenes novads",
    },
    {
      region_id: 397,
      country_id: "LV",
      code: "Iecavas novads",
      default_name: "Iecavas novads",
    },
    {
      region_id: 398,
      country_id: "LV",
      code: "Ikšķiles novads",
      default_name: "Ikšķiles novads",
    },
    {
      region_id: 399,
      country_id: "LV",
      code: "Ilūkstes novads",
      default_name: "Ilūkstes novads",
    },
    {
      region_id: 400,
      country_id: "LV",
      code: "Inčukalna novads",
      default_name: "Inčukalna novads",
    },
    {
      region_id: 401,
      country_id: "LV",
      code: "Jaunjelgavas novads",
      default_name: "Jaunjelgavas novads",
    },
    {
      region_id: 402,
      country_id: "LV",
      code: "Jaunpiebalgas novads",
      default_name: "Jaunpiebalgas novads",
    },
    {
      region_id: 403,
      country_id: "LV",
      code: "Jaunpils novads",
      default_name: "Jaunpils novads",
    },
    {
      region_id: 404,
      country_id: "LV",
      code: "LV-JL",
      default_name: "Jelgavas novads",
    },
    {
      region_id: 405,
      country_id: "LV",
      code: "LV-JK",
      default_name: "Jēkabpils novads",
    },
    {
      region_id: 406,
      country_id: "LV",
      code: "Kandavas novads",
      default_name: "Kandavas novads",
    },
    {
      region_id: 407,
      country_id: "LV",
      code: "Kokneses novads",
      default_name: "Kokneses novads",
    },
    {
      region_id: 408,
      country_id: "LV",
      code: "Krimuldas novads",
      default_name: "Krimuldas novads",
    },
    {
      region_id: 409,
      country_id: "LV",
      code: "Krustpils novads",
      default_name: "Krustpils novads",
    },
    {
      region_id: 410,
      country_id: "LV",
      code: "LV-KR",
      default_name: "Krāslavas novads",
    },
    {
      region_id: 411,
      country_id: "LV",
      code: "LV-KU",
      default_name: "Kuldīgas novads",
    },
    {
      region_id: 412,
      country_id: "LV",
      code: "Kārsavas novads",
      default_name: "Kārsavas novads",
    },
    {
      region_id: 413,
      country_id: "LV",
      code: "Lielvārdes novads",
      default_name: "Lielvārdes novads",
    },
    {
      region_id: 414,
      country_id: "LV",
      code: "LV-LM",
      default_name: "Limbažu novads",
    },
    {
      region_id: 415,
      country_id: "LV",
      code: "Lubānas novads",
      default_name: "Lubānas novads",
    },
    {
      region_id: 416,
      country_id: "LV",
      code: "LV-LU",
      default_name: "Ludzas novads",
    },
    {
      region_id: 417,
      country_id: "LV",
      code: "Līgatnes novads",
      default_name: "Līgatnes novads",
    },
    {
      region_id: 418,
      country_id: "LV",
      code: "Līvānu novads",
      default_name: "Līvānu novads",
    },
    {
      region_id: 419,
      country_id: "LV",
      code: "LV-MA",
      default_name: "Madonas novads",
    },
    {
      region_id: 420,
      country_id: "LV",
      code: "Mazsalacas novads",
      default_name: "Mazsalacas novads",
    },
    {
      region_id: 421,
      country_id: "LV",
      code: "Mālpils novads",
      default_name: "Mālpils novads",
    },
    {
      region_id: 422,
      country_id: "LV",
      code: "Mārupes novads",
      default_name: "Mārupes novads",
    },
    {
      region_id: 423,
      country_id: "LV",
      code: "Naukšēnu novads",
      default_name: "Naukšēnu novads",
    },
    {
      region_id: 424,
      country_id: "LV",
      code: "Neretas novads",
      default_name: "Neretas novads",
    },
    {
      region_id: 425,
      country_id: "LV",
      code: "Nīcas novads",
      default_name: "Nīcas novads",
    },
    {
      region_id: 426,
      country_id: "LV",
      code: "LV-OG",
      default_name: "Ogres novads",
    },
    {
      region_id: 427,
      country_id: "LV",
      code: "Olaines novads",
      default_name: "Olaines novads",
    },
    {
      region_id: 428,
      country_id: "LV",
      code: "Ozolnieku novads",
      default_name: "Ozolnieku novads",
    },
    {
      region_id: 429,
      country_id: "LV",
      code: "LV-PR",
      default_name: "Preiļu novads",
    },
    {
      region_id: 430,
      country_id: "LV",
      code: "Priekules novads",
      default_name: "Priekules novads",
    },
    {
      region_id: 431,
      country_id: "LV",
      code: "Priekuļu novads",
      default_name: "Priekuļu novads",
    },
    {
      region_id: 432,
      country_id: "LV",
      code: "Pārgaujas novads",
      default_name: "Pārgaujas novads",
    },
    {
      region_id: 433,
      country_id: "LV",
      code: "Pāvilostas novads",
      default_name: "Pāvilostas novads",
    },
    {
      region_id: 434,
      country_id: "LV",
      code: "Pļaviņu novads",
      default_name: "Pļaviņu novads",
    },
    {
      region_id: 435,
      country_id: "LV",
      code: "Raunas novads",
      default_name: "Raunas novads",
    },
    {
      region_id: 436,
      country_id: "LV",
      code: "Riebiņu novads",
      default_name: "Riebiņu novads",
    },
    {
      region_id: 437,
      country_id: "LV",
      code: "Rojas novads",
      default_name: "Rojas novads",
    },
    {
      region_id: 438,
      country_id: "LV",
      code: "Ropažu novads",
      default_name: "Ropažu novads",
    },
    {
      region_id: 439,
      country_id: "LV",
      code: "Rucavas novads",
      default_name: "Rucavas novads",
    },
    {
      region_id: 440,
      country_id: "LV",
      code: "Rugāju novads",
      default_name: "Rugāju novads",
    },
    {
      region_id: 441,
      country_id: "LV",
      code: "Rundāles novads",
      default_name: "Rundāles novads",
    },
    {
      region_id: 442,
      country_id: "LV",
      code: "LV-RE",
      default_name: "Rēzeknes novads",
    },
    {
      region_id: 443,
      country_id: "LV",
      code: "Rūjienas novads",
      default_name: "Rūjienas novads",
    },
    {
      region_id: 444,
      country_id: "LV",
      code: "Salacgrīvas novads",
      default_name: "Salacgrīvas novads",
    },
    {
      region_id: 445,
      country_id: "LV",
      code: "Salas novads",
      default_name: "Salas novads",
    },
    {
      region_id: 446,
      country_id: "LV",
      code: "Salaspils novads",
      default_name: "Salaspils novads",
    },
    {
      region_id: 447,
      country_id: "LV",
      code: "LV-SA",
      default_name: "Saldus novads",
    },
    {
      region_id: 448,
      country_id: "LV",
      code: "Saulkrastu novads",
      default_name: "Saulkrastu novads",
    },
    {
      region_id: 449,
      country_id: "LV",
      code: "Siguldas novads",
      default_name: "Siguldas novads",
    },
    {
      region_id: 450,
      country_id: "LV",
      code: "Skrundas novads",
      default_name: "Skrundas novads",
    },
    {
      region_id: 451,
      country_id: "LV",
      code: "Skrīveru novads",
      default_name: "Skrīveru novads",
    },
    {
      region_id: 452,
      country_id: "LV",
      code: "Smiltenes novads",
      default_name: "Smiltenes novads",
    },
    {
      region_id: 453,
      country_id: "LV",
      code: "Stopiņu novads",
      default_name: "Stopiņu novads",
    },
    {
      region_id: 454,
      country_id: "LV",
      code: "Strenču novads",
      default_name: "Strenču novads",
    },
    {
      region_id: 455,
      country_id: "LV",
      code: "Sējas novads",
      default_name: "Sējas novads",
    },
    {
      region_id: 456,
      country_id: "LV",
      code: "LV-TA",
      default_name: "Talsu novads",
    },
    {
      region_id: 457,
      country_id: "LV",
      code: "LV-TU",
      default_name: "Tukuma novads",
    },
    {
      region_id: 458,
      country_id: "LV",
      code: "Tērvetes novads",
      default_name: "Tērvetes novads",
    },
    {
      region_id: 459,
      country_id: "LV",
      code: "Vaiņodes novads",
      default_name: "Vaiņodes novads",
    },
    {
      region_id: 460,
      country_id: "LV",
      code: "LV-VK",
      default_name: "Valkas novads",
    },
    {
      region_id: 461,
      country_id: "LV",
      code: "LV-VM",
      default_name: "Valmieras novads",
    },
    {
      region_id: 462,
      country_id: "LV",
      code: "Varakļānu novads",
      default_name: "Varakļānu novads",
    },
    {
      region_id: 463,
      country_id: "LV",
      code: "Vecpiebalgas novads",
      default_name: "Vecpiebalgas novads",
    },
    {
      region_id: 464,
      country_id: "LV",
      code: "Vecumnieku novads",
      default_name: "Vecumnieku novads",
    },
    {
      region_id: 465,
      country_id: "LV",
      code: "LV-VE",
      default_name: "Ventspils novads",
    },
    {
      region_id: 466,
      country_id: "LV",
      code: "Viesītes novads",
      default_name: "Viesītes novads",
    },
    {
      region_id: 467,
      country_id: "LV",
      code: "Viļakas novads",
      default_name: "Viļakas novads",
    },
    {
      region_id: 468,
      country_id: "LV",
      code: "Viļānu novads",
      default_name: "Viļānu novads",
    },
    {
      region_id: 469,
      country_id: "LV",
      code: "Vārkavas novads",
      default_name: "Vārkavas novads",
    },
    {
      region_id: 470,
      country_id: "LV",
      code: "Zilupes novads",
      default_name: "Zilupes novads",
    },
    {
      region_id: 471,
      country_id: "LV",
      code: "Ādažu novads",
      default_name: "Ādažu novads",
    },
    {
      region_id: 472,
      country_id: "LV",
      code: "Ērgļu novads",
      default_name: "Ērgļu novads",
    },
    {
      region_id: 473,
      country_id: "LV",
      code: "Ķeguma novads",
      default_name: "Ķeguma novads",
    },
    {
      region_id: 474,
      country_id: "LV",
      code: "Ķekavas novads",
      default_name: "Ķekavas novads",
    },
    {
      region_id: 475,
      country_id: "LT",
      code: "LT-AL",
      default_name: "Alytaus Apskritis",
    },
    {
      region_id: 476,
      country_id: "LT",
      code: "LT-KU",
      default_name: "Kauno Apskritis",
    },
    {
      region_id: 477,
      country_id: "LT",
      code: "LT-KL",
      default_name: "Klaipėdos Apskritis",
    },
    {
      region_id: 478,
      country_id: "LT",
      code: "LT-MR",
      default_name: "Marijampolės Apskritis",
    },
    {
      region_id: 479,
      country_id: "LT",
      code: "LT-PN",
      default_name: "Panevėžio Apskritis",
    },
    {
      region_id: 480,
      country_id: "LT",
      code: "LT-SA",
      default_name: "Šiaulių Apskritis",
    },
    {
      region_id: 481,
      country_id: "LT",
      code: "LT-TA",
      default_name: "Tauragės Apskritis",
    },
    {
      region_id: 482,
      country_id: "LT",
      code: "LT-TE",
      default_name: "Telšių Apskritis",
    },
    {
      region_id: 483,
      country_id: "LT",
      code: "LT-UT",
      default_name: "Utenos Apskritis",
    },
    {
      region_id: 484,
      country_id: "LT",
      code: "LT-VL",
      default_name: "Vilniaus Apskritis",
    },
    {
      region_id: 485,
      country_id: "BR",
      code: "AC",
      default_name: "Acre",
    },
    {
      region_id: 486,
      country_id: "BR",
      code: "AL",
      default_name: "Alagoas",
    },
    {
      region_id: 487,
      country_id: "BR",
      code: "AP",
      default_name: "Amapá",
    },
    {
      region_id: 488,
      country_id: "BR",
      code: "AM",
      default_name: "Amazonas",
    },
    {
      region_id: 489,
      country_id: "BR",
      code: "BA",
      default_name: "Bahia",
    },
    {
      region_id: 490,
      country_id: "BR",
      code: "CE",
      default_name: "Ceará",
    },
    {
      region_id: 491,
      country_id: "BR",
      code: "ES",
      default_name: "Espírito Santo",
    },
    {
      region_id: 492,
      country_id: "BR",
      code: "GO",
      default_name: "Goiás",
    },
    {
      region_id: 493,
      country_id: "BR",
      code: "MA",
      default_name: "Maranhão",
    },
    {
      region_id: 494,
      country_id: "BR",
      code: "MT",
      default_name: "Mato Grosso",
    },
    {
      region_id: 495,
      country_id: "BR",
      code: "MS",
      default_name: "Mato Grosso do Sul",
    },
    {
      region_id: 496,
      country_id: "BR",
      code: "MG",
      default_name: "Minas Gerais",
    },
    {
      region_id: 497,
      country_id: "BR",
      code: "PA",
      default_name: "Pará",
    },
    {
      region_id: 498,
      country_id: "BR",
      code: "PB",
      default_name: "Paraíba",
    },
    {
      region_id: 499,
      country_id: "BR",
      code: "PR",
      default_name: "Paraná",
    },
    {
      region_id: 500,
      country_id: "BR",
      code: "PE",
      default_name: "Pernambuco",
    },
    {
      region_id: 501,
      country_id: "BR",
      code: "PI",
      default_name: "Piauí",
    },
    {
      region_id: 502,
      country_id: "BR",
      code: "RJ",
      default_name: "Rio de Janeiro",
    },
    {
      region_id: 503,
      country_id: "BR",
      code: "RN",
      default_name: "Rio Grande do Norte",
    },
    {
      region_id: 504,
      country_id: "BR",
      code: "RS",
      default_name: "Rio Grande do Sul",
    },
    {
      region_id: 505,
      country_id: "BR",
      code: "RO",
      default_name: "Rondônia",
    },
    {
      region_id: 506,
      country_id: "BR",
      code: "RR",
      default_name: "Roraima",
    },
    {
      region_id: 507,
      country_id: "BR",
      code: "SC",
      default_name: "Santa Catarina",
    },
    {
      region_id: 508,
      country_id: "BR",
      code: "SP",
      default_name: "São Paulo",
    },
    {
      region_id: 509,
      country_id: "BR",
      code: "SE",
      default_name: "Sergipe",
    },
    {
      region_id: 510,
      country_id: "BR",
      code: "TO",
      default_name: "Tocantins",
    },
    {
      region_id: 511,
      country_id: "BR",
      code: "DF",
      default_name: "Distrito Federal",
    },
    {
      region_id: 512,
      country_id: "HR",
      code: "HR-01",
      default_name: "Zagrebačka županija",
    },
    {
      region_id: 513,
      country_id: "HR",
      code: "HR-02",
      default_name: "Krapinsko-zagorska županija",
    },
    {
      region_id: 514,
      country_id: "HR",
      code: "HR-03",
      default_name: "Sisačko-moslavačka županija",
    },
    {
      region_id: 515,
      country_id: "HR",
      code: "HR-04",
      default_name: "Karlovačka županija",
    },
    {
      region_id: 516,
      country_id: "HR",
      code: "HR-05",
      default_name: "Varaždinska županija",
    },
    {
      region_id: 517,
      country_id: "HR",
      code: "HR-06",
      default_name: "Koprivničko-križevačka županija",
    },
    {
      region_id: 518,
      country_id: "HR",
      code: "HR-07",
      default_name: "Bjelovarsko-bilogorska županija",
    },
    {
      region_id: 519,
      country_id: "HR",
      code: "HR-08",
      default_name: "Primorsko-goranska županija",
    },
    {
      region_id: 520,
      country_id: "HR",
      code: "HR-09",
      default_name: "Ličko-senjska županija",
    },
    {
      region_id: 521,
      country_id: "HR",
      code: "HR-10",
      default_name: "Virovitičko-podravska županija",
    },
    {
      region_id: 522,
      country_id: "HR",
      code: "HR-11",
      default_name: "Požeško-slavonska županija",
    },
    {
      region_id: 523,
      country_id: "HR",
      code: "HR-12",
      default_name: "Brodsko-posavska županija",
    },
    {
      region_id: 524,
      country_id: "HR",
      code: "HR-13",
      default_name: "Zadarska županija",
    },
    {
      region_id: 525,
      country_id: "HR",
      code: "HR-14",
      default_name: "Osječko-baranjska županija",
    },
    {
      region_id: 526,
      country_id: "HR",
      code: "HR-15",
      default_name: "Šibensko-kninska županija",
    },
    {
      region_id: 527,
      country_id: "HR",
      code: "HR-16",
      default_name: "Vukovarsko-srijemska županija",
    },
    {
      region_id: 528,
      country_id: "HR",
      code: "HR-17",
      default_name: "Splitsko-dalmatinska županija",
    },
    {
      region_id: 529,
      country_id: "HR",
      code: "HR-18",
      default_name: "Istarska županija",
    },
    {
      region_id: 530,
      country_id: "HR",
      code: "HR-19",
      default_name: "Dubrovačko-neretvanska županija",
    },
    {
      region_id: 531,
      country_id: "HR",
      code: "HR-20",
      default_name: "Međimurska županija",
    },
    {
      region_id: 532,
      country_id: "HR",
      code: "HR-21",
      default_name: "Grad Zagreb",
    },
    {
      region_id: 533,
      country_id: "IN",
      code: "AN",
      default_name: "Andaman and Nicobar Islands",
    },
    {
      region_id: 534,
      country_id: "IN",
      code: "AP",
      default_name: "Andhra Pradesh",
    },
    {
      region_id: 535,
      country_id: "IN",
      code: "AR",
      default_name: "Arunachal Pradesh",
    },
    {
      region_id: 536,
      country_id: "IN",
      code: "AS",
      default_name: "Assam",
    },
    {
      region_id: 537,
      country_id: "IN",
      code: "BR",
      default_name: "Bihar",
    },
    {
      region_id: 538,
      country_id: "IN",
      code: "CH",
      default_name: "Chandigarh",
    },
    {
      region_id: 539,
      country_id: "IN",
      code: "CT",
      default_name: "Chhattisgarh",
    },
    {
      region_id: 540,
      country_id: "IN",
      code: "DN",
      default_name: "Dadra and Nagar Haveli",
    },
    {
      region_id: 541,
      country_id: "IN",
      code: "DD",
      default_name: "Daman and Diu",
    },
    {
      region_id: 542,
      country_id: "IN",
      code: "DL",
      default_name: "Delhi",
    },
    {
      region_id: 543,
      country_id: "IN",
      code: "GA",
      default_name: "Goa",
    },
    {
      region_id: 544,
      country_id: "IN",
      code: "GJ",
      default_name: "Gujarat",
    },
    {
      region_id: 545,
      country_id: "IN",
      code: "HR",
      default_name: "Haryana",
    },
    {
      region_id: 546,
      country_id: "IN",
      code: "HP",
      default_name: "Himachal Pradesh",
    },
    {
      region_id: 547,
      country_id: "IN",
      code: "JK",
      default_name: "Jammu and Kashmir",
    },
    {
      region_id: 548,
      country_id: "IN",
      code: "JH",
      default_name: "Jharkhand",
    },
    {
      region_id: 549,
      country_id: "IN",
      code: "KA",
      default_name: "Karnataka",
    },
    {
      region_id: 550,
      country_id: "IN",
      code: "KL",
      default_name: "Kerala",
    },
    {
      region_id: 551,
      country_id: "IN",
      code: "LD",
      default_name: "Lakshadweep",
    },
    {
      region_id: 552,
      country_id: "IN",
      code: "MP",
      default_name: "Madhya Pradesh",
    },
    {
      region_id: 553,
      country_id: "IN",
      code: "MH",
      default_name: "Maharashtra",
    },
    {
      region_id: 554,
      country_id: "IN",
      code: "MN",
      default_name: "Manipur",
    },
    {
      region_id: 555,
      country_id: "IN",
      code: "ML",
      default_name: "Meghalaya",
    },
    {
      region_id: 556,
      country_id: "IN",
      code: "MZ",
      default_name: "Mizoram",
    },
    {
      region_id: 557,
      country_id: "IN",
      code: "NL",
      default_name: "Nagaland",
    },
    {
      region_id: 558,
      country_id: "IN",
      code: "OR",
      default_name: "Odisha",
    },
    {
      region_id: 559,
      country_id: "IN",
      code: "PY",
      default_name: "Puducherry",
    },
    {
      region_id: 560,
      country_id: "IN",
      code: "PB",
      default_name: "Punjab",
    },
    {
      region_id: 561,
      country_id: "IN",
      code: "RJ",
      default_name: "Rajasthan",
    },
    {
      region_id: 562,
      country_id: "IN",
      code: "SK",
      default_name: "Sikkim",
    },
    {
      region_id: 563,
      country_id: "IN",
      code: "TN",
      default_name: "Tamil Nadu",
    },
    {
      region_id: 564,
      country_id: "IN",
      code: "TG",
      default_name: "Telangana",
    },
    {
      region_id: 565,
      country_id: "IN",
      code: "TR",
      default_name: "Tripura",
    },
    {
      region_id: 566,
      country_id: "IN",
      code: "UP",
      default_name: "Uttar Pradesh",
    },
    {
      region_id: 567,
      country_id: "IN",
      code: "UT",
      default_name: "Uttarakhand",
    },
    {
      region_id: 568,
      country_id: "IN",
      code: "WB",
      default_name: "West Bengal",
    },
    {
      region_id: 569,
      country_id: "AU",
      code: "ACT",
      default_name: "Australian Capital Territory",
    },
    {
      region_id: 570,
      country_id: "AU",
      code: "NSW",
      default_name: "New South Wales",
    },
    {
      region_id: 571,
      country_id: "AU",
      code: "VIC",
      default_name: "Victoria",
    },
    {
      region_id: 572,
      country_id: "AU",
      code: "QLD",
      default_name: "Queensland",
    },
    {
      region_id: 573,
      country_id: "AU",
      code: "SA",
      default_name: "South Australia",
    },
    {
      region_id: 574,
      country_id: "AU",
      code: "TAS",
      default_name: "Tasmania",
    },
    {
      region_id: 575,
      country_id: "AU",
      code: "WA",
      default_name: "Western Australia",
    },
    {
      region_id: 576,
      country_id: "AU",
      code: "NT",
      default_name: "Northern Territory",
    },
    {
      region_id: 577,
      country_id: "BE",
      code: "VAN",
      default_name: "Antwerpen",
    },
    {
      region_id: 578,
      country_id: "BE",
      code: "WBR",
      default_name: "Brabant wallon",
    },
    {
      region_id: 579,
      country_id: "BE",
      code: "BRU",
      default_name: "Brussels-Capital Region",
    },
    {
      region_id: 580,
      country_id: "BE",
      code: "WHT",
      default_name: "Hainaut",
    },
    {
      region_id: 581,
      country_id: "BE",
      code: "VLI",
      default_name: "Limburg",
    },
    {
      region_id: 582,
      country_id: "BE",
      code: "WLG",
      default_name: "Liège",
    },
    {
      region_id: 583,
      country_id: "BE",
      code: "WLX",
      default_name: "Luxembourg",
    },
    {
      region_id: 584,
      country_id: "BE",
      code: "WNA",
      default_name: "Namur",
    },
    {
      region_id: 585,
      country_id: "BE",
      code: "VOV",
      default_name: "Oost-Vlaanderen",
    },
    {
      region_id: 586,
      country_id: "BE",
      code: "VBR",
      default_name: "Vlaams-Brabant",
    },
    {
      region_id: 587,
      country_id: "BE",
      code: "VWV",
      default_name: "West-Vlaanderen",
    },
    {
      region_id: 588,
      country_id: "BG",
      code: "BG-01",
      default_name: "Blagoevgrad",
    },
    {
      region_id: 589,
      country_id: "BG",
      code: "BG-02",
      default_name: "Burgas",
    },
    {
      region_id: 590,
      country_id: "BG",
      code: "BG-03",
      default_name: "Varna",
    },
    {
      region_id: 591,
      country_id: "BG",
      code: "BG-04",
      default_name: "Veliko Tarnovo",
    },
    {
      region_id: 592,
      country_id: "BG",
      code: "BG-05",
      default_name: "Vidin",
    },
    {
      region_id: 593,
      country_id: "BG",
      code: "BG-06",
      default_name: "Vratsa",
    },
    {
      region_id: 594,
      country_id: "BG",
      code: "BG-07",
      default_name: "Gabrovo",
    },
    {
      region_id: 595,
      country_id: "BG",
      code: "BG-08",
      default_name: "Dobrich",
    },
    {
      region_id: 596,
      country_id: "BG",
      code: "BG-09",
      default_name: "Kardzhali",
    },
    {
      region_id: 597,
      country_id: "BG",
      code: "BG-10",
      default_name: "Kyustendil",
    },
    {
      region_id: 598,
      country_id: "BG",
      code: "BG-11",
      default_name: "Lovech",
    },
    {
      region_id: 599,
      country_id: "BG",
      code: "BG-12",
      default_name: "Montana",
    },
    {
      region_id: 600,
      country_id: "BG",
      code: "BG-13",
      default_name: "Pazardzhik",
    },
    {
      region_id: 601,
      country_id: "BG",
      code: "BG-14",
      default_name: "Pernik",
    },
    {
      region_id: 602,
      country_id: "BG",
      code: "BG-15",
      default_name: "Pleven",
    },
    {
      region_id: 603,
      country_id: "BG",
      code: "BG-16",
      default_name: "Plovdiv",
    },
    {
      region_id: 604,
      country_id: "BG",
      code: "BG-17",
      default_name: "Razgrad",
    },
    {
      region_id: 605,
      country_id: "BG",
      code: "BG-18",
      default_name: "Ruse",
    },
    {
      region_id: 606,
      country_id: "BG",
      code: "BG-19",
      default_name: "Silistra",
    },
    {
      region_id: 607,
      country_id: "BG",
      code: "BG-20",
      default_name: "Sliven",
    },
    {
      region_id: 608,
      country_id: "BG",
      code: "BG-21",
      default_name: "Smolyan",
    },
    {
      region_id: 609,
      country_id: "BG",
      code: "BG-22",
      default_name: "Sofia City",
    },
    {
      region_id: 610,
      country_id: "BG",
      code: "BG-23",
      default_name: "Sofia Province",
    },
    {
      region_id: 611,
      country_id: "BG",
      code: "BG-24",
      default_name: "Stara Zagora",
    },
    {
      region_id: 612,
      country_id: "BG",
      code: "BG-25",
      default_name: "Targovishte",
    },
    {
      region_id: 613,
      country_id: "BG",
      code: "BG-26",
      default_name: "Haskovo",
    },
    {
      region_id: 614,
      country_id: "BG",
      code: "BG-27",
      default_name: "Shumen",
    },
    {
      region_id: 615,
      country_id: "BG",
      code: "BG-28",
      default_name: "Yambol",
    },
    {
      region_id: 616,
      country_id: "CN",
      code: "CN-AH",
      default_name: "Anhui Sheng",
    },
    {
      region_id: 617,
      country_id: "CN",
      code: "CN-BJ",
      default_name: "Beijing Shi",
    },
    {
      region_id: 618,
      country_id: "CN",
      code: "CN-CQ",
      default_name: "Chongqing Shi",
    },
    {
      region_id: 619,
      country_id: "CN",
      code: "CN-FJ",
      default_name: "Fujian Sheng",
    },
    {
      region_id: 620,
      country_id: "CN",
      code: "CN-GS",
      default_name: "Gansu Sheng",
    },
    {
      region_id: 621,
      country_id: "CN",
      code: "CN-GD",
      default_name: "Guangdong Sheng",
    },
    {
      region_id: 622,
      country_id: "CN",
      code: "CN-GX",
      default_name: "Guangxi Zhuangzu Zizhiqu",
    },
    {
      region_id: 623,
      country_id: "CN",
      code: "CN-GZ",
      default_name: "Guizhou Sheng",
    },
    {
      region_id: 624,
      country_id: "CN",
      code: "CN-HI",
      default_name: "Hainan Sheng",
    },
    {
      region_id: 625,
      country_id: "CN",
      code: "CN-HE",
      default_name: "Hebei Sheng",
    },
    {
      region_id: 626,
      country_id: "CN",
      code: "CN-HL",
      default_name: "Heilongjiang Sheng",
    },
    {
      region_id: 627,
      country_id: "CN",
      code: "CN-HA",
      default_name: "Henan Sheng",
    },
    {
      region_id: 628,
      country_id: "CN",
      code: "CN-HK",
      default_name: "Hong Kong SAR",
    },
    {
      region_id: 629,
      country_id: "CN",
      code: "CN-HB",
      default_name: "Hubei Sheng",
    },
    {
      region_id: 630,
      country_id: "CN",
      code: "CN-HN",
      default_name: "Hunan Sheng",
    },
    {
      region_id: 631,
      country_id: "CN",
      code: "CN-JS",
      default_name: "Jiangsu Sheng",
    },
    {
      region_id: 632,
      country_id: "CN",
      code: "CN-JX",
      default_name: "Jiangxi Sheng",
    },
    {
      region_id: 633,
      country_id: "CN",
      code: "CN-JL",
      default_name: "Jilin Sheng",
    },
    {
      region_id: 634,
      country_id: "CN",
      code: "CN-LN",
      default_name: "Liaoning Sheng",
    },
    {
      region_id: 635,
      country_id: "CN",
      code: "CN-MO",
      default_name: "Macao SAR",
    },
    {
      region_id: 636,
      country_id: "CN",
      code: "CN-NM",
      default_name: "Nei Mongol Zizhiqu",
    },
    {
      region_id: 637,
      country_id: "CN",
      code: "CN-NX",
      default_name: "Ningxia Huizi Zizhiqu",
    },
    {
      region_id: 638,
      country_id: "CN",
      code: "CN-QH",
      default_name: "Qinghai Sheng",
    },
    {
      region_id: 639,
      country_id: "CN",
      code: "CN-SN",
      default_name: "Shaanxi Sheng",
    },
    {
      region_id: 640,
      country_id: "CN",
      code: "CN-SD",
      default_name: "Shandong Sheng",
    },
    {
      region_id: 641,
      country_id: "CN",
      code: "CN-SH",
      default_name: "Shanghai Shi",
    },
    {
      region_id: 642,
      country_id: "CN",
      code: "CN-SX",
      default_name: "Shanxi Sheng",
    },
    {
      region_id: 643,
      country_id: "CN",
      code: "CN-SC",
      default_name: "Sichuan Sheng",
    },
    {
      region_id: 644,
      country_id: "CN",
      code: "CN-TW",
      default_name: "Taiwan Sheng",
    },
    {
      region_id: 645,
      country_id: "CN",
      code: "CN-TJ",
      default_name: "Tianjin Shi",
    },
    {
      region_id: 646,
      country_id: "CN",
      code: "CN-XJ",
      default_name: "Xinjiang Uygur Zizhiqu",
    },
    {
      region_id: 647,
      country_id: "CN",
      code: "CN-XZ",
      default_name: "Xizang Zizhiqu",
    },
    {
      region_id: 648,
      country_id: "CN",
      code: "CN-YN",
      default_name: "Yunnan Sheng",
    },
    {
      region_id: 649,
      country_id: "CN",
      code: "CN-ZJ",
      default_name: "Zhejiang Sheng",
    },
    {
      region_id: 650,
      country_id: "CO",
      code: "CO-AMA",
      default_name: "Amazonas",
    },
    {
      region_id: 651,
      country_id: "CO",
      code: "CO-ANT",
      default_name: "Antioquia",
    },
    {
      region_id: 652,
      country_id: "CO",
      code: "CO-ARA",
      default_name: "Arauca",
    },
    {
      region_id: 653,
      country_id: "CO",
      code: "CO-ATL",
      default_name: "Atlántico",
    },
    {
      region_id: 654,
      country_id: "CO",
      code: "CO-BOL",
      default_name: "Bolívar",
    },
    {
      region_id: 655,
      country_id: "CO",
      code: "CO-BOY",
      default_name: "Boyacá",
    },
    {
      region_id: 656,
      country_id: "CO",
      code: "CO-CAL",
      default_name: "Caldas",
    },
    {
      region_id: 657,
      country_id: "CO",
      code: "CO-CAQ",
      default_name: "Caquetá",
    },
    {
      region_id: 658,
      country_id: "CO",
      code: "CO-CAS",
      default_name: "Casanare",
    },
    {
      region_id: 659,
      country_id: "CO",
      code: "CO-CAU",
      default_name: "Cauca",
    },
    {
      region_id: 660,
      country_id: "CO",
      code: "CO-CES",
      default_name: "Cesar",
    },
    {
      region_id: 661,
      country_id: "CO",
      code: "CO-CHO",
      default_name: "Chocó",
    },
    {
      region_id: 662,
      country_id: "CO",
      code: "CO-COR",
      default_name: "Córdoba",
    },
    {
      region_id: 663,
      country_id: "CO",
      code: "CO-CUN",
      default_name: "Cundinamarca",
    },
    {
      region_id: 664,
      country_id: "CO",
      code: "CO-GUA",
      default_name: "Guainía",
    },
    {
      region_id: 665,
      country_id: "CO",
      code: "CO-GUV",
      default_name: "Guaviare",
    },
    {
      region_id: 666,
      country_id: "CO",
      code: "CO-HUL",
      default_name: "Huila",
    },
    {
      region_id: 667,
      country_id: "CO",
      code: "CO-LAG",
      default_name: "La Guajira",
    },
    {
      region_id: 668,
      country_id: "CO",
      code: "CO-MAG",
      default_name: "Magdalena",
    },
    {
      region_id: 669,
      country_id: "CO",
      code: "CO-MET",
      default_name: "Meta",
    },
    {
      region_id: 670,
      country_id: "CO",
      code: "CO-NAR",
      default_name: "Nariño",
    },
    {
      region_id: 671,
      country_id: "CO",
      code: "CO-NSA",
      default_name: "Norte de Santander",
    },
    {
      region_id: 672,
      country_id: "CO",
      code: "CO-PUT",
      default_name: "Putumayo",
    },
    {
      region_id: 673,
      country_id: "CO",
      code: "CO-QUI",
      default_name: "Quindío",
    },
    {
      region_id: 674,
      country_id: "CO",
      code: "CO-RIS",
      default_name: "Risaralda",
    },
    {
      region_id: 675,
      country_id: "CO",
      code: "CO-SAP",
      default_name: "San Andrés y Providencia",
    },
    {
      region_id: 676,
      country_id: "CO",
      code: "CO-SAN",
      default_name: "Santander",
    },
    {
      region_id: 677,
      country_id: "CO",
      code: "CO-SUC",
      default_name: "Sucre",
    },
    {
      region_id: 678,
      country_id: "CO",
      code: "CO-TOL",
      default_name: "Tolima",
    },
    {
      region_id: 679,
      country_id: "CO",
      code: "CO-VAC",
      default_name: "Valle del Cauca",
    },
    {
      region_id: 680,
      country_id: "CO",
      code: "CO-VAU",
      default_name: "Vaupés",
    },
    {
      region_id: 681,
      country_id: "CO",
      code: "CO-VID",
      default_name: "Vichada",
    },
    {
      region_id: 792,
      country_id: "MX",
      code: "AGU",
      default_name: "Aguascalientes",
    },
    {
      region_id: 793,
      country_id: "MX",
      code: "BCN",
      default_name: "Baja California",
    },
    {
      region_id: 794,
      country_id: "MX",
      code: "BCS",
      default_name: "Baja California Sur",
    },
    {
      region_id: 795,
      country_id: "MX",
      code: "CAM",
      default_name: "Campeche",
    },
    {
      region_id: 796,
      country_id: "MX",
      code: "CHP",
      default_name: "Chiapas",
    },
    {
      region_id: 797,
      country_id: "MX",
      code: "CHH",
      default_name: "Chihuahua",
    },
    {
      region_id: 798,
      country_id: "MX",
      code: "CMX",
      default_name: "Ciudad de México",
    },
    {
      region_id: 799,
      country_id: "MX",
      code: "COA",
      default_name: "Coahuila",
    },
    {
      region_id: 800,
      country_id: "MX",
      code: "COL",
      default_name: "Colima",
    },
    {
      region_id: 801,
      country_id: "MX",
      code: "DUR",
      default_name: "Durango",
    },
    {
      region_id: 802,
      country_id: "MX",
      code: "MEX",
      default_name: "Estado de México",
    },
    {
      region_id: 803,
      country_id: "MX",
      code: "GUA",
      default_name: "Guanajuato",
    },
    {
      region_id: 804,
      country_id: "MX",
      code: "GRO",
      default_name: "Guerrero",
    },
    {
      region_id: 805,
      country_id: "MX",
      code: "HID",
      default_name: "Hidalgo",
    },
    {
      region_id: 806,
      country_id: "MX",
      code: "JAL",
      default_name: "Jalisco",
    },
    {
      region_id: 807,
      country_id: "MX",
      code: "MIC",
      default_name: "Michoacán",
    },
    {
      region_id: 808,
      country_id: "MX",
      code: "MOR",
      default_name: "Morelos",
    },
    {
      region_id: 809,
      country_id: "MX",
      code: "NAY",
      default_name: "Nayarit",
    },
    {
      region_id: 810,
      country_id: "MX",
      code: "NLE",
      default_name: "Nuevo León",
    },
    {
      region_id: 811,
      country_id: "MX",
      code: "OAX",
      default_name: "Oaxaca",
    },
    {
      region_id: 812,
      country_id: "MX",
      code: "PUE",
      default_name: "Puebla",
    },
    {
      region_id: 813,
      country_id: "MX",
      code: "QUE",
      default_name: "Querétaro",
    },
    {
      region_id: 814,
      country_id: "MX",
      code: "ROO",
      default_name: "Quintana Roo",
    },
    {
      region_id: 815,
      country_id: "MX",
      code: "SLP",
      default_name: "San Luis Potosí",
    },
    {
      region_id: 816,
      country_id: "MX",
      code: "SIN",
      default_name: "Sinaloa",
    },
    {
      region_id: 817,
      country_id: "MX",
      code: "SON",
      default_name: "Sonora",
    },
    {
      region_id: 818,
      country_id: "MX",
      code: "TAB",
      default_name: "Tabasco",
    },
    {
      region_id: 819,
      country_id: "MX",
      code: "TAM",
      default_name: "Tamaulipas",
    },
    {
      region_id: 820,
      country_id: "MX",
      code: "TLA",
      default_name: "Tlaxcala",
    },
    {
      region_id: 821,
      country_id: "MX",
      code: "VER",
      default_name: "Veracruz",
    },
    {
      region_id: 822,
      country_id: "MX",
      code: "YUC",
      default_name: "Yucatán",
    },
    {
      region_id: 823,
      country_id: "MX",
      code: "ZAC",
      default_name: "Zacatecas",
    },
    {
      region_id: 824,
      country_id: "PL",
      code: "PL-02",
      default_name: "dolnośląskie",
    },
    {
      region_id: 825,
      country_id: "PL",
      code: "PL-04",
      default_name: "kujawsko-pomorskie",
    },
    {
      region_id: 826,
      country_id: "PL",
      code: "PL-06",
      default_name: "lubelskie",
    },
    {
      region_id: 827,
      country_id: "PL",
      code: "PL-08",
      default_name: "lubuskie",
    },
    {
      region_id: 828,
      country_id: "PL",
      code: "PL-10",
      default_name: "łódzkie",
    },
    {
      region_id: 829,
      country_id: "PL",
      code: "PL-12",
      default_name: "małopolskie",
    },
    {
      region_id: 830,
      country_id: "PL",
      code: "PL-14",
      default_name: "mazowieckie",
    },
    {
      region_id: 831,
      country_id: "PL",
      code: "PL-16",
      default_name: "opolskie",
    },
    {
      region_id: 832,
      country_id: "PL",
      code: "PL-18",
      default_name: "podkarpackie",
    },
    {
      region_id: 833,
      country_id: "PL",
      code: "PL-20",
      default_name: "podlaskie",
    },
    {
      region_id: 834,
      country_id: "PL",
      code: "PL-22",
      default_name: "pomorskie",
    },
    {
      region_id: 835,
      country_id: "PL",
      code: "PL-24",
      default_name: "śląskie",
    },
    {
      region_id: 836,
      country_id: "PL",
      code: "PL-26",
      default_name: "świętokrzyskie",
    },
    {
      region_id: 837,
      country_id: "PL",
      code: "PL-28",
      default_name: "warmińsko-mazurskie",
    },
    {
      region_id: 838,
      country_id: "PL",
      code: "PL-30",
      default_name: "wielkopolskie",
    },
    {
      region_id: 839,
      country_id: "PL",
      code: "PL-32",
      default_name: "zachodniopomorskie",
    },
    {
      region_id: 840,
      country_id: "UY",
      code: "UY-AR",
      default_name: "Artigas",
    },
    {
      region_id: 841,
      country_id: "UY",
      code: "UY-CA",
      default_name: "Canelones",
    },
    {
      region_id: 842,
      country_id: "UY",
      code: "UY-CL",
      default_name: "Cerro Largo",
    },
    {
      region_id: 843,
      country_id: "UY",
      code: "UY-CO",
      default_name: "Colonia",
    },
    {
      region_id: 844,
      country_id: "UY",
      code: "UY-DU",
      default_name: "Durazno",
    },
    {
      region_id: 845,
      country_id: "UY",
      code: "UY-FS",
      default_name: "Flores",
    },
    {
      region_id: 846,
      country_id: "UY",
      code: "UY-FD",
      default_name: "Florida",
    },
    {
      region_id: 847,
      country_id: "UY",
      code: "UY-LA",
      default_name: "Lavalleja",
    },
    {
      region_id: 848,
      country_id: "UY",
      code: "UY-MA",
      default_name: "Maldonado",
    },
    {
      region_id: 849,
      country_id: "UY",
      code: "UY-MO",
      default_name: "Montevideo",
    },
    {
      region_id: 850,
      country_id: "UY",
      code: "UY-PA",
      default_name: "Paysandu",
    },
    {
      region_id: 851,
      country_id: "UY",
      code: "UY-RN",
      default_name: "Río Negro",
    },
    {
      region_id: 852,
      country_id: "UY",
      code: "UY-RV",
      default_name: "Rivera",
    },
    {
      region_id: 853,
      country_id: "UY",
      code: "UY-RO",
      default_name: "Rocha",
    },
    {
      region_id: 854,
      country_id: "UY",
      code: "UY-SA",
      default_name: "Salto",
    },
    {
      region_id: 855,
      country_id: "UY",
      code: "UY-SJ",
      default_name: "San José",
    },
    {
      region_id: 856,
      country_id: "UY",
      code: "UY-SO",
      default_name: "Soriano",
    },
    {
      region_id: 857,
      country_id: "UY",
      code: "UY-TA",
      default_name: "Tacuarembó",
    },
    {
      region_id: 858,
      country_id: "UY",
      code: "UY-TT",
      default_name: "Treinta y Tres",
    },
    {
      region_id: 859,
      country_id: "IT",
      code: "AG",
      default_name: "Agrigento",
    },
    {
      region_id: 860,
      country_id: "IT",
      code: "AL",
      default_name: "Alessandria",
    },
    {
      region_id: 861,
      country_id: "IT",
      code: "AN",
      default_name: "Ancona",
    },
    {
      region_id: 862,
      country_id: "IT",
      code: "AO",
      default_name: "Aosta",
    },
    {
      region_id: 863,
      country_id: "IT",
      code: "AR",
      default_name: "Arezzo",
    },
    {
      region_id: 864,
      country_id: "IT",
      code: "AP",
      default_name: "Ascoli Piceno",
    },
    {
      region_id: 865,
      country_id: "IT",
      code: "AT",
      default_name: "Asti",
    },
    {
      region_id: 866,
      country_id: "IT",
      code: "AV",
      default_name: "Avellino",
    },
    {
      region_id: 867,
      country_id: "IT",
      code: "BA",
      default_name: "Bari",
    },
    {
      region_id: 868,
      country_id: "IT",
      code: "BT",
      default_name: "Barletta-Andria-Trani",
    },
    {
      region_id: 869,
      country_id: "IT",
      code: "BL",
      default_name: "Belluno",
    },
    {
      region_id: 870,
      country_id: "IT",
      code: "BN",
      default_name: "Benevento",
    },
    {
      region_id: 871,
      country_id: "IT",
      code: "BG",
      default_name: "Bergamo",
    },
    {
      region_id: 872,
      country_id: "IT",
      code: "BI",
      default_name: "Biella",
    },
    {
      region_id: 873,
      country_id: "IT",
      code: "BO",
      default_name: "Bologna",
    },
    {
      region_id: 874,
      country_id: "IT",
      code: "BZ",
      default_name: "Bolzano",
    },
    {
      region_id: 875,
      country_id: "IT",
      code: "BS",
      default_name: "Brescia",
    },
    {
      region_id: 876,
      country_id: "IT",
      code: "BR",
      default_name: "Brindisi",
    },
    {
      region_id: 877,
      country_id: "IT",
      code: "CA",
      default_name: "Cagliari",
    },
    {
      region_id: 878,
      country_id: "IT",
      code: "CI",
      default_name: "Carbonia-Iglesias",
    },
    {
      region_id: 879,
      country_id: "IT",
      code: "CL",
      default_name: "Caltanissetta",
    },
    {
      region_id: 880,
      country_id: "IT",
      code: "CB",
      default_name: "Campobasso",
    },
    {
      region_id: 881,
      country_id: "IT",
      code: "CE",
      default_name: "Caserta",
    },
    {
      region_id: 882,
      country_id: "IT",
      code: "CT",
      default_name: "Catania",
    },
    {
      region_id: 883,
      country_id: "IT",
      code: "CZ",
      default_name: "Catanzaro",
    },
    {
      region_id: 884,
      country_id: "IT",
      code: "CH",
      default_name: "Chieti",
    },
    {
      region_id: 885,
      country_id: "IT",
      code: "CO",
      default_name: "Como",
    },
    {
      region_id: 886,
      country_id: "IT",
      code: "CS",
      default_name: "Cosenza",
    },
    {
      region_id: 887,
      country_id: "IT",
      code: "CR",
      default_name: "Cremona",
    },
    {
      region_id: 888,
      country_id: "IT",
      code: "KR",
      default_name: "Crotone",
    },
    {
      region_id: 889,
      country_id: "IT",
      code: "CN",
      default_name: "Cuneo",
    },
    {
      region_id: 890,
      country_id: "IT",
      code: "EN",
      default_name: "Enna",
    },
    {
      region_id: 891,
      country_id: "IT",
      code: "FM",
      default_name: "Fermo",
    },
    {
      region_id: 892,
      country_id: "IT",
      code: "FE",
      default_name: "Ferrara",
    },
    {
      region_id: 893,
      country_id: "IT",
      code: "FI",
      default_name: "Firenze",
    },
    {
      region_id: 894,
      country_id: "IT",
      code: "FG",
      default_name: "Foggia",
    },
    {
      region_id: 895,
      country_id: "IT",
      code: "FC",
      default_name: "Forlì-Cesena",
    },
    {
      region_id: 896,
      country_id: "IT",
      code: "FR",
      default_name: "Frosinone",
    },
    {
      region_id: 897,
      country_id: "IT",
      code: "GE",
      default_name: "Genova",
    },
    {
      region_id: 898,
      country_id: "IT",
      code: "GO",
      default_name: "Gorizia",
    },
    {
      region_id: 899,
      country_id: "IT",
      code: "GR",
      default_name: "Grosseto",
    },
    {
      region_id: 900,
      country_id: "IT",
      code: "IM",
      default_name: "Imperia",
    },
    {
      region_id: 901,
      country_id: "IT",
      code: "IS",
      default_name: "Isernia",
    },
    {
      region_id: 902,
      country_id: "IT",
      code: "SP",
      default_name: "La Spezia",
    },
    {
      region_id: 903,
      country_id: "IT",
      code: "AQ",
      default_name: "L'Aquila",
    },
    {
      region_id: 904,
      country_id: "IT",
      code: "LT",
      default_name: "Latina",
    },
    {
      region_id: 905,
      country_id: "IT",
      code: "LE",
      default_name: "Lecce",
    },
    {
      region_id: 906,
      country_id: "IT",
      code: "LC",
      default_name: "Lecco",
    },
    {
      region_id: 907,
      country_id: "IT",
      code: "LI",
      default_name: "Livorno",
    },
    {
      region_id: 908,
      country_id: "IT",
      code: "LO",
      default_name: "Lodi",
    },
    {
      region_id: 909,
      country_id: "IT",
      code: "LU",
      default_name: "Lucca",
    },
    {
      region_id: 910,
      country_id: "IT",
      code: "MC",
      default_name: "Macerata",
    },
    {
      region_id: 911,
      country_id: "IT",
      code: "MN",
      default_name: "Mantova",
    },
    {
      region_id: 912,
      country_id: "IT",
      code: "MS",
      default_name: "Massa-Carrara",
    },
    {
      region_id: 913,
      country_id: "IT",
      code: "MT",
      default_name: "Matera",
    },
    {
      region_id: 914,
      country_id: "IT",
      code: "ME",
      default_name: "Messina",
    },
    {
      region_id: 915,
      country_id: "IT",
      code: "MI",
      default_name: "Milano",
    },
    {
      region_id: 916,
      country_id: "IT",
      code: "MO",
      default_name: "Modena",
    },
    {
      region_id: 917,
      country_id: "IT",
      code: "MB",
      default_name: "Monza e della Brianza",
    },
    {
      region_id: 918,
      country_id: "IT",
      code: "NA",
      default_name: "Napoli",
    },
    {
      region_id: 919,
      country_id: "IT",
      code: "NO",
      default_name: "Novara",
    },
    {
      region_id: 920,
      country_id: "IT",
      code: "NU",
      default_name: "Nuoro",
    },
    {
      region_id: 921,
      country_id: "IT",
      code: "OG",
      default_name: "Ogliastra",
    },
    {
      region_id: 922,
      country_id: "IT",
      code: "OR",
      default_name: "Oristano",
    },
    {
      region_id: 923,
      country_id: "IT",
      code: "OT",
      default_name: "Olbia-Tempio",
    },
    {
      region_id: 924,
      country_id: "IT",
      code: "PD",
      default_name: "Padova",
    },
    {
      region_id: 925,
      country_id: "IT",
      code: "PA",
      default_name: "Palermo",
    },
    {
      region_id: 926,
      country_id: "IT",
      code: "PR",
      default_name: "Parma",
    },
    {
      region_id: 927,
      country_id: "IT",
      code: "PV",
      default_name: "Pavia",
    },
    {
      region_id: 928,
      country_id: "IT",
      code: "PG",
      default_name: "Perugia",
    },
    {
      region_id: 929,
      country_id: "IT",
      code: "PU",
      default_name: "Pesaro e Urbino",
    },
    {
      region_id: 930,
      country_id: "IT",
      code: "PE",
      default_name: "Pescara",
    },
    {
      region_id: 931,
      country_id: "IT",
      code: "PC",
      default_name: "Piacenza",
    },
    {
      region_id: 932,
      country_id: "IT",
      code: "PI",
      default_name: "Pisa",
    },
    {
      region_id: 933,
      country_id: "IT",
      code: "PT",
      default_name: "Pistoia",
    },
    {
      region_id: 934,
      country_id: "IT",
      code: "PN",
      default_name: "Pordenone",
    },
    {
      region_id: 935,
      country_id: "IT",
      code: "PZ",
      default_name: "Potenza",
    },
    {
      region_id: 936,
      country_id: "IT",
      code: "PO",
      default_name: "Prato",
    },
    {
      region_id: 937,
      country_id: "IT",
      code: "RG",
      default_name: "Ragusa",
    },
    {
      region_id: 938,
      country_id: "IT",
      code: "RA",
      default_name: "Ravenna",
    },
    {
      region_id: 939,
      country_id: "IT",
      code: "RC",
      default_name: "Reggio Calabria",
    },
    {
      region_id: 940,
      country_id: "IT",
      code: "RE",
      default_name: "Reggio Emilia",
    },
    {
      region_id: 941,
      country_id: "IT",
      code: "RI",
      default_name: "Rieti",
    },
    {
      region_id: 942,
      country_id: "IT",
      code: "RN",
      default_name: "Rimini",
    },
    {
      region_id: 943,
      country_id: "IT",
      code: "RM",
      default_name: "Roma",
    },
    {
      region_id: 944,
      country_id: "IT",
      code: "RO",
      default_name: "Rovigo",
    },
    {
      region_id: 945,
      country_id: "IT",
      code: "SA",
      default_name: "Salerno",
    },
    {
      region_id: 946,
      country_id: "IT",
      code: "SS",
      default_name: "Sassari",
    },
    {
      region_id: 947,
      country_id: "IT",
      code: "SV",
      default_name: "Savona",
    },
    {
      region_id: 948,
      country_id: "IT",
      code: "SI",
      default_name: "Siena",
    },
    {
      region_id: 949,
      country_id: "IT",
      code: "SR",
      default_name: "Siracusa",
    },
    {
      region_id: 950,
      country_id: "IT",
      code: "SO",
      default_name: "Sondrio",
    },
    {
      region_id: 951,
      country_id: "IT",
      code: "SU",
      default_name: "Sud Sardegna",
    },
    {
      region_id: 952,
      country_id: "IT",
      code: "TA",
      default_name: "Taranto",
    },
    {
      region_id: 953,
      country_id: "IT",
      code: "TE",
      default_name: "Teramo",
    },
    {
      region_id: 954,
      country_id: "IT",
      code: "TR",
      default_name: "Terni",
    },
    {
      region_id: 955,
      country_id: "IT",
      code: "TO",
      default_name: "Torino",
    },
    {
      region_id: 956,
      country_id: "IT",
      code: "TP",
      default_name: "Trapani",
    },
    {
      region_id: 957,
      country_id: "IT",
      code: "TN",
      default_name: "Trento",
    },
    {
      region_id: 958,
      country_id: "IT",
      code: "TV",
      default_name: "Treviso",
    },
    {
      region_id: 959,
      country_id: "IT",
      code: "TS",
      default_name: "Trieste",
    },
    {
      region_id: 960,
      country_id: "IT",
      code: "UD",
      default_name: "Udine",
    },
    {
      region_id: 961,
      country_id: "IT",
      code: "VA",
      default_name: "Varese",
    },
    {
      region_id: 962,
      country_id: "IT",
      code: "VE",
      default_name: "Venezia",
    },
    {
      region_id: 963,
      country_id: "IT",
      code: "VB",
      default_name: "Verbano-Cusio-Ossola",
    },
    {
      region_id: 964,
      country_id: "IT",
      code: "VC",
      default_name: "Vercelli",
    },
    {
      region_id: 965,
      country_id: "IT",
      code: "VR",
      default_name: "Verona",
    },
    {
      region_id: 966,
      country_id: "IT",
      code: "VV",
      default_name: "Vibo Valentia",
    },
    {
      region_id: 967,
      country_id: "IT",
      code: "VI",
      default_name: "Vicenza",
    },
    {
      region_id: 968,
      country_id: "IT",
      code: "VT",
      default_name: "Viterbo",
    },
  ]

  static LANGUAGE_OPTIONS = ["it", "en"]

  static EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  static PWD_REGEX = /^(?=.*[A-Z])(?=.*[-!@#$&*])(?=.*[0-9]).{8,}$/
  static IP_REGEX = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/
  static CMS_LIST = [
    { label: "Shopify", version: "any" },
    { label: "Google Shopping", version: "any" },
    { label: "Prestashop", version: "any" },
    { label: "Storeden", version: "any" },
    { label: "Custom Integration", version: "any" },
    { label: "Magento 1 & 2", version: "" },
    { label: "WooCommerce", version: "" },
  ]

  static newEmptyConfig(websiteName = null, id = "") {
    return {
      sector: null,
      cms: {
        type: "",
        version: "",
      },
      url: websiteName,
      id: id,
      globalSettings: false,
      setup: {
        query: [],
        results: [],
        facets: [],
        dynamicFacets: false,
        seoSettings: false,
        ga4: { isActive: false },
        boosting: [],
        config: {
          selectorId: "",
          productUrl: "",
        },
        layerVersion: "latest",
      },
      layout: {
        general: {},
        desktop: {
          layout: 1,
          logoUrl: "",
          typing: 1,
          minChars: 4,
          paging: 1,
          perPage: 1,
        },
        mobile: {
          minChars: 4,
          paging: 1,
          perPage: 2,
          barcode: false,
        },
      },
      syncing: false,
      synced: false,
      completed: false,
      counter: 0,
      monthly: 0,
    }
  }

  static CONFIG_MODAL_INFO = {
    "info-query": [
      {
        title: "Titles",
        desc: "Be sure to select your product titles field.",
      },
      {
        title: "Description",
        desc:
          "Select this field just if you want that your search will also matches your product description.",
      },
      {
        title: "Barcode/EAN/SKU/ID",
        desc:
          "Select one or more of these fields just if you want that your search will also matches this codes. ",
      },
      {
        title: "Categories",
        desc:
          "Select this field just if you want that your search will also matches your categories.",
      },
      {
        title: "Other fields of your choice",
        desc:
          "Add whatever field you prefer but mind every search could impact the results.",
      },
    ],
    "info-results": [
      {
        title: "Images",
        desc: "Be sure to select your product images field.",
      },
      {
        title: "Titles",
        desc: "Be sure to select your product titles field.",
      },
      {
        title: "URL",
        desc:
          "Be sure to select your product page URL. Otherwise the product will not be clickable.",
      },
      {
        title: "Short Description",
        desc:
          "Select this field just if you want to show a short product description in your product listing card.",
      },
      {
        title: "Sale/Most Relevant/New Arrival",
        desc:
          "Add one or more of this type of fields to customize the visualization later on.",
      },
      {
        title: "Barcode/EAN/SKU",
        desc:
          "Select this field just if you want to show one of these codes in your product listing card.",
      },
      {
        title: "Configuration fields/Additional images",
        desc:
          "Add one or more fields that will help to add functionalities to configurable products.",
      },
      {
        title: "Other fields of your choice",
        desc:
          "Add whatever field you prefer, later you’ll be able to choose what to show and what not.",
      },
    ],
    "info-facets": [
      {
        title: "Categories",
        desc: "",
      },
      {
        title: "Brands",
        desc: "",
      },
      {
        title: "Prices",
        desc: "",
      },
      {
        title: "Rating",
        desc: "",
      },
      {
        title: "Other fields of your choice",
        desc: "",
      },
    ],
  }

  static COLORS_ARRAY = ["#398ff4", "#f865a7", "#edb974"]

  static isTypeRoute(type, routeName) {
    return (type == "general"
      ? Utils.GENERAL_ROUTES
      : Utils.WEBSITE_ROUTES
    ).some((item) => {
      if (item.children) {
        return item.children.some((subitem) => subitem.name == routeName)
          ? item.children.some((subitem) => subitem.name == routeName)
          : item.name === routeName
      } else return item.name === routeName
    })
  }

  static isLocalhost() {
    return window.location.hostname === "localhost"
  }

  static isDev() {
    return (
      window.location.hostname === "as-bo.dev70.ittweb.net" ||
      window.location.hostname === "as-bo.dev1.accelasearch.net"
    )
  }

  static checkConfiguring(shop) {
    return (
      shop.last_sync_start_time != null && shop.last_index_start_time == null
    )
  }

  static checkToBeConfigured(shop) {
    return shop.last_sync_start_time == null
  }

  static checkCompleted(shop) {
    return shop.last_index_start_time !== null
  }

  static checkApikeyHeader(url, method) {
    return url == Utils.SHOPS && method == "get"
  }

  static getConfigurationFromWebsite(configurations, website) {
    var objectToReturn = {}
    if (configurations.length == 0) return Utils.newEmptyConfig()
    configurations.every((item) => {
      if (
        item.url == website.url &&
        item.language_iso == website.language_iso
      ) {
        objectToReturn = { ...item }
        return false
      }
      return true
    })
    return objectToReturn
  }

  static objectEquality(obj1, obj2) {
    return (
      obj1 &&
      obj2 &&
      Object.keys(obj1).every((item) => obj1[item] == obj2[item])
    )
  }

  static checkChanges(websites, configuration) {
    var difference = Utils.difference(
      Utils.getConfigurationFromWebsite(websites, configuration),
      configuration
    )
    delete difference["last_index_end_time"]
    delete difference["last_index_start_time"]
    if (
      difference.layout &&
      difference.layout.facets &&
      difference.layout.facets.every((item) => lodash.isEmpty(item))
    ) {
      delete difference.layout.facets
      if (lodash.isEmpty(difference.layout)) delete difference.layout
    }
    if (!lodash.isEmpty(difference)) console.log(difference)
    return lodash.isEmpty(difference)
  }

  static difference(object, base) {
    return lodash.transform(object, (result, value, key) => {
      if (!lodash.isEqual(value, base[key])) {
        result[key] =
          lodash.isObject(value) && lodash.isObject(base[key])
            ? Utils.difference(value, base[key])
            : value
      }
    })
  }

  static async fromConfigToConfig(config) {
    console.log(config)
    var configToReturn = {}
    await axios.get("/config.json").then((response) => {
      configToReturn.baseUrl = response.data.baseurl
    })
    let recommendations = []
    await axiosUtil
      .get(this.SHOPS + "/" + config.id + this.RECOMMENDATIONS)
      .then((response) => {
        recommendations = response.data.rules
      })
    configToReturn.shopId = config.key
    configToReturn.accentColor = config.layout.primaryColor
    configToReturn.display = config.layout.desktop.layout
    configToReturn.show = {
      type: config.layout.desktop.typing,
      id: {
        desktop: "search",
        mobile: "search",
      },
      name: {
        desktop: "search",
        mobile: "search2",
      },
      disableScroll: true,
    }
    configToReturn.queryOptions = {
      rows: {
        desktop: config.layout.desktop.perPage,
        mobile: config.layout.mobile.perPage,
      },
      show_variants: true, //TODO
      suggestions: 2, //TODO
      spellcheck: 2, //TODO
    }
    configToReturn.recommendationRules = recommendations
    var configurableAttributes = config.layout.card.configurableAttributes.filter(
      (item) => item.key
    )
    configToReturn.hover = {
      // secondImage:Object.keys(config.setup.productImage.hover).length>0,
      secondImage: false, // TODO
      changeImage: configurableAttributes.length > 0, // TODO
      isActive: configurableAttributes.length > 0,
      attributes: configurableAttributes,
    }
    configToReturn.priceGroup = {
      name: "default",
      id: config.setup.config.groupPrice.priceGroupId,
      currency: config.setup.config.groupPrice.currencyCode,
      quantity: "0.0000", // DA MODIFICARE SU LAYER
      currencySymbol: "€",
      format: {
        decimals: 2,
        dec_divider: ".",
        migl__divider: "",
      },
    }
    configToReturn.card = {
      //TODO
      title: Utils.getCardItemFromValue(Object.values(config.setup.results), 1),
      desc: Utils.getCardItemFromValue(Object.values(config.setup.results), 2),
      url: {
        isActive: true,
        key: Object.keys(config.setup.config.productUrl)[0],
      },
      img: {
        isActive: true,
        key: Object.keys(config.setup.productImage.main)[0],
      },
      isinstock: Utils.getCardItemFromValue(
        Object.values(config.setup.results),
        3
      ),
      sku: Utils.getCardItemFromValue(Object.values(config.setup.results), 4),
      type: Utils.getCardItemFromValue(Object.values(config.setup.results), 5),
      brand: Utils.getCardItemFromValue(Object.values(config.setup.results), 6),
      rating: Utils.getCardItemFromValue(
        Object.values(config.setup.results),
        7
      ),
      custom: Utils.getCardItemFromValue(
        Object.values(config.setup.results),
        8
      ),
      listingPrice: { isActive: true, key: "listingPrice" },
      sellingPrice: { isActive: true, key: "sellingPrice" },
    }
    configToReturn.placeholders = {
      searchbar: "Search for a product",
      resultsFor: "Results for",
      filterBy: "Filter By",
      products: "Products",
      labelTiles: "Search or filter by Category",
      labelTrendingCategories: "Trending Categories",
      zeroPrice: config.layout.isZeroOrNull ? config.layout.zeroOrNull : "",
      outOfStock: "Out of stock",
      inStock: "In stock",
      showFilters: "Show Filters",
      hideFilters: "Hide Filters",
      clearFilters: "Clear Filters",
      activeFilters: "Active Filters",
      suggestedWords: "Suggested Words",
      wholeCategory: "Tutta la categoria",
      mainCategories: "Categorie principali",
      noCategory: "There are no results that match your search",
      fromSlider: "From",
      lastSearches: "Ultime ricerche",
      toSlider: "To",
      filterSearchbar: "Cerca",
      noProducts: "Nessun prodotto per ",
      noMatch: "Nessuna corrispondenza",
      popularSearch: "Prova con una delle parole più utilizzate:",
      reset: "Reset",
      show: "Mostra",
      showAll: "Show all",
    }
    configToReturn.voiceSearch = {
      active: config.layout.language.isActive,
      desktop: config.layout.language.isActive, //DA MODIFICARE SU BO
      mobile: config.layout.language.isActive, //DA MODIFICARE SU BO
      googleAPIKEY: "", //TODO
      language: config.layout.language.language,
    }
    configToReturn.showcaseItems = {
      desktop: config.layout.desktop.showcaseItems,
      mobile: config.layout.mobile.showcaseItems,
    }
    configToReturn.triggers = {
      sort: config.layout.sort.active,
      dynamicBanner: config.layout.dynamicBanner,
      wordSuggention: true, // TODO
      icebreaker: {
        isActive: config.layout.icebreaker.isActive,
        number: config.layout.icebreaker.number,
      },
      dynamicFacetsLabels: config.setup.config.dynamicFacets.isActive,
      infiniteScrolling: {
        desktop: config.layout.desktop.paging,
        mobile: config.layout.mobile.paging,
      },
      wordsHistory: {
        desktop: {
          isActive: config.layout.desktop.showcaseLastSearches,
          timeframe: config.layout.desktop.showcaseLastSearchesOption,
        },
        mobile: {
          isActive: config.layout.mobile.showcaseLastSearches,
          timeframe: config.layout.mobile.showcaseLastSearchesOption,
        },
      },
      barcode: config.layout.mobile.barcode,
      placeholder: config.layout.placeholder,
      logo: config.layout.desktop.logoUrl,
      raccomandationsCategories: {
        desktop: config.layout.desktop.showcaseRecommendationsCategories
          ? config.layout.desktop.recommendationCategoriesItems
          : [],
        mobile: config.layout.mobile.showcaseRecommendationsCategories
          ? config.layout.mobile.recommendationCategoriesItems
          : [],
      },
      raccomandationsProducts: [], // TODO
      categoryTiles: {
        desktop: config.layout.desktop.showcaseMainCategories
          ? config.layout.desktop.showcaseMainCategoriesItems
          : [],
        mobile: config.layout.mobile.showcaseMainCategories
          ? config.layout.mobile.showcaseMainCategoriesItems
          : [],
      },
    }
    configToReturn.webhook = {
      key:
        config.setup.config.webhook.isActive && config.setup.config.webhook.key
          ? config.setup.config.webhook.key
          : "",
      fields: {
        sellingPrice:
          config.setup.config.webhook.isActive &&
          config.setup.config.webhook.url
            ? config.setup.config.webhook.url
            : "",
      },
    }
    configToReturn.autocomplete = {
      desktop: false, //TODO
      mobile: false, //TODO
      searchAttribute: ["title", "url", "image"], //TODO
      matchCategories: true, //TODO
      rows: 10, //TODO
      minChars: {
        desktop: config.layout.desktop.minChars, //DA MODIFICARE LAYER
        mobile: config.layout.mobile.minChars, //DA MODIFICARE LAYER
      },
      highlightMode: "normal", //TODO
      limit: 6, //TODO
      item: {
        //TODO
        price: false,
        image: false,
      },
    }
    configToReturn.sorts = Utils.fromSortToSort(config.layout.sort.values)
    configToReturn.filters = Utils.fromFacetsToFilters(config)
    configToReturn.seoSettings = Utils.getSeoSettings(config)
    configToReturn.ga4 = config.setup.config.ga4
    configToReturn.layerVersion = config.setup.config.layerVersion
    configToReturn.promotions = {
      autocomplete: {
        desktop: {
          isActive: false,
          img: "",
          url: "",
        },
        mobile: {
          isActive: false,
          img: "",
          url: "",
        },
      }, //TODO
      firstScreen: {
        desktop: {
          isActive: config.layout.desktop.showcaseBanner,
          img: config.layout.desktop.showcaseBannerSrc,
          url: config.layout.desktop.showcaseBannerUrl,
          name: config.layout.desktop.showcaseBannerName,
        },
        mobile: {
          isActive: config.layout.mobile.showcaseBanner,
          img: config.layout.mobile.showcaseBannerSrc,
          url: config.layout.mobile.showcaseBannerUrl,
          name: config.layout.mobile.showcaseBannerName,
        },
      },
    }
    configToReturn.stickers = [] //TODO
    configToReturn.alert = {} //TODO
    configToReturn.noProducts = {
      mostSearched: [],
      img: "",
    } //TODO
    console.log(configToReturn)
    return configToReturn
  }

  static getCardItemFromValue(values, value) {
    var arrayTemp = values.filter((item) => parseInt(item.value) == value)
    var isActive = arrayTemp.length > 0
    return {
      isActive: isActive,
      key: isActive ? arrayTemp[0].label : "",
    }
  }

  static fromFacetsToFilters(config) {
    var itemToReturn = {}
    config.layout.facets.forEach((value, index) => {
      itemToReturn[value.name] = {}
      itemToReturn[value.name].isActive = value.active
      itemToReturn[value.name].label = value.label
      itemToReturn[value.name].type = value.isCategory
        ? value.type.includes("MC")
          ? "checkbox-MC"
          : "checkbox-SC"
        : value.type
      itemToReturn[value.name].filterSorting = value.filterSorting
      itemToReturn[value.name].isCategory = value.isCategory
      itemToReturn[value.name].order = index
      itemToReturn[value.name].withSearch = value.withSearch
      itemToReturn[value.name].level = value.isCategory
        ? value.type.includes("tree")
          ? 0
          : -1
        : []
    })
    itemToReturn.listingPrice = {}
    itemToReturn.listingPrice.isActive = false
    if (config.setup.config.dynamicFacets.isActive) {
      itemToReturn.dynamicFacets = {}
      itemToReturn.dynamicFacets.type = config.layout.dynamicFacets.dynamicType
      itemToReturn.dynamicFacets.filterSorting =
        config.layout.dynamicFacets.dynamicSorting
      itemToReturn.dynamicFacets.withSearch =
        config.layout.dynamicFacets.dynamicSearch
    }
    return itemToReturn
  }

  static getSeoSettings(config) {
    var itemToReturn = {}
    if (config.setup.config.seoSettings) {
      if (config.setup.config.seoSettings.isActive) {
        itemToReturn.isActive = true
        itemToReturn.searchWord = config.setup.config.seoSettings.searchWord
      }

      itemToReturn.attributes = Object.keys(
        config.setup.config.seoSettings.csvData ?? []
      ).map((key) =>
        Object.keys(config.setup.config.seoSettings.csvData[key]).map(
          (attr, index) => ({
            key: attr,
            value: Object.values(config.setup.config.seoSettings.csvData[key])[
              index
            ],
          })
        )
      )
    }
    return itemToReturn
  }

  static fromSortToSort(values) {
    var arrayToReturn = []
    values
      .filter((item) => item.active)
      .forEach((item) => {
        var itemToAdd = {}
        itemToAdd.label = item.label
        itemToAdd.value = item.value
        itemToAdd.key = item.key
        if (item.selectedList && item.selectedList.length > 0) {
          itemToAdd.tiebrakers = []
          item.selectedList.forEach((subItem) => {
            itemToAdd.tiebrakers.push({
              key: subItem.label,
              value: subItem.sort,
            })
          })
        }
        arrayToReturn.push(itemToAdd)
      })
    return arrayToReturn
  }

  static fromLevelsToArrayLevels(categoryLevel) {
    let arrayToReturn = []
    // const categoryLevelListLength = Utils.categoryLevelList.length;
    // let arrayToReturn = categoryLevel.list.map((item) => [
    //   item.value <= categoryLevelListLength / 2
    //     ? item.value - 1
    //     : item.value - categoryLevelListLength - 1,
    // ]);
    if (categoryLevel.level === 1) {
      arrayToReturn = [[-1]]
    } else if (categoryLevel.level === 2) {
      arrayToReturn = [[0], [1]]
    } else {
      arrayToReturn = [[0], [1], [2]]
    }
    return arrayToReturn
  }

  static beautifyWebsite(website) {
    return website
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
  }

  static getFormattedTimestampFromDate(date) {
    return (
      Utils.FROM_TIMESTAMP +
      "=" +
      date.startDate.getTime() / 1000 +
      "&" +
      Utils.TO_TIMESTAMP +
      "=" +
      date.endDate.getTime() / 1000
    )
  }

  static setNumberFormat(number, decimals, dec_point, thousands_sep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "")
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "" : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function(n, prec) {
        var k = Math.pow(10, prec)
        return "" + (Math.round(n * k) / k).toFixed(prec)
      }

    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".")
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || ""
      s[1] += new Array(prec - s[1].length + 1).join("0")
    }
    return s.join(dec)
  }

  static isStartOrMore(plan) {
    return (
      plan == "start" ||
      plan == "standard" ||
      plan == "advanced" ||
      plan == "enterprise" ||
      plan == "trial"
    )
  }

  static isStandardOrMore(plan) {
    return (
      plan == "standard" ||
      plan == "advanced" ||
      plan == "enterprise" ||
      plan == "trial"
    )
  }

  static isAdvancedOrMore(plan) {
    return plan == "advanced" || plan == "enterprise" || plan == "trial"
  }

  static checkBillingInfo(info) {
    var regionsToUse = lodash.cloneDeep(Utils.REGIONS)
    regionsToUse = regionsToUse.filter(
      (item) => item.country_id == info.country
    )
    return (
      !info.firstName ||
      info.firstName == "" ||
      !info.lastName ||
      info.lastName == "" ||
      !info.address ||
      info.address == "" ||
      !info.email ||
      info.email == "" ||
      !info.city ||
      info.city == "" ||
      !info.vat ||
      info.vat == "" ||
      !info.companyName ||
      info.companyName == "" ||
      (["IT", "VA", "SM"].includes(info.country) &&
        (!info.sdi || info.sdi == "" || info.sdi.length != 7)) ||
      (regionsToUse.length > 0 && (!info.state || info.state == ""))
    )
  }

  static isHomeRoute(name) {
    return (
      name === "login" ||
      name === "signup" ||
      name === "activation" ||
      name === "activation-mail" ||
      name === "forgot-password" ||
      name === "reset-password" ||
      name === "crm-login"
    )
  }

  static fromDateToTimestamp(date) {
    var dateToReturn = lodash.cloneDeep(date)
    dateToReturn.startDate = date.startDate.getTime()
    dateToReturn.endDate = date.endDate.getTime()
    return dateToReturn
  }

  static downloadJSON2CSV(json, filename) {
    var csv =
      filename === "facets_labels"
        ? Utils.convertJsonToCSV(json)
        : Utils.convertJsonTranslationToCSV(json)
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([csv], { type: "text/csv" })
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a")

    // Passing the blob downloading url
    a.setAttribute("href", url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", `${filename}.csv`)

    // Performing a download with click
    a.click()
  }

  static convertJsonToCSV(json) {
    if (!json || !json[0]) return ""
    var fields = Object.keys(json[0])
    var replacer = (key, value) => (value === null ? "" : value)
    var filteredJson = json.filter((item) => item.facet != "")
    var csv = filteredJson.map((row) =>
      fields
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    )
    csv.unshift(fields.join(";")) // add header column
    return csv.join("\n")
  }

  static convertJsonTranslationToCSV(json) {
    if (!json || !Object.keys(json)) return ""
    let csv = []
    for (const key in json) {
      if (Object.hasOwnProperty.call(json, key)) {
        const elements = json[key]
        // console.log("ELEMENTS: ", elements)
        csv.push(
          `${elements.filter}; ${elements.value}; ${elements.translation}`
        )
        // for( const attr in elements){
        //   const valueObj = `"${attr}"; "${elements[attr]}"`
        //   csv.push(valueObj)
        // }
      }
    }

    csv.unshift("Filter; Value; Translation")
    return csv.join("\n")
  }

  static convertCSVToJson(csv) {
    var lines = csv.replace("/(.*);(.*)/gm", "").split("\n")
    var result = []

    var headers = lines[0].split(";")
    headers = headers.map((item) => item.replace(/['"]+/g, "", "").trim())

    for (var i = 0; i < lines.length; i++) {
      var obj = {}
      var currentline = lines[i].split(";")
      currentline = currentline.map((item) =>
        item.replace(/['"]+/g, "", "").trim()
      )
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }
      if (currentline[0] != "") result.push(obj)
    }
    return result
  }

  static convertCSVTranslationToJson(csv) {
    var lines = csv.replace("/(.*);(.*)/gm", "").split("\n")
    var result = []
    // var headers=lines[0].split(";");
    // headers=headers.map(item=>item.replace(/['"]+/g, '',"").trim())
    for (var i = 1; i < lines.length; i++) {
      var currentline = lines[i].split(";")
      currentline = currentline.map((item) =>
        item.replace(/['"]+/g, "", "").trim()
      )
      var previousline = lines[i - 1].split(";")
      previousline = previousline.map((item) =>
        item.replace(/['"]+/g, "", "").trim()
      )

      result.push({
        filter: currentline[0],
        value: currentline[1],
        translation: currentline[2],
      })
    }

    return result
  }
}
